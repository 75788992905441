import React, { useState } from "react";
import styled from 'styled-components/macro';
import Dialog from '../../Shared/Components/GenericComponent/Dialog';

const StyledButton = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

const DeleteOrderDialog = ({ confirmAction,translationKey,currentFileRequired }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => {
    setShowDialog(false);
  };

  const handleClick = () => {
    confirmAction();
    close();
  }

  return (
    <div>
      <StyledButton onClick={open}>{translationKey(currentFileRequired['DELETE_CART'])}</StyledButton>
      <Dialog 
        showDialog={showDialog}
        close={close}
        title={`${translationKey(currentFileRequired['DELETE_CART'])}`}
        confirmActionLabel={`${translationKey(currentFileRequired['DELETE'])}`}
        confirmActionHandleClick={handleClick}
        cancelActionLabel={`${translationKey(currentFileRequired['CANCEL'])}`}
        cancelActionHandleClick={close}
      />
    </div>
  )
}

export default DeleteOrderDialog
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux';
import { buildProductURL } from '../../linkBuilders';
import { getAppURL } from '../../AppContext/selectors';
import FixedImage from '../GenericComponent/FixedImage';

const placeholder = '//multimedia.3m.com/mws/media/642088J'

const Card = styled.a`
    border: 0px;
    border-radius: 0px;
    width: 220px;
    margin-right: 96px;
    display: inline-block;
    position: relative;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
`;

const CardBody = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
`;

const CardTitle = styled.div`
    font-family: fontbold;
    color: #0033cc;
    height: 60px;
    margin-bottom: 11px;
    font-size: 16px;
    line-height: 20px;
`;
// const CardImg = styled.img`
//     border-radius: 0px!important;
//     height: 175px;
//     flex-shrink: 0;
//     width: '100%';
// `;

// const CardBorder = styled.div`
//     margin-top: 6px;
//     height: 5px;
//     margin-bottom: 16px;
// `;

const ActiveCardBorder = styled.div`
    background: linear-gradient(90deg, #8328b4 0%, #01c8e7 65.43%);
    margin-top: 6px;
    height: 5px;
    margin-bottom: 16px;
    visibility: hidden;
    ${Card}:hover & {
      visibility: visible;
    }
`;

const ProductGrid = ({ products, categoryId, searchTerm }) => {
  const appURL = useSelector(getAppURL)
  const toURL = product => buildProductURL(appURL, product.uniqueID, { categoryId, searchTerm })
  return (
    <div className="productGrid">
      {
        products?.map((product, index) => {
          return (
            <Card key={index} href={toURL(product)}>
                <FixedImage width="220px" height="175px" src={product.thumbnail ? product.thumbnail : placeholder} alt="" />
                <CardBody>
                  <ActiveCardBorder></ActiveCardBorder>
                  <CardTitle>{product.name ? product.name : ''}</CardTitle>
                </CardBody>
            </Card>
          )
        })
      }
    </div>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    uniqueID: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    name: PropTypes.string.isRequired,
  })),
  categoryId: PropTypes.string,
  searchTerm: PropTypes.string,
}

ProductGrid.defaultProps = {
  products: [],
}

export default ProductGrid
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import {
    TopHeaderMenu,
    TopNavBar,
    TopLogo,
    HeaderSearchBar,
    TopHeaderNav,
    HeaderMenu,
} from '../HeaderStyles'

import threeMlogo from './../../../../assets/images/3m.svg'
import customerFocus from './../../../../assets/images/customerfocus.svg'
import forecast from './../../../../assets/images/forecast.svg'
import theflash from './../../../../assets/images/flash.svg'
import savedCart from './../../../../assets/images/savedcart.svg'
import UserDetails from '../../UserDetails';
import CatalogMenu from '../CatalogMenu';
import { getBaseURL, getCatalogId, getLangId, getStoreId, getUser, getAppURL, isFeatureEnabled} from '../../../AppContext/selectors';
import { connect } from 'react-redux';
import { buildSavedCartsURL } from '../../../linkBuilders'

import { getCurrentFile } from '../../../utility'
import { withTranslation } from 'react-i18next';
import { UserContext } from '../../../../UserContext';

const GradientBorder = styled.div`
  height: 5px;
  background: linear-gradient(90deg, #8328B4 0%, #01C8E7 110.78%);
  width:100%;
`;

const createLinks = (storeId, catalogId, langId, baseURL) => ({
    home: `${baseURL}RSMPortalDisplayCmd?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`, 
    catalog: `${baseURL}RSMPortalDisplayCmd?view=RegionalStoreCatalogDisplayView&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&showcat=Y`,
    customerFocus: `${baseURL}RSMPortalDisplayCmd?view=RegionalCustomerFocusView&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
    theFlash: `${baseURL}RSMPortalDisplayCmd?view=FlashReportView&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
    forecast: `${baseURL}RSMPortalDisplayCmd?view=ForecastView&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
    mySetup: `${baseURL}RSMPortalDisplayCmd?view=RegionalManagerSetupView&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
    logout: `${baseURL}Logoff?area=regionalsalesportal&new=Y&langId=${langId}&catalogId=${catalogId}&storeId=${storeId}`,
})

class SalesPortalHeader extends Component {

    constructor(props){
        super(props);
        
    }

    static contextType = UserContext

    render() {
        const { selectedLanguage='' } = this?.context;
        const { activeMenu, storeId, catalogId, langId, baseURL, user, appURL, isSavedCarts,t:translationKey={},i18n } = this.props
        const savedCartsURL = buildSavedCartsURL(appURL)
        const links = createLinks(storeId, catalogId, langId, baseURL)
        const activeClass = (menuName) => activeMenu === menuName ? 'headerMenuActive' : ''
        var currentFileRequired = getCurrentFile('EN');
       
        
        return  (
            <div>
                <TopHeaderMenu>
                    <a href={links.home}><TopLogo src={threeMlogo}/></a>
                    <TopNavBar>
                        <TopHeaderNav>
                            <HeaderSearchBar currentFileRequired={currentFileRequired} translationKey={translationKey}/>

                                <a href={links.mySetup}>My Setup</a>
                                <a href={links.logout}>Logout</a>
                            {/* <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Location
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        Option 1
                                </DropdownItem>
                                    <DropdownItem>
                                        Option 2
                                </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        Reset
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                        </TopHeaderNav>
                    </TopNavBar>

                </TopHeaderMenu>
                <HeaderMenu>
                    <CatalogMenu currentFileRequired={currentFileRequired} translationKey={translationKey} className={activeClass('catalog')}/>
                    <a href={links.customerFocus} className={activeClass('customer-focus')}> <img alt="Customer focus menu" src={customerFocus} /> Customer Focus</a>
                    { (isSavedCarts) ? (
                        <a href={savedCartsURL} className={activeClass('saved-carts')}><img alt="Saved carts menu" src={savedCart} /> Saved Carts</a>
                    ) : (
                        <a href={savedCartsURL} className={activeClass('saved-carts')} style={{ display: 'none' }}><img alt="Saved carts menu" src={savedCart} /> Saved Carts</a>
                    ) }
                    <a href={links.theFlash} className={activeClass('the-flash')}><img alt="The flash menu" src={theflash} /> THE FLASH</a>
                    <a href={links.forecast} className={activeClass('forecast')}> <img alt="Forecast menu" src={forecast} /> Forecast</a>
                </HeaderMenu>
                <GradientBorder />
                <UserDetails user={user} currentFileRequired={currentFileRequired} translationKey={translationKey}/>
            </div>
        );
    }
}

SalesPortalHeader.propTypes = {
    activeMenu: PropTypes.string,
    user: UserDetails.propTypes.user,
};

SalesPortalHeader.defaultProps = {}

const mapStateToProps = state => ({
    storeId: getStoreId(state),
    catalogId: getCatalogId(state),
    langId: getLangId(state),
    baseURL: getBaseURL(state),
    user: getUser(state),
    appURL: getAppURL(state),
    isSavedCarts: isFeatureEnabled(state, 'saved-carts'),
})

export default withTranslation()(connect(mapStateToProps)(SalesPortalHeader)); 

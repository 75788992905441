import React, { useState, useEffect } from "react";
import styled from 'styled-components/macro';
import MyOrder from '../../assets/images/myorder.svg';
import Dialog from '../../Shared/Components/GenericComponent/Dialog';

const StyledDialogInput = styled.div`
  position: relative;
  display: flex;
  input {
    flex: 2;
    outline: none;
    padding: 12px 16px 12px 48px;
    line-height: 20px;
    font-family: fontregular;
    font-size: 16px;
    border: 1px solid black;
  }
  img {
    position:absolute;
    top:12px;
    left:12px;
    width:24px;
    height:24px;
  }
`

const StyledButton = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

const RenameOrderDialog = ({ orderName, confirmAction, translationKey, currentFileRequired  }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => {
    setShowDialog(false);
  };

  const [inputValue, setInputValue] = useState(orderName);
  const handleChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleClick = () => {
    if (orderName !== inputValue) {
      confirmAction(inputValue);
    }
    close();
  }

  useEffect(() => {
    setInputValue(orderName);
  }, [orderName])

  return (
    <div>
      <StyledButton onClick={open}>{translationKey(currentFileRequired['RENAME_CART'])}</StyledButton>
      <Dialog 
        showDialog={showDialog}
        close={close}
        title={`${translationKey(currentFileRequired['RENAME_CART'])}`}
        confirmActionLabel={`${translationKey(currentFileRequired['RENAME'])}`}
        confirmActionHandleClick={handleClick}
        cancelActionLabel={`${translationKey(currentFileRequired['CANCEL'])}`}
        cancelActionHandleClick={close}
        inputComponent={
          <StyledDialogInput>
            <img alt="order" src={MyOrder} />
            <input placeholder={`${translationKey(currentFileRequired['CART_NAME'])}`} value={inputValue} onChange={handleChange}/>
          </StyledDialogInput>
        }
      />
    </div>
  )
}

export default RenameOrderDialog
import {
    actionCreator,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../Shared/utility';

export {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader
};

export const LoyaltyRewardsActionTypes = {
    get_loyaltyPoints: createRequestActionTypes('GET_LOYALTY_POINTS'),
    get_loyaltyActivities: createRequestActionTypes('GET_LOYALTY_ACTIVITIES'),
    get_loyaltyTiers: createRequestActionTypes('GET_LOYALTY_TIERS'),
    get_otherLoyaltyActivities: createRequestActionTypes('GET_OTHER_LOYALTY_ACTIVITIES'),
    get_faqProperties: createRequestActionTypes('GET_FAQ_PROPERTIES'),
    get_rewardProperties: createRequestActionTypes('GET_Reward_PROPERTIES'),

};
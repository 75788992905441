import React, { useState } from 'react'
import styled from 'styled-components'

import Button from '../../Shared/Components/GenericComponent/Button'
import Dropdown from '../../Shared/Components/GenericComponent/Dropdown'
import Cross from '../../assets/images/cross.svg'

const ResetButton = styled(Button)`
  width: 100%;
  text-align: left;
  &::after {
    content: url(${Cross});
    margin-top: 3px;
    float: right;
    height: 15px;
  }

`

const ProductOptionDropdown = ({ options, onChange }) => {
  const firstValue = options?.[0]?.value
  const [ currentValue, setCurrentValue ] = useState(firstValue)
  const selected = currentValue !== firstValue
  const activeItem = options.find(option => option.value === currentValue)
  const handleOnChange = value => {
    setCurrentValue(value)
    onChange(value)
  }
  if (selected) {
    return <ResetButton onClick={() => handleOnChange(firstValue)}>{activeItem.label}</ResetButton>
  } else {
    return <Dropdown options={options} onChange={handleOnChange}/>
  }
}

export default ProductOptionDropdown
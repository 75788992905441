import queryString from 'query-string';
import React, { useEffect,useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import { getCatalogId, getStoreId } from '../../Shared/AppContext/selectors';
import ProductFacetSelections from '../../Shared/Components/ProductFacetSelections';
import ProductGrid from '../../Shared/Components/ProductGrid';
import ProductListTitle from '../../Shared/Components/ProductListTitle';
import { toArray } from '../../Shared/utility';
import { GetProductCategoryAction, GetProductViewAction } from './actions/index';
import { getCategory, getFacets, getProductCount, getProducts } from './selectors';
import ProductListingBreadCrumb from './ProductListingBreadCrumb';
import SideBarContentWrapper from '../../Shared/Components/GenericComponent/SideBarContentWrapper';
import ContentWrapper from '../../Shared/Components/GenericComponent/ContentWrapper';
import ProductListingSideBar from './ProductListingSideBar';
import Espot from '../../Shared/Components/Espot'
import BannerArea from '../../Shared/Components/GenericComponent/BannerArea';
import BannerImage from '../../Shared/Components/GenericComponent/BannerImage';
import { useTranslation } from 'react-i18next';
import {UserContext} from '../../UserContext';
import { getCurrentFile } from '../../Shared/utility';

const ProductListing = () => {
    const storeId = useSelector(getStoreId)
    const catalogId = useSelector(getCatalogId)
    const { categoryId } = useParams()
    const { search } = useLocation()
    const category = useSelector(getCategory)
    const products = useSelector(getProducts)
    const productCount = useSelector(getProductCount)
    const facets = useSelector(getFacets)
    const dispatch = useDispatch()
    const { t:translationKey } = useTranslation();
    const {selectedLanguage} = useContext(UserContext);
    const currentFileRequired = getCurrentFile(selectedLanguage)


    useEffect(() => {
        const query = queryString.parse(search)
        const facets = toArray(query?.facet)
        dispatch(GetProductViewAction({ storeId, catalogId, categoryId, facets }))
        dispatch(GetProductCategoryAction({ storeId, catalogId, categoryId }))
    }, [ dispatch, search, storeId, catalogId, categoryId ])


    return (
        <>
            <ProductListingBreadCrumb currentFileRequired={currentFileRequired} translationKey={translationKey}/>
            <SideBarContentWrapper>
                <ProductListingSideBar/>
                <ContentWrapper>
                    <ProductListTitle  currentFileRequired={currentFileRequired} translationKey={translationKey} title={category?.name} productCount={productCount} />
                    <BannerArea>
                        {category?.fullImage && <BannerImage src={category.fullImage} alt="banner"/>}
                        <Espot name="CATEGORY_BANNER" categoryId={categoryId} reqCommand="CategoryDisplay" collapse/>                        
                    </BannerArea>
                    <ProductFacetSelections facets={facets} />
                    <ProductGrid products={products} categoryId={categoryId} />
                </ContentWrapper>
            </SideBarContentWrapper>
        </>
    )
}
export default ProductListing;
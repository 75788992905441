import { ProductListingActionTypes } from '../constants/ApiConstants';

const initialState = {
    loading: false,
    productViewDetails: null,
    productCategoryDetails: null,
    breadCrumbTrail: null,
};


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ProductListingActionTypes.get_prouctView.REQUEST:
            return {
                ...state,
                loading: true,
                productViewDetails: null,
            };
        case ProductListingActionTypes.get_prouctView.SUCCESS:
            return {
                ...state,
                loading: false,
                productViewDetails: payload,
            };
        case ProductListingActionTypes.get_prouctView.FAILURE:
            return {
                ...state,
                loading: false,
                productViewDetails: null
            };
        case ProductListingActionTypes.get_productCategory.REQUEST:
            return {
                ...state,
                loading: true,
                productCategoryDetails: null,
            };
        case ProductListingActionTypes.get_productCategory.SUCCESS:
            return {
                ...state,
                loading: false,
                productCategoryDetails: payload,
            };
        case ProductListingActionTypes.get_productCategory.FAILURE:
            return {
                ...state,
                loading: false,
                productCategoryDetails: null
            };
        case ProductListingActionTypes.get_categoryBreadCrumbTrail.REQUEST:
            return {
                ...state,
                loading: true,
                breadCrumbTrail: null,
            };
        case ProductListingActionTypes.get_categoryBreadCrumbTrail.SUCCESS:
            return {
                ...state,
                loading: false,
                breadCrumbTrail: payload,
            };
        case ProductListingActionTypes.get_categoryBreadCrumbTrail.FAILURE:
            return {
                ...state,
                loading: false,
                breadCrumbTrail: null
            };
        default:
            return state;
    }
};

import queryString from 'query-string';
import {
  actionCreator,
  checkHttpStatus,
  LoyaltyRewardsActionTypes,
  jsonApiHeader,
} from '../constants/ApiConstants';
import {
  isLoyaltyPointsLoading,
  isLoyaltyActivitiesLoading,
  isLoyaltyTiersLoading,
  isOtherLoyaltyActivitiesLoading,
  isFaqPropertiesLoading,
  isRewardPropertiesLoading,

} from '../selectors';

export const GetLoyaltyPoints = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isLoyaltyPointsLoading(state)) {
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyPoints.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/loyalty/@self`, {
          method: 'GET',
          headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyPoints.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyPoints.FAILURE));
        console.log('error get_loyaltyPoints ..', e);
      }
    }
  };
};

export const GetLoyaltyActivities = ({ storeId, pageNumber, qualifiedSaleType, sortColumn, sortSequence }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isLoyaltyActivitiesLoading(state)) {
      const query = queryString.stringify({
        pageNum: pageNumber,
        qualifiedSaleType: qualifiedSaleType,
        sortColumn: sortColumn,
        sortSequence: sortSequence,
      })
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyActivities.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/loyalty/@self/activity?${query}`, {
          method: 'GET',
          headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyActivities.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyActivities.FAILURE));
        console.log('error get_loyaltyActivities ..', e);
      }
    }
  };
};

export const GetOtherLoyaltyActivities = ({ storeId, pageNumber, qualifiedSaleType, sortColumn, sortSequence }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isOtherLoyaltyActivitiesLoading(state)) {
      const query = queryString.stringify({
        pageNum: pageNumber,
        qualifiedSaleType: qualifiedSaleType,
        sortColumn: sortColumn,
        sortSequence: sortSequence,
      })
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/loyalty/@self/activity?${query}`, {
          method: 'GET',
          headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.FAILURE));
        console.log('error get_otherLoyaltyActivities ..', e);
      }
    }
  };
};

export const GetLoyaltyTiers = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!isLoyaltyTiersLoading(state)) {
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyTiers.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/loyalty/@self/tier`, {
          method: 'GET',
          headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyTiers.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_loyaltyTiers.FAILURE));
        console.log('error get_loyaltyActivities ..', e);
      }
    }
  };
};


export const GetFaqProperties = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isFaqPropertiesLoading(state)) {
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_faqProperties.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/properties/faq`, {
          method: 'GET',
          headers: jsonApiHeader(),

        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_faqProperties.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_faqProperties.FAILURE));
        console.log('error get_faqProperties ..', e);
      }
    }
  };
};

export const GetRewardProperties = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isRewardPropertiesLoading(state)) {
      dispatch(actionCreator(LoyaltyRewardsActionTypes.get_rewardProperties.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/properties/rewardMessages`, {
          method: 'GET',
          headers: jsonApiHeader(),

        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_rewardProperties.SUCCESS, payload));
      } catch (e) {
        dispatch(actionCreator(LoyaltyRewardsActionTypes.get_rewardProperties.FAILURE));
        console.log('error get_rewardProperties ..', e);
      }
    }
  };
};
import React, { useState } from "react";
import { checkCurrentLanguageSelected } from "../src/Shared/utility";
import { useSelector } from "react-redux";
import { getStoreId } from "../src/Shared/AppContext/selectors/index";
import { useEffect } from "react";

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const storeId = useSelector(getStoreId);

  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  useEffect(() => {
    async function fetchLanguage() {
      const data = await checkCurrentLanguageSelected(storeId);
      setSelectedLanguage(data);
    }

    fetchLanguage();
  }, [storeId]);

  const setLanguage = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <UserContext.Provider
      value={{
        selectedLanguage,
        setLanguage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };

export const UserConsumer = UserContext.Consumer;

import { OrderFavoritesDropdownActionTypes } from "../constants/ApiCostants"

const initialState = {
  loading: false,
  orderFavorites: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OrderFavoritesDropdownActionTypes.get_orderFavorites.REQUEST:
      return {
        ...state,
        loading: true,
        orderFavorites: null,
      }
    case OrderFavoritesDropdownActionTypes.get_orderFavorites.SUCCESS:
      return {
        ...state,
        loading: false,
        orderFavorites: payload,
      }
    case OrderFavoritesDropdownActionTypes.get_orderFavorites.FAILURE:
      return {
        ...state,
        loading: false,
        orderFavorites: null,
      }
    default:
      return state
  }
}
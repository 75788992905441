import styled from 'styled-components/macro';

const MainTitle = styled.div`
    font-size: 26px;
    line-height: 32px;
    color: #000;
    font-family: fontbold;
    margin-bottom: 26px;
`;

export default MainTitle;
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import BreadCrumb from "../../Shared/Components/BreadCrumb";
import { getCategoryName } from "./selectors";
import {
  getAppURL,
  getCatalogId,
  getLangId,
  getStoreId,
} from "../../Shared/AppContext/selectors";
import { GetCategoryNameAction } from "./actions";

const SearchResultsBreadCrumb = ({ currentFileRequired, translationKey }) => {
  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);
  const langId = useSelector(getLangId);
  const appURL = useSelector(getAppURL);
  const { searchTerm } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const categoryName = useSelector(getCategoryName);
  const { categoryId } = queryString.parse(search);
  useEffect(() => {
    dispatch(GetCategoryNameAction({ storeId, catalogId, langId, categoryId }));
  }, [dispatch, storeId, catalogId, langId, categoryId]);
  const searchPageName = searchTerm
    ? `${translationKey(currentFileRequired["SEARCH_RESULTS"])}: ${searchTerm}`
    : `${translationKey(currentFileRequired["CATALOG_PRODUCT"])}`;
  const searchPageURL = searchTerm
    ? `${appURL}#/search/${encodeURIComponent(searchTerm)}`
    : `${appURL}#/catalog`;
  return categoryName ? (
    <BreadCrumb
      breadCrumbs={[
        {
          label: searchPageName,
          href: searchPageURL,
        },
      ]}
      currentPageLabel={categoryName}
    />
  ) : (
    <BreadCrumb currentPageLabel={searchPageName} />
  );
};

export default SearchResultsBreadCrumb;

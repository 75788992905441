import React from "react";
import PropTypes from 'prop-types'
import styled from 'styled-components/macro';
import ArrowUp from '../../../assets/images/arrow-up.svg'
import ArrowDown from '../../../assets/images/arrow-down.svg'
import { Listbox, ListboxOption } from '@reach/listbox'

const Container = styled(Listbox)`
  position: relative;
  width: 100%;

  & [data-reach-listbox-button] {
    display: inline-block;
    font-size: 16px;
    line-height:20px;
    font-family: fontbold;
    padding: 11px 16px;
    transition: 0.5s all ease-out;
    width: 100%;
    text-align: left;

    &:not([aria-disabled="true"]) {
      color: #000000;
      background: #FFFFFF;
      border: 1px solid #000000;
      cursor: pointer;

      &:hover {
        color: white;
        background-color: #000000;
      }
    }

    &[aria-disabled="true"] {
      color: #B0B0B0;
      background: #E3E3E3;
      border: 1px solid #B0B0B0;
      cursor: default;
    }
  }

  & [data-reach-listbox-arrow] {
    float:right;
    height: 20px;

    content: url(${ArrowDown});

    &[data-expanded] {
      content: url(${ArrowUp});
    }
  }

  & [data-reach-listbox-list] {
    display: block;
    list-style: none;
    margin: 0;
    margin-top: -1px;
    margin-left: 1rem;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    border: 1px solid #000;
    box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.15);
    &:focus {
      outline:none;
      border: 1px solid #000;
    }
  }
`

const Option = styled(ListboxOption)`
  padding: 9px 8px;
  color: #000;
  background:#fff;
  border-bottom: 1px solid #000;
  text-size-adjust: 100%;
  cursor: pointer;
  color: #000; 
  background: #fff; 

  &:last-child {
    border-bottom: 0px;
  }

  &[aria-selected="true"] {
    color: #fff; 
    background: #000; 
    box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.15);
  }
`

const Dropdown = ({ options, value, onChange, disabled }) => (
  <Container portal={false} value={value} onChange={onChange} disabled={disabled}>
    {
      options?.map(option => {
        return (<Option value={`${option.value}`}>{option.label}</Option>)
      })
    }
  </Container>
)

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string
  })),
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Dropdown;
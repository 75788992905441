import queryString from "query-string";
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getCatalogId, getStoreId } from "../../Shared/AppContext/selectors";
import Espot from "../../Shared/Components/Espot";
import BannerArea from "../../Shared/Components/GenericComponent/BannerArea";
import ContentWrapper from "../../Shared/Components/GenericComponent/ContentWrapper";
import SideBarContentWrapper from "../../Shared/Components/GenericComponent/SideBarContentWrapper";
import ProductFacetSelections from "../../Shared/Components/ProductFacetSelections";
import ProductGrid from "../../Shared/Components/ProductGrid";
import ProductListTitle from "../../Shared/Components/ProductListTitle";
import { toArray } from "../../Shared/utility";
import { GetSearchResultsAction } from "./actions/index";
import SearchResultsBreadCrumb from "./SearchResultsBreadCrumb";
import SearchResultsSideBar from "./SearchResultsSideBar";
import { getFacets, getProductCount, getProducts } from "./selectors";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../UserContext";
import { getCurrentFile } from "../../Shared/utility";

const SearchResults = () => {
  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);
  const { searchTerm } = useParams();
  const { search } = useLocation();
  const products = useSelector(getProducts);
  const productCount = useSelector(getProductCount);
  const facets = useSelector(getFacets);
  const dispatch = useDispatch();
  const { t: translationKey } = useTranslation();
  const { selectedLanguage } = useContext(UserContext);
  const currentFileRequired = getCurrentFile(selectedLanguage);

  useEffect(() => {
    const query = queryString.parse(search);
    const facets = toArray(query?.facet);
    const categoryId = query?.categoryId;
    dispatch(
      GetSearchResultsAction({
        storeId,
        catalogId,
        searchTerm,
        facets,
        categoryId,
      })
    );
  }, [dispatch, search, storeId, catalogId, searchTerm]);

  return (
    <>
      <SearchResultsBreadCrumb
        currentFileRequired={currentFileRequired}
        translationKey={translationKey}
      />
      <SideBarContentWrapper>
        <SearchResultsSideBar />
        <ContentWrapper>
          <ProductListTitle
            currentFileRequired={currentFileRequired}
            translationKey={translationKey}
            title={
              searchTerm
                ? `${translationKey(
                    currentFileRequired["SEARCH_RESULTS_FOR"]
                  )} "${searchTerm}"`
                : `${translationKey(currentFileRequired["CATALOG_PRODUCT"])}`
            }
            productCount={productCount}
          />
          <BannerArea>
            <Espot
              name="SEARCH_BANNER"
              searchTerm={searchTerm}
              reqCommand="SearchDisplay"
              collapse
            />
          </BannerArea>
          <ProductFacetSelections facets={facets} />
          <ProductGrid products={products} searchTerm={searchTerm} />
        </ContentWrapper>
      </SideBarContentWrapper>
    </>
  );
};
export default SearchResults;

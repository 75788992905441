import React from 'react'
import PropTypes from 'prop-types'

const HTML = ({children}) => {
  const html = typeof children === 'string' ? children :
    Array.isArray(children) ? children.join('') :
    undefined
  return <span dangerouslySetInnerHTML={{__html: html}}/>
}

HTML.propTypes = {
  children: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]
  ).isRequired
}

export default HTML
const getSavedCarts = state => state.savedCarts;

export const getPendingOrders = state => getSavedCarts(state).pendingOrders?.Order;
export const isPendingOrdersLoading = state => getSavedCarts(state).pendingOrdersLoading;
export const isPendingOrdersLoaded = state => getSavedCarts(state).pendingOrdersLoaded;

export const getCurrentPendingOrder = state => getSavedCarts(state).currentPendingOrder;
export const isCurrentPendingOrderLoading = state => getSavedCarts(state).currentPendingOrderLoading;
export const isCurrentPendingOrderLoaded = state => getSavedCarts(state).currentPendingOrderLoaded;

export const getLockedCustomerCarts = state => getSavedCarts(state).lockedCustomerCarts?.resultList;
export const isLockedCustomerCartsLoading = state => getSavedCarts(state).lockedCustomerCartsLoading;
export const isLockedCustomerCartsLoaded = state => getSavedCarts(state).lockedCustomerCartsLoaded;

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
import styled from 'styled-components/macro'
import cross from '../../../assets/images/cross.svg'
import { toArray } from '../../utility';
import { useSelector } from 'react-redux';
import { getAppURL } from '../../AppContext/selectors';

const Badge = styled.div`
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    height: 44px;
    background: #e8e8e8;
    color: #0033cc;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 17px;
    border-radius: 0px;
    border: 1px solid #d3d3d3;
    text-align: left;
    font-family: fontlight;
    & img {
        margin-right: 13px;
        vertical-align: middle;
    }
`;

const ProductFacetSelections = ({ facets }) => {
  const appURL = useSelector(getAppURL)
  const { pathname, search } = useLocation()

  const query = queryString.parse(search)
  const selections = toArray(query.facet)

  const facetEntryMap = facets?.reduce((accum, facet) => ({
    ...accum,
    ...facet.entry.reduce((entryAccum, entry) => ({
      ...entryAccum,
      [entry.value]: entry.label
    }), {})
  }), {})

  const urlPrefix = `${appURL}#${pathname}`
  const toURL = (selection) => {
    const newQuery = {
      ...query,
      facet: toArray(query.facet).filter(x => x !== selection)
    }
    const newSearch = queryString.stringify(newQuery)
    return `${urlPrefix}${newSearch.length > 0 ? '?' : ''}${newSearch}`
  }

  return (
    <div className="selection">
      { selections.length > 0 && facetEntryMap && (
        <Fragment>
          <div className="yourSelection">Your Selection</div>
          {
            selections.map(selection => (
              <a href={toURL(selection)}>
                <Badge color="secondary"><img alt="remove selection icon" src={cross} />{facetEntryMap[selection]}</Badge>
              </a>
            ))
          }
        </Fragment>
      )}
    </div>
  )
}

ProductFacetSelections.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    entry: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }))
  }))
}

export default ProductFacetSelections
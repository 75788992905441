import React from 'react';
import { useSelector } from "react-redux";
import VerticalFacets from '../../Shared/Components/VerticalFacets';
import { getFacets } from './selectors';

export const SearchResultsSideBar = () => {
  const facets = useSelector(getFacets)
  return (
    <VerticalFacets facets={facets} />
  )
}

export default SearchResultsSideBar

import { translation as translationDE } from "../i18n/locales/de/translations";
import { translation as translationES } from "../i18n/locales/es/translations-es";
import { translation as translationEN } from "../i18n/locales/en/translations-en";
import { translation as translationFR } from "../i18n/locales/fr/translations-fr";

export const API_URL = "";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

export const PositiveIntegerRegex = /^[1-9]\d*$/;

export function actionCreator(type, payload, meta) {
  return {
    type,
    payload,
    meta,
  };
}

/**
 * @typedef {Object} AsyncActions
 * @property {string} REQUEST
 * @property {string} SUCCESS
 * @property {string} FAILURE
 */

/**
 * @param {string} base
 * @returns {AsyncActions}
 */
export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (response.status >= 400 && response.status < 500) {
    return response.json();
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export const jsonApiHeader = (contentType) => {
  // const token = localStorage.getItem('token')
  const contentTypeHeader = contentType ? { "Content-Type": contentType } : {};
  return {
    Accept: "application/json",
    ...contentTypeHeader,
    // Authorization: `Bearer ${token}`,
  };
};

export const toArray = (o) =>
  Array.isArray(o) ? o : typeof o === "undefined" || o === null ? [] : [o];

/**
 *
 * @param {*} end  - exclusive
 * @param {*} [start=0] - inclusive, optional, default = 0
 */
export const sequence = (end, start = 0) => {
  const result = [];
  const step = (end - start) / Math.abs(end - start);
  for (let i = start; i !== end; i += step) {
    result.push(i);
  }
  return result;
};

export const priceFormatter = (total) => {
  const values = total ? total.split(".") : [];
  return values.length > 1
    ? `${values[0]}.${values[1].substring(0, 2)}`
    : "0.00";
};

export const dateTimeFormatter = (
  timeStr,
  locales = [],
  options = { dateStyle: "short", timeStyle: "long", hour12: false }
) => {
  const date = new Date(timeStr);
  const isValidDate = date instanceof Date && !isNaN(date);
  return isValidDate
    ? new Intl.DateTimeFormat(locales, options).format(date)
    : timeStr;
};

export const getCookie = (name) => {
  let m = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return Array.isArray(m) && m.length > 1
    ? decodeURIComponent(m[1])
    : undefined;
};

export const checkCurrentLanguageSelected = async (storeId) => {
  var language = "EN";

  const response = await fetch(
    `/wcs/resources/store/${storeId}/storelang/getLanguages`,
    {
      method: "GET",
      headers: jsonApiHeader(),
    }
  );
  const data = await checkHttpStatus(response);

  if (data && data.languageList && Array.isArray(data.languageList)) {
    totalLanguagesAvailableWithId = data.languageList;
  }

  var url = new URL(window.location.href);

  if (getCookie("UNITEK_LANGID")) {
    let currentLangObj =
      totalLanguagesAvailableWithId.length > 0 &&
      totalLanguagesAvailableWithId.find(
        (lang) => lang.langId == getCookie("UNITEK_LANGID")
      );

    if (currentLangObj) {
      return currentLangObj.localeName;
    }
    return language;
  } else if (url.searchParams.get("langId")) {
    var langId = url.searchParams.get("langId");
    let currentLangObj =
      totalLanguagesAvailableWithId.length > 0 &&
      totalLanguagesAvailableWithId.find((lang) => lang.langId == langId);

    if (currentLangObj) {
      return currentLangObj.localeName;
    }
    return language;
  } else if (
    window.navigator.languages.length &&
    window.navigator.languages.findIndex(
      (language) =>
        language == "en" ||
        language == "en-US" ||
        language == "es" ||
        language == "de" ||
        language == "fr"
    ) !== -1
  ) {
    language =
      window.navigator.language == "en-US" ? "en" : window.navigator.language;
  }
  return language.toUpperCase();
};

export const getCurrentFile = (selectedLanguage) => {
  if (selectedLanguage == "DE") {
    return translationDE;
  }
  if (selectedLanguage == "ES") {
    return translationES;
  }
  if (selectedLanguage == "EN") {
    return translationEN;
  }
  if (selectedLanguage == "FR") {
    return translationFR;
  }
};

export var totalLanguagesAvailableWithId = [];

export const getTotalAvailableLanguages = async (storeId) => {
  const response = await fetch(
    `/wcs/resources/store/${storeId}/storelang/getLanguages`,
    {
      method: "GET",
      headers: jsonApiHeader(),
    }
  );
  const data = await checkHttpStatus(response);
  if (data && data.languageList && Array.isArray(data.languageList)) {
    totalLanguagesAvailableWithId = data.languageList;
  }
};

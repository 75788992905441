import React, { useEffect } from "react";
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux'
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button';
import EyeOutline from '../../assets/images/eye-outline.svg';
import ArrowUp from '../../assets/images/arrow-up.svg'
import ArrowDown from '../../assets/images/arrow-down.svg'
import SavedCartsBreadCrumb from './SavedCartsBreadCrumb';
import RenameOrderDialog from './RenameOrderDialog';
import DeleteOrderDialog from './DeleteOrderDialog';
import {
  getStoreId,
  getLangId,
  getBaseURL,
  getCatalogId
} from '../../Shared/AppContext/selectors';
import {
  UnLockOrderAction,
  GetLockedCustomerCartsAction,
  RenameOrderAction,
  DeleteOrderAction,
} from './actions';
import { getLockedCustomerCarts } from './selectors';
import { priceFormatter, dateTimeFormatter, getCurrentFile } from '../../Shared/utility';
import HTML from "../../Shared/Components/GenericComponent/HTML";
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../UserContext';


const MinHeightTable = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid;
  margin: 50px 0;
  table {
    table-layout: fixed;
    border: none;
  }
  th, td {
    padding: 8px 16px;
    border-right: 1px solid #5A5A5A;
    &:last-child {
      border-right: none;
    }
  }
  img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
  input {
    margin: 0px;
  }
  span {
    word-break: break-word;
  }
`

const FlexVerticalAlign = styled.div`
  display: flex;
  align-items: center;
`

const FlexHorizontalAlign = styled.div`
  display: flex;
  justify-content: center;
`

const FixedTH = styled.th`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.center && 'text-align: center;'}
`

const StyledMenuButton = styled(MenuButton)`
  font-family: fontregular;
  color: #4b4b4b;
  font-size: 16px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  img {
    margin: 0px;
  }
`

const StyledMenuList = styled(MenuList)`
  background-color: rgba(255,255,255,1);
  margin-top: 5px;
  margin-left: -220px;
  border: 1px solid #ccc;
  box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.15);
  border-sizing: border-box;
  &:focus {
    outline: none;
  }
  div:last-child {
    a {
      border: 0;
    }
  }
`

const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 44px;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  width: 220px;
  border-bottom: 1px solid #ccc;
  color: #4b4b4b;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
  ${props => props.fullwidth === 'true' && 'padding: 0; width: 100%;'}
`;
const StyledButtonLink = styled.button`
background: none!important;
border: none;
padding: 0px !important;
color: #069;
text-decoration: underline;
cursor: pointer;
`;

const StyledResumeLink = styled.button`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 44px;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  width: 220px;
  border-bottom: 1px solid #ccc;
  color: #4b4b4b;
  background: white;
    border: none;
    display: flex;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
  ${props => props.fullwidth === 'true' && 'padding: 0; width: 100%;'}
`

const StyledCalloutNotch = styled.i`
  border: solid #ccc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  left: 12px;
  top: 1px;
  background-color: rgba(255,255,255,1);
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`

const SalesPortalSavedCarts = () => {
  const dispatch = useDispatch()
  const storeId = useSelector(getStoreId)
  const langId = useSelector(getLangId)
  const baseURL = useSelector(getBaseURL)
  const catalogId = useSelector(getCatalogId)

  const { t:translationKey } = useTranslation();
  const currentFileRequired = getCurrentFile('EN')

  const constructSetCurrentPendingOrderUrl = (order) => 
    `SetPendingOrder?orderId=${order.orderId}&URL=OrderItemDisplay&doPrice=N`;
  const constructResumeEditingUrl = (order) => 
    `${constructSetCurrentPendingOrderUrl(order)}`

  const isOrderOnlyVisibleToLoggedInUser = (order) => parseInt(order.x_field1) === 1

  const handleUnLockOrder = (orderId) => {
    dispatch(UnLockOrderAction({ storeId, orderId }))
    .then(()=>{
      dispatch(GetLockedCustomerCartsAction({ storeId }))
    })
  }

  const getHandleRenameAction = (order) => (orderName) => {
    dispatch(RenameOrderAction({
      storeId,
      orderId: order.orderId,
      orderName,
      buyerId: order.buyerId
    }))
    .then(()=>{
      dispatch(GetLockedCustomerCartsAction({ storeId }))
    })
  }

  const getHandleDeleteAction = (order) => () => {
    dispatch(DeleteOrderAction({
      storeId,
      orderId: order.orderId,
      buyerId: order.buyerId,
    }))
    .then(()=>{
      dispatch(GetLockedCustomerCartsAction({ storeId }))
    })
  }

  useEffect(() => {
    dispatch(GetLockedCustomerCartsAction({ storeId }))
  }, [])

  const lockedOrders = useSelector(getLockedCustomerCarts)
  const authToken = document.getElementById('reactWCToken')?.value;

  return (
    <>
      <SavedCartsBreadCrumb />
      <MinHeightTable>
        <table>
          <thead>
            <tr>
              <FixedTH>Customer #</FixedTH>
              <FixedTH>Customer Name</FixedTH>
              <FixedTH>Cart #</FixedTH>
              <FixedTH>Cart Name</FixedTH>
              <FixedTH center>Last Updated</FixedTH>
              <FixedTH center>Total</FixedTH>
              <FixedTH width={100} center>Actions</FixedTH>
            </tr>
          </thead>
          <tbody>
            {lockedOrders && lockedOrders.length > 0 && lockedOrders.map((order, index)=> (
              <tr key={index} >
                <td>
                  <span>
                    {order.accountNumber}
                  </span>
                </td>
                <td>
                  <span>
                    {order.customerName}
                  </span>
                </td>
                <td>
                  <FlexVerticalAlign>
                    <form action="RunAsUserSetInSession"  method="post">
                      <input type="hidden" name="storeId" value={storeId}/> 
                      <input type="hidden" name="langId" value={langId}/> 
                      <input type="hidden" name="catalogId" value={catalogId}/> 
                      <input type="hidden" name="runAsUserId" value={order.buyerId}/> 
                      <input type="hidden" name="URL" value={constructSetCurrentPendingOrderUrl(order)}/>
                      <input type="hidden" name="authToken" value={authToken}/> 
                      <div style={{display:'flex'}}>
                      <StyledButtonLink type="submit" >
                       {order.orderId}
                      
                      </StyledButtonLink>
                      {isOrderOnlyVisibleToLoggedInUser(order) && <img alt="eye" src={EyeOutline} />}
                      </div>
                    </form>
                    
                  </FlexVerticalAlign>
                </td>
                <td>
                  <span>
                    {order.orderDescription}
                  </span>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    {dateTimeFormatter(order.lastUpdateDate)}
                  </FlexHorizontalAlign>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    <HTML>{order.formattedTotalProductPrice}</HTML>
                  </FlexHorizontalAlign>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    <Menu>
                      {({isExpanded}) => (
                        <>
                          <StyledMenuButton>
                            { isExpanded ? <img alt="Dropdown" src={ArrowUp} /> : <img alt="Dropdown" src={ArrowDown} /> }
                          </StyledMenuButton>
                          <StyledMenuList>
                            <StyledCalloutNotch />
                            <form action="RunAsUserSetInSession" method="post">
                            <input type="hidden" name="storeId" value={storeId}/> 
                              <input type="hidden" name="langId" value={langId}/> 
                              <input type="hidden" name="catalogId" value={catalogId}/> 
                              <input type="hidden" name="runAsUserId" value={order.buyerId}/> 
                              <input type="hidden" name="URL" value={constructResumeEditingUrl(order)}/>
                              <input type="hidden" name="authToken" value={authToken}/> 
                              <div style={{borderBottom:'1px solid rgb(204, 204, 204)'}}>
                              <StyledResumeLink type="submit">
                                Resume editing
                              </StyledResumeLink>
                              </div>
                             
                            </form>
                            <StyledMenuLink onClick={()=>{handleUnLockOrder(order.orderId)}}>
                              Unlock cart
                            </StyledMenuLink>
                            <StyledMenuLink fullwidth={'true'}>
                              <RenameOrderDialog 
                                confirmAction={getHandleRenameAction(order)}
                                orderName={order.orderDescription}
                                translationKey={translationKey}
                                currentFileRequired={currentFileRequired}
                              />
                            </StyledMenuLink>
                            <StyledMenuLink fullwidth={'true'}>
                              <DeleteOrderDialog 
                                confirmAction={getHandleDeleteAction(order)}
                                translationKey={translationKey}
                                currentFileRequired={currentFileRequired}
                              />
                            </StyledMenuLink>
                          </StyledMenuList>
                        </>
                      )}
                    </Menu>
                  </FlexHorizontalAlign>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MinHeightTable>  
    </>
  )
}

export default SalesPortalSavedCarts

import { HeaderActionTypes } from "../constants/ApiConstants"

const initialState = {
  loading: false,
  loaded: false,
  multiAccountsLoading: false,
  multiAccountsLoaded: false,
  customerProfileLoading: false,
  customerProfileLoaded: false,
  runAsUserLoading: false,
  runAsUserLoaded: false,
  topCategories: null,
  multiAccounts: null,
  customerProfile: null,
  runAsUser: null,
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HeaderActionTypes.get_topCategories.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case HeaderActionTypes.get_topCategories.SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        topCategories: payload,
      }
    case HeaderActionTypes.get_topCategories.FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        topCategories: payload,
      }
    case HeaderActionTypes.get_multiAccounts.REQUEST:
      return {
        ...state,
        multiAccountsLoading: true,
      }
    case HeaderActionTypes.get_multiAccounts.SUCCESS:
      return {
        ...state,
        multiAccountsLoading: false,
        multiAccountsLoaded: true,
        multiAccounts: payload,
      }
    case HeaderActionTypes.get_multiAccounts.FAILURE:
      return {
        ...state,
        multiAccountsLoading: false,
        multiAccountsLoaded: true,
        multiAccounts: payload,
      }
    case HeaderActionTypes.get_customerProfile.REQUEST:
      return {
        ...state,
        customerProfileLoading: true,
      }
    case HeaderActionTypes.get_customerProfile.SUCCESS:
      return {
        ...state,
        customerProfileLoading: false,
        customerProfileLoaded: true,
        customerProfile: payload,
      }
    case HeaderActionTypes.get_customerProfile.FAILURE:
      return {
        ...state,
        customerProfileLoading: false,
        customerProfileLoaded: true,
        customerProfile: payload,
      }
    case HeaderActionTypes.get_runAsUser.REQUEST:
      return {
        ...state,
        runAsUserLoading: true,
      }
    case HeaderActionTypes.get_runAsUser.SUCCESS:
      return {
        ...state,
        runAsUserLoading: false,
        runAsUserLoaded: true,
        runAsUser: payload,
      }
    case HeaderActionTypes.get_runAsUser.FAILURE:
      return {
        ...state,
        runAsUserLoading: false,
        runAsUserLoaded: true,
        runAsUser: payload,
      }
    default:
      return state
  }
}
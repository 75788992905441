import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import { GetTopCategoriesAction } from "./actions";
import { getCatalogId, getStoreId } from "../../AppContext/selectors";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";

const StyledMenuList = styled(MenuList)`
  background-color: rgba(90, 90, 90, 1);
  margin-top: 5px;
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.15);
  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 52px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(90, 90, 90, 1) transparent;
  }
  &:focus {
    outline: none;
  }
  position: relative;
  z-index: 9;
`;
const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 44px;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff !important;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    to left,
    rgba(235, 235, 235, 0) 0%,
    #ebebeb 100%
  );
  border-image-slice: 1;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
`;

const YourProductsMenu = ({
  orderFavoritesLink,
  orderHistoryLink,
  savedCartsLink,
  orderBandsLink,
  quickOrderLink,
  translationKey,
  currentFileRequired,
  activeClass,
  isSavedCarts,
  activeMenu,
}) => {
  const [clicked, setClicked] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const availableItems = [
    "order-favorites-menu",
    "saved-carts",
    "order-bands",
    "my-orders-menu",
    "quick-order",
  ];

  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);

  const handleMouseOver = !isLoaded
    ? () => {
        dispatch(GetTopCategoriesAction({ storeId, catalogId }));
        setLoaded(true);
      }
    : undefined;
  const handleButtonDown = () => {
    if (!clicked) setClicked(true);
  };
  const handleMenuUp = (e) => {
    if (clicked) setClicked(false);
  };
  const handleMenuOut = (e) => {
    if (clicked) setClicked(false);
  };
  const handleMenuClick = (e) => {
    if (clicked) {
      e.preventDefault();
      setClicked(false);
    }
  };
  

  const subItemsSelected = () => {
    return availableItems.findIndex((item) => item == activeMenu) !== -1;
  };
  return (
    
    <Menu>
      {({ isExpanded }) => (
        <React.Fragment>
          <MenuButton
          // onFocus={handleMouseOver}
          // onMouseOver={handleMouseOver}
          // onMouseDown={handleButtonDown
          className={`your-account-btn ${
            subItemsSelected() ? "headerMenuActive" : ""
          }`}
        >
          {translationKey(currentFileRequired["YOUR_PRODUCTS"])}
          <img alt="" src={isExpanded ? ArrowUp : ArrowDown}/>
        </MenuButton>
        <StyledMenuList>
          <StyledMenuLink
            href={orderFavoritesLink}
            onClick={handleMenuClick}
            onMouseUp={handleMenuUp}
            onMouseOut={handleMenuOut}
            className={activeClass("order-favorites-menu")}
          >
            {translationKey(currentFileRequired["ORDER_FAVORITES"])}
          </StyledMenuLink>
          {isSavedCarts ? (
            <StyledMenuLink
              href={savedCartsLink}
              onClick={handleMenuClick}
              onMouseUp={handleMenuUp}
              onMouseOut={handleMenuOut}
              className={activeClass("saved-carts")}
            >
              {translationKey(currentFileRequired["SAVED_CARTS"])}
            </StyledMenuLink>
          ) : null}
          <StyledMenuLink
            href={orderBandsLink}
            onClick={handleMenuClick}
            onMouseUp={handleMenuUp}
            onMouseOut={handleMenuOut}
            className={activeClass("order-bands")}
          >
            {translationKey(currentFileRequired["ORDER_BANDS"])}
          </StyledMenuLink>
          <StyledMenuLink
            href={orderHistoryLink}
            onClick={handleMenuClick}
            onMouseUp={handleMenuUp}
            onMouseOut={handleMenuOut}
            className={activeClass("my-orders-menu")}
          >
            {translationKey(currentFileRequired["MY_ORDERS"])}
          </StyledMenuLink>
          <StyledMenuLink
            href={quickOrderLink}
            onClick={handleMenuClick}
            onMouseUp={handleMenuUp}
            onMouseOut={handleMenuOut}
            className={activeClass("quick-order")}
          >
            {translationKey(currentFileRequired["QUICK_ORDER"])}
          </StyledMenuLink>
        </StyledMenuList>
        </React.Fragment>
      )}
    </Menu>
  );
};

YourProductsMenu.propTypes = {
  className: PropTypes.string,
};

export default YourProductsMenu;

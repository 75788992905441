import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { getStoreId, isCustomerServiceRepresentative } from '../../../../src/Shared/AppContext/selectors/index';
import { GetCustomerProfileAction } from '../Header/actions';
import { getCustomerProfile } from '../Header/selectors';
import HTML from "../GenericComponent/HTML";
import ArrowUp from '../../../assets/images/arrow-up.svg'
import ArrowDown from '../../../assets/images/arrow-down.svg'

const FlexOne = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d2d2d2;
  padding-top:8px;
  padding-bottom:8px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const UserLabel = styled.label`
  font-size:14px;
  color:#000;
  line-height:32px;
  display: block;
  font-weight:bold;
  margin-right:16px;
  margin-bottom:0px;
  flex-grow: 1;
`

const UserData = styled.label`
  font-size:14px;
  color:#000;
  line-height:32px;
  font-weight:normal;
  margin-bottom:0px;
`

const StyledButton = styled.button`
  font-family: fontregular;
  color: #4b4b4b;
  font-size: 16px;
  border: none;
  background: transparent;
  outline: none;
  font-weight:bold;
  position: relative;
  cursor: pointer;
  line-height: 44px;
  display: flex;
  align-items: center;
  height: 31px;
  margin-top: 33px;
`

const UserDetails = ({ user,translationKey,currentFileRequired }) => {
  const {
      firstName,
      lastName,
      logonId,
      customerNumber,
      noBilling,
      billingFirstName,
      billingLastName,
      billingAddressLine1,
      billingCity,
      billingState,
      billingZipCode,
      billingCountry
  } = user || {}

  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false);
  
  const storeId = useSelector(getStoreId)
  const isCSR = useSelector(isCustomerServiceRepresentative);
  const customerProfile = useSelector(getCustomerProfile);

  useEffect(() => {
    if (isCSR) {
      dispatch(GetCustomerProfileAction({ storeId }))
    }
  }, [])

 

  return (
    <FlexOne>
      <FlexColumn>
        <FlexRow>
          <div>
            <UserLabel>
              {translationKey && currentFileRequired ? translationKey(currentFileRequired['WELCOME']) : 'Welcome'} <HTML>{firstName} {lastName}</HTML>
            </UserLabel >
            { (customerNumber) && (
              <UserLabel>
                {translationKey(currentFileRequired['ACCOUNT_NUMBER'])}: <UserData>{customerNumber}</UserData>
              </UserLabel>
            ) }
            {isCSR && customerProfile && expanded && <FlexRow>
              {customerProfile.alliance && 
                <UserLabel>
                  Alliance
                </UserLabel>
              }
              <UserLabel>
              {translationKey(currentFileRequired['PHONE'])}: <UserData><HTML>{customerProfile.phone ?? ''}</HTML></UserData>
              </UserLabel>
              <UserLabel>
              {translationKey(currentFileRequired['FAX'])}: <UserData><HTML>{customerProfile.fax ?? ''}</HTML></UserData>
              </UserLabel>
            </FlexRow>}
          </div>
          <div>
            <UserLabel>
              {translationKey(currentFileRequired['LOGIN_ID'])}: <UserData>{logonId}</UserData>
            </UserLabel>
            { (customerNumber) && (
              <UserLabel>
                {translationKey(currentFileRequired['ADDRESS'])}: <UserData><HTML>{noBilling}{billingFirstName}&nbsp;{billingLastName}&nbsp;&nbsp;{billingAddressLine1}&nbsp;&nbsp;{billingCity}&nbsp;{billingState}&nbsp;&nbsp;{billingZipCode}</HTML></UserData>
              </UserLabel>
            ) }
            {isCSR && customerProfile && expanded && <FlexRow>
              <UserLabel>
              {translationKey(currentFileRequired['PRICE_DISCOUNT'])}: <UserData><HTML>{customerProfile.priceDiscount ?? ''}</HTML></UserData>
              </UserLabel>
            </FlexRow>}
          </div>
        </FlexRow >
      </FlexColumn>
      {isCSR && 
        <StyledButton onClick={()=>{setExpanded(!expanded)}}>
          {expanded ? 
            <>{translationKey(currentFileRequired['HIDE_CUSTOMER_DETAILS'])} <img alt="Arrow up icon" src={ArrowUp} /></>:
            <>{translationKey(currentFileRequired['SHOW_CUSTOMER_DETAILS'])} <img alt="Arrow down icon" src={ArrowDown} /></>
          }
        </StyledButton>
      }
    </FlexOne>
  );
}

UserDetails.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    logonId: PropTypes.string,
    customerNumber: PropTypes.string,
    noBilling: PropTypes.string,
    billingFirstName: PropTypes.string,
    billingLastName: PropTypes.string,
    billingAddressLine1: PropTypes.string,
    billingCity: PropTypes.string,
    billingState: PropTypes.string,
    billingZipCode: PropTypes.string,
    billingCountry: PropTypes.string
  })
};

export default UserDetails;

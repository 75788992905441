import React, { useState } from "react";
import styled from "styled-components/macro";
import { Menu, MenuButton } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import { GetMultiAccountsAction } from "../actions";
import { getUser } from "../../../AppContext/selectors";
import { isBuyOnBehalf, getStoreId } from "../../../AppContext/selectors";
import ArrowUp from "../../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../../assets/images/arrow-down.svg";
import MultiAccountsMenuList from "./MultiAccountsMenuList";
import MultiAccountsMenuBuyOnBehalf from "./MultiAccountsMenuBuyOnBehalf";

const StyledMenuButton = styled(MenuButton)`
  font-family: fontregular;
  color: #4b4b4b;
  font-size: 12px;
  border: none;
  background: transparent;
  outline: none;
  height: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0 0 0 15px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const EllipsisText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MultiAccountsMenu = ({ currentFileRequired, translationKey }) => {
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const storeId = useSelector(getStoreId);
  const user = useSelector(getUser);

  const handleMouseOver = !isLoaded
    ? () => {
        dispatch(GetMultiAccountsAction({ storeId }));
        setLoaded(true);
      }
    : undefined;

  const isBOB = useSelector(isBuyOnBehalf);

  return (
    <Menu>
      {({ isExpanded }) => (
        <React.Fragment>
          <StyledMenuButton
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
          >
            <FlexColumn>
              <div style={{ fontSize: "14px" }}>
                {translationKey(currentFileRequired["MANAGE_ACCOUNTS"])}
              </div>
              {isBOB && user && (
                <EllipsisText>({user.customerNumber})</EllipsisText>
              )}
            </FlexColumn>
            {isExpanded ? (
              <img alt="Arrow up icon" src={ArrowUp} />
            ) : (
              <img alt="Arrow down icon" src={ArrowDown} />
            )}
          </StyledMenuButton>
          {isBOB ? (
            <MultiAccountsMenuBuyOnBehalf
              user={user}
              currentFileRequired={currentFileRequired}
              translationKey={translationKey}
            />
          ) : (
            <MultiAccountsMenuList
            currentFileRequired={currentFileRequired}
              translationKey={translationKey}
              isExpanded={isExpanded}
            />
          )}
        </React.Fragment>
      )}
    </Menu>
  );
};

export default MultiAccountsMenu;

export const translation = {
  SEARCH_CATALOG: "Buscar en el catálogo",
  MY_SETUP: "Mi perfil",
  CONTACT_US: "Contacto",
  HELP: "Ayuda",
  LOGOUT: "Cerrar sesión",
  ORDER_FAVORITES: "Pedir favoritos",
  QUICK_ORDER: "Pedido rápido",
  ORDER_BANDS: "Pedir bandas",
  CATALOG: "Catálogo",
  SAVED_CARTS: "Carritos guardados",
  MY_CART: "Mi carrito",
  MY_ORDERS: "Mis pedidos",
  MY_STATEMENT: "Mis extractos",
  WELCOME: "Bienvenido",
  LOGIN_ID: "Usuario",
  ACCOUNT_NUMBER: "Número de cuenta 3M",
  ADDRESS: "Dirección",
  PHONE: "Teléfono",
  FAX: "Fax",
  PRICE_DISCOUNT: "Descuento de precios",
  SHOW_CUSTOMER_DETAILS: "Mostrar detalles del cliente",
  HIDE_CUSTOMER_DETAILS: "Ocultar detalles del cliente",
  MY_REWARDS: "Mis recompensas",
  PRODUCTS: "Productos",
  YOUR_PRODUCTS: "Mis productos",
  MANAGE_ACCOUNTS: "Administrar cuentas",
  //CATALOG PAGE
  PRODUCTS: "Productos",
  HOME: "Inicio",
  //Saved Carts
  CART_NUMBER: "N.º de carrito",
  CART_NAME: "Nombre del carrito",
  LAST_UPDATED: "Última actualización",
  TOTAL: "Total",
  ACTIONS: "Acciones",
  SAVED_CARTS: "Carritos  guardados",
  CREATE_CART: "Crear carrito",
  VIEW_CART_DETAILS: "Ver detalles del carrito",
  SET_AS_CURRENT_CART: "Establecer como carrito actual",
  RENAME_CART: "Cambiar nombre del carrito",
  DELETE_CART: "Eliminar carrito",
  ACTIVE_CART_MESSAGE: "Este es su carrito activo",
  LOCK_CART_MESSAGE: "Este carrito está bloqueado por el usuario",
  RENAME: "Cambiar nombre",
  DELETE: "Eliminar",
  CANCEL: "Cancelar",
  PRIVATE_CART_MESSAGE:
    "Este carro es privado. Desbloquea este carro para que otras personas puedan verlo.",
  UNLOCK_CART: "Desbloquear carro",
  TAKE_OVER_LOCK: "Bloqueo de toma de control",
  LOCK_CART: "Bloquear carro",
  CART_NAME: "Nombre del carrito",
  LOCK_CART_SELF_MESSAGE:
    "Has bloqueado este carro. Nadie más puede editar este carro.",
  CREATE: "Crear",
  CUSTOMER_SUPPORT: "Apoyo al cliente",
  YOUR_ACCOUNT: "Mi cuenta",
  ITEMS: "Artículos",
  //Product Details Page
  OVERVIEW: "Resumen",
  SPECIFICATIONS: "Especificaciones",
  DETAILS: "Detalles",
  VIEW_LEGAL_DISCLAIMER: "Ver el aviso legal",
  PRODUCTS_AVAILABLE: "Productos disponibles",
  THREEM_SITE_REDIRECT_MSG: "Haga clic aquí para ver este producto en 3M.com",
  LEGAL_DISCLAIMER: "Aviso legal",
  ENDORSEMENT_MSG:
    "*Versión 3M de esta receta. No se implica ningún respaldo por parte del Doctor.",
  SEARCH_RESULTS_FOR: "Resultados de la búsqueda de",
  CATALOG_PRODUCT: "Catálogo",
  SEARCH_RESULTS: "Resultados de búsqueda",
  //My Rewards Page
  SAVINGS_THIS_YEAR: "ahorro este año",
  CURRENT_3M: "Estado actual de descuentos ganados por los alineadores",
  TM: "",
  CLARITY: "",
  DISCOUNT_STATUS: "3M Clarity",
  CLARITY_ALIGNERS_CASES: "Casos de alineadores Clarity",
  OTHER_PRODUCT_PURCHASES: "Otras compras de productos",
  SPEND: "Gaste",
  IN_PRODUCT_PURCHASES: "en compras de productos",
  ADD: "Añadir",
  OR: "OR",
  CLARITY_ALIGNERS_ORDERS: "casos de alineadores Clarity",
  DATE: "Fecha",
  INVOICE_NUMBER: "Número de factura",
  ORDER_NUMBER: "Número de pedido",
  QUALIFYING_CASES: "Casos aptos",
  QUALIFYING_PURCHASES: "Compras que califican",
  OTHER_ORTHODONTIC_PURCHASES: "Otras compras de ortodoncia",
  FAQS: "Preguntas frecuentes",
  //MAC BOB
  BUYING_FOR: "Comprar para",
  BUY_ON_BEHALF: "Compra como representante de",
  RETURN_TO_HEADQUARTERS: "Devolver a oficina central",
};

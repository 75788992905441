import { LoyaltyRewardsActionTypes } from '../constants/ApiConstants';

const initialState = {
  loyaltyPointsloading: false,
  loyaltyPointsloaded: false,
  loyaltyPoints: null,
  loyaltyActivitiesloading: false,
  loyaltyActivitiesloaded: false,
  loyaltyActivities: null,
  loyaltyTiersloading: false,
  loyaltyTiersloaded: false,
  tiers: null,
  otherLoyaltyActivitiesloading: false,
  otherLoyaltyActivitiesloaded: false,
  otherLoyaltyActivities: null,
  faqPropertiesloading: false,
  faqPropertiesloaded: false,
  faqProperties: null,
  rewardPropertiesloading: false,
  rewardPropertiesloaded: false,
  rewardProperties: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LoyaltyRewardsActionTypes.get_loyaltyPoints.REQUEST:
      return {
        ...state,
        loyaltyPointsloading: true,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyPoints.SUCCESS:
      return {
        ...state,
        loyaltyPointsloading: false,
        loyaltyPointsloaded: true,
        loyaltyPoints: payload,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyPoints.FAILURE:
      return {
        ...state,
        loyaltyPointsloading: false,
        loyaltyPoints: null,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyActivities.REQUEST:
      return {
        ...state,
        loyaltyActivitiesloading: true,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyActivities.SUCCESS:
      return {
        ...state,
        loyaltyActivitiesloading: false,
        loyaltyActivitiesloaded: true,
        loyaltyActivities: payload,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyActivities.FAILURE:
      return {
        ...state,
        loyaltyActivitiesloading: false,
        loyaltyActivities: null,
      };

    case LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.REQUEST:
      return {
        ...state,
        otherLoyaltyActivitiesloading: true,
      };
    case LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.SUCCESS:
      return {
        ...state,
        otherLoyaltyActivitiesloading: false,
        otherLoyaltyActivitiesloaded: true,
        otherLoyaltyActivities: payload,
      };
    case LoyaltyRewardsActionTypes.get_otherLoyaltyActivities.FAILURE:
      return {
        ...state,
        otherLoyaltyActivitiesloading: false,
        otherLoyaltyActivities: null,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyTiers.REQUEST:
      return {
        ...state,
        loyaltyTiersloading: true,
      };
    case LoyaltyRewardsActionTypes.get_loyaltyTiers.SUCCESS:
      return {
        ...state,
        loyaltyTiersloading: false,
        loyaltyTiersloaded: true,
        tiers: [...payload.tierInfoList].reverse(),
      };
    case LoyaltyRewardsActionTypes.get_loyaltyTiers.FAILURE:
      return {
        ...state,
        loyaltyTiersloading: false,
        tiers: null,
      };
    case LoyaltyRewardsActionTypes.get_faqProperties.REQUEST:
      return {
        ...state,
        faqPropertiesloading: true,
      };
    case LoyaltyRewardsActionTypes.get_faqProperties.SUCCESS:
      return {
        ...state,
        faqPropertiesloading: false,
        faqPropertiesloaded: true,
        faqProperties: payload,
      };
    case LoyaltyRewardsActionTypes.get_faqProperties.FAILURE:
      return {
        ...state,
        faqPropertiesloading: false,
        faqProperties: null,
      };
    case LoyaltyRewardsActionTypes.get_rewardProperties.REQUEST:
      return {
        ...state,
        rewardPropertiesloading: true,
      };
    case LoyaltyRewardsActionTypes.get_rewardProperties.SUCCESS:
      return {
        ...state,
        rewardPropertiesloading: false,
        rewardPropertiesloaded: true,
        rewardProperties: payload,
      };
    case LoyaltyRewardsActionTypes.get_rewardProperties.FAILURE:
      return {
        ...state,
        rewardPropertiesloading: false,
        rewardProperties: null,
      };
    default:
      return state;
  }
};
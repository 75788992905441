export const translation = {
  SEARCH_CATALOG: "Search Catalog",
  MY_SETUP: "Account Details",
  CONTACT_US: "Contact Us",
  HELP: "Help",
  LOGOUT: "Logout",
  ORDER_FAVORITES: "Order Favorites",
  QUICK_ORDER: "Quick Order",
  ORDER_BANDS: "Order Bands",
  CATALOG: "Shop Catalog",
  SAVED_CARTS: "Saved Carts",
  MY_CART: "Cart",
  MY_ORDERS: "Order History",
  MY_STATEMENT: "Manage Payments",
  WELCOME: "Welcome",
  LOGIN_ID: "Login ID",
  ACCOUNT_NUMBER: "Account Number",
  ADDRESS: "Address",
  PHONE: "Phone",
  FAX: "Fax",
  PRICE_DISCOUNT: "Price Discount",
  SHOW_CUSTOMER_DETAILS: "Show Customer Details",
  HIDE_CUSTOMER_DETAILS: "Hide Customer Details",
  MY_REWARDS: "My Rewards",
  YOUR_PRODUCTS: "Your Products",
  MANAGE_ACCOUNTS: "Manage Accounts",
  //CATALOG PAGE
  PRODUCTS: "products",
  HOME: "Home",
  //Saved Carts

  CART_NUMBER: "Cart Number",
  CART_NAME: "Cart Name",
  LAST_UPDATED: "Last Updated",
  TOTAL: "Total",
  ACTIONS: "Actions",
  CREATE_CART: "Create Cart",
  SAVED_CARTS: "Saved Carts",
  VIEW_CART_DETAILS: "View cart details",
  SET_AS_CURRENT_CART: "Set as current cart",
  RENAME_CART: "Rename cart",
  DELETE_CART: "Delete cart",
  ACTIVE_CART_MESSAGE: "This is your active cart",
  LOCK_CART_MESSAGE: "This cart is locked by logon ID",
  LOCK_CART_SELF_MESSAGE:
    "You have locked this cart. No one else may edit this cart.",
  RENAME: "Rename",
  DELETE: "Delete",
  CANCEL: "Cancel",
  PRIVATE_CART_MESSAGE:
    "This cart is private. Unlock to allow others to see this cart.",
  UNLOCK_CART: "Unlock cart",
  TAKE_OVER_LOCK: "Take over lock",
  LOCK_CART: "Lock cart",
  CART_NAME: "Cart name",
  CREATE: "Create",
  CUSTOMER_SUPPORT: "Customer Support",
  YOUR_ACCOUNT: "Your Account",
  ITEMS: "items",
  //Product Details Page
  OVERVIEW: "Overview",
  SPECIFICATIONS: "Specifications",
  DETAILS: "Details",
  VIEW_ALL_DETAILS: "View all details",
  VIEW_LEGAL_DISCLAIMER: "View Legal Disclaimer",
  PRODUCTS_AVAILABLE: "Products available",
  THREEM_SITE_REDIRECT_MSG: "Click here to see this product on 3M.com",
  LEGAL_DISCLAIMER: "Legal Disclaimer",
  ENDORSEMENT_MSG:
    "*3M version of this prescription. No endorsement by the Doctor is implied.",
  SEARCH_RESULTS_FOR: "Search results for",
  CATALOG_PRODUCT: "Catalog",
  SEARCH_RESULTS: "Search results",
  //My Rewards Page
  SAVINGS_THIS_YEAR: "savings this year",
  CURRENT_3M: "Current 3M",
  TM: "TM",
  CLARITY: "Clarity",
  DISCOUNT_STATUS: "Aligner Earned Discount Status",
  CLARITY_ALIGNERS_CASES: "Clarity Aligners Cases",
  OTHER_PRODUCT_PURCHASES: "Other Product Purchases",
  SPEND: "Spend",
  IN_PRODUCT_PURCHASES: "in product purchases",
  ADD: "Add",
  OR: "OR",
  CLARITY_ALIGNERS_ORDERS: "Clarity Aligners Orders",
  DATE: "Date",
  INVOICE_NUMBER: "Invoice Number",
  ORDER_NUMBER: "Order Number",
  QUALIFYING_CASES: "Qualifying Cases",
  QUALIFYING_PURCHASES: "Qualifying Purchases",
  OTHER_ORTHODONTIC_PURCHASES: "Other Orthodontic Purchases",
  FAQS: "FAQS",
  //MAC BOB
  BUYING_FOR: "Buying for",
  BUY_ON_BEHALF: "Buy on behalf of",
  RETURN_TO_HEADQUARTERS: "Return to headquarters",
};

import React from "react";
import styled from "styled-components";
import { LoyalityRewardsData } from "./fetch";

const LevelUpWrapper = styled.div`
  display: inline-block;
  background: #f1f2f2;
  border-radius: 8px;
  position: absolute;
  padding: 20px 0px 0px;
`;
const MessageBlock = styled.div`
  display: inline-block;
  padding: 25px 40px 40px;
  min-width: 265px;
`;

const MessageTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const StyledMessageTitle = styled(MessageTitle)`
  max-width: 100%;
  margin: auto;
  text-align: center;
  height: 173px;
  padding: 65px 160px;
`;
const MessageText = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const AmountBlock = styled.div`
  display: inline-block;
  padding-right: 40px;
`;
const BalanceTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
`;
const BalanceText = styled.div`
  font-size: 32px;
  line-height: 42px;
  color: #1e1e96;
  font-weight: 700;
`;

const RewardMessageContainer = ({
  currentFileRequired,
  translationKey,
  ...props
}) => {
  const futureYear = new Date().getFullYear() + 1;
  return (
    <LevelUpWrapper
      style={{
        width:
          (props.additionalAlignerCasesToNextTier == 0 ||
            props.additionalCoreSaleToNextTier == 0) &&
          "960px",
      }}
    >
      {props.levelUpFlag == 1 ||
      (props.additionalAlignerCasesToNextTier == 0 &&
        props.additionalCoreSaleToNextTier == 0) ? (
        <StyledMessageTitle>
          {" "}
          <span style={{ fontSize: "24px" }}>{props.rightHeader}</span> <br />
          {props.rightBody} {futureYear}
        </StyledMessageTitle>
      ) : (
        <MessageBlock>
          <MessageTitle
            style={{
              maxWidth:
                props.additionalAlignerCasesToNextTier != 0 &&
                props.additionalCoreSaleToNextTier != 0 &&
                "250px",
            }}
          >
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>
              {props.rightHeader}
            </span>
            <br />
            {props.rightBody}
          </MessageTitle>
        </MessageBlock>
      )}
      {(props.levelUpFlag != 1 ||
        (props.additionalAlignerCasesToNextTier != 0 &&
          props.additionalCoreSaleToNextTier != 0)) && (
        <>
          {props.additionalCoreSaleToNextTier != 0 && (
            <MessageBlock>
              <BalanceTitle>
                {translationKey(currentFileRequired["SPEND"])}
              </BalanceTitle>
              <BalanceText>
                $
                {Math.round(props.additionalCoreSaleToNextTier).toLocaleString(
                  "en-us"
                )}
                <sup
                  style={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "black",
                    marginLeft: "5px",
                    fontWeight: "100",
                  }}
                >
                  USD
                </sup>
              </BalanceText>
              <MessageText>
                {translationKey(currentFileRequired["IN_PRODUCT_PURCHASES"])}
              </MessageText>
            </MessageBlock>
          )}
          {props.additionalAlignerCasesToNextTier != 0 &&
            props.additionalCoreSaleToNextTier != 0 && (
              <span
                style={{
                  padding: " 0px 20px",
                  position: "relative",
                  top: " -21px",
                }}
              >
                {translationKey(currentFileRequired["OR"])}
              </span>
            )}
          {props.additionalAlignerCasesToNextTier != 0 && (
            <MessageBlock>
              <BalanceTitle>
                {translationKey(currentFileRequired["ADD"])}
              </BalanceTitle>
              <BalanceText>
                {props.additionalAlignerCasesToNextTier}
              </BalanceText>
              <MessageText>
                {translationKey(currentFileRequired["CLARITY_ALIGNERS_CASES"])}
              </MessageText>
            </MessageBlock>
          )}
        </>
      )}
    </LevelUpWrapper>
  );
};

export default RewardMessageContainer;

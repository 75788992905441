import { SavedCartsActionTypes } from '../constants/ApiConstants';

const initialState = {
    pendingOrdersLoading: false,
    pendingOrdersLoaded: false,
    currentPendingOrderLoading: false,
    currentPendingOrderLoaded: false,
    lockedCustomerCartsLoading: false,
    lockedCustomerCartsLoaded: false,
    pendingOrders: null,
    currentPendingOrder: null,
    lockedCustomerCarts: null,
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SavedCartsActionTypes.get_pendingOrders.REQUEST:
      return {
        ...state,
        pendingOrdersLoading: true,
      };
    case SavedCartsActionTypes.get_pendingOrders.SUCCESS:
      return {
        ...state,
        pendingOrdersLoading: false,
        pendingOrdersLoaded: true,
        pendingOrders: payload,
      };
    case SavedCartsActionTypes.get_pendingOrders.FAILURE:
      return {
        ...state,
        pendingOrdersLoading: false,
        pendingOrdersLoaded: true,
        pendingOrders: payload
      };
    case SavedCartsActionTypes.get_currentPendingOrder.REQUEST:
      return {
        ...state,
        currentPendingOrderLoading: true,
      };
    case SavedCartsActionTypes.get_currentPendingOrder.SUCCESS:
      return {
        ...state,
        currentPendingOrderLoading: false,
        currentPendingOrderLoaded: true,
        currentPendingOrder: payload,
      };
    case SavedCartsActionTypes.get_currentPendingOrder.FAILURE:
      return {
        ...state,
        currentPendingOrderLoading: false,
        currentPendingOrderLoaded: true,
        currentPendingOrder: payload
      };
    case SavedCartsActionTypes.get_lockedCustomerCarts.REQUEST:
      return {
        ...state,
        lockedCustomerCartsLoading: true,
      };
    case SavedCartsActionTypes.get_lockedCustomerCarts.SUCCESS:
      return {
        ...state,
        lockedCustomerCartsLoading: false,
        lockedCustomerCartsLoaded: true,
        lockedCustomerCarts: payload,
      };
    case SavedCartsActionTypes.get_lockedCustomerCarts.FAILURE:
      return {
        ...state,
        lockedCustomerCartsLoading: false,
        lockedCustomerCartsLoaded: true,
        lockedCustomerCarts: payload
      };
    default:
      return state;
  }
};

import React from "react";
import styled from "styled-components/macro";
import { MenuLink, MenuList } from "@reach/menu-button";
import { useSelector } from "react-redux";
import {
  getStoreId,
  getLangId,
  getBaseURL,
  getCatalogId,
  getSiteId,
} from "../../../AppContext/selectors";
import { buildHomeURL } from "../../../linkBuilders";
import Button from "../../GenericComponent/Button";

const StyledCalloutNotch = styled.i`
  border: solid #5a5a5a;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 30px;
  top: -4px;
  background-color: rgba(255, 255, 255, 1);
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const StyledMenuList = styled(MenuList)`
  background-color: rgba(255, 255, 255, 1);
  margin-left: -80px;
  border: 1px solid;
  border-sizing: border-box;
  width: 230px;
  &:focus {
    outline: none;
  }
`;

const RestoreBuyOnBehalfMenuLink = styled(MenuLink)`
  &:focus {
    outline: none;
  }
`;

const RestoreBuyOnBehalfTitle = styled.div`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  padding: 12px 8px 0 12px;
  outline: none;
  display: flex;
  flex-wrap: wrap;
`;

const RestoreBuyOnBehalfButton = styled(Button)`
  margin: 12px;
  width: 206px;
  outline: none;
`;

const EllipsisText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MultiAccountsMenuBuyOnBehalf = ({
  user,
  translationKey,
  currentFileRequired,
}) => {
  const storeId = useSelector(getStoreId);
  const siteId = useSelector(getSiteId);
  const langId = useSelector(getLangId);
  const baseURL = useSelector(getBaseURL);
  const catalogId = useSelector(getCatalogId);

  const redirectUrl = encodeURIComponent(
    buildHomeURL(siteId, baseURL, langId, storeId, catalogId)
  );
  const restoreBOBUrl = `${baseURL}RestoreOriginalUserSetInSession?URL=${redirectUrl}`;

  return (
    <StyledMenuList>
      <StyledCalloutNotch />
      {user && (
        <RestoreBuyOnBehalfTitle
          currentFileRequired={currentFileRequired}
          translationKey={translationKey}
        >
          <div>{translationKey(currentFileRequired["BUYING_FOR"])}: </div>
          <EllipsisText>
            {user.firstName} {user.lastName}
          </EllipsisText>
        </RestoreBuyOnBehalfTitle>
      )}
      <RestoreBuyOnBehalfMenuLink>
        <form method="post" action={restoreBOBUrl}>
          <RestoreBuyOnBehalfButton type="submit">
            {translationKey(currentFileRequired["RETURN_TO_HEADQUARTERS"])}
          </RestoreBuyOnBehalfButton>
        </form>
      </RestoreBuyOnBehalfMenuLink>
    </StyledMenuList>
  );
};

export default React.memo(MultiAccountsMenuBuyOnBehalf);

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  TopHeaderMenu,
  TopNavBar,
  TopLogo,
  HeaderSearchBar,
  TopHeaderNav,
  HeaderMenu,
  TopNavigation,
} from "../HeaderStyles";

import threeMlogo from "./../../../../assets/images/3m.svg";
import OrderFav from "./../../../../assets/images/Favorite.svg";

import SavedCart from "./../../../../assets/images/savedcart.svg";
import MyCart from "./../../../../assets/images/Cart.svg";
import ArrowUp from "../../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../../assets/images/arrow-down.svg";

import UserDetails from "../../UserDetails";
import CatalogMenu from "../CatalogMenu";
import CustomerSupportMenu from "../CustomerSupportMenu";
import YourAccountMenu from "../YourAccountMenu";

import { connect } from "react-redux";
import {
  getBaseURL,
  getCatalogId,
  getLangId,
  getStoreId,
  getUser,
  isMultiAccountsCustomer,
  isCustomerServiceRepresentative,
  isBuyOnBehalf,
  getAppURL,
  isFeatureEnabled,
  getSiteId,
} from "../../../AppContext/selectors";
import BackToUSIPDropdown from "../BackToUSIPDropdown";
import { buildSavedCartsURL } from "../../../linkBuilders";
import { GetCurrentPendingOrderAction } from "../../../../Views/SavedCarts/actions";
import {
  isCurrentPendingOrderLoaded,
  getCurrentPendingOrder,
} from "../../../../Views/SavedCarts/selectors";
import { withTranslation } from "react-i18next";
import languageIcon from "./../../../../assets/images/language-icon.svg";
import dropdownicon from "./../../../../assets/images/Vector.svg";
import { UserContext } from "../../../../UserContext";
import {
  getCurrentFile,
  totalLanguagesAvailableWithId,
  jsonApiHeader,
  checkHttpStatus,
} from "../../../../Shared/utility";
import YourProductsMenu from "../YourProductsMenu";
import MultiAccountsMenu from "../MutiAccountsDropDown/MultiAccountsMenu";

const GradientBorder = styled.div`
  height: 5px;
  background: linear-gradient(90deg, #fff000 0%, #00b331 110.78%);
  width: 100%;
`;

const createLinks = (storeId, catalogId, langId, baseURL) => ({
  home: `${baseURL}StoreCatalogDisplay?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  orderFavorites: `${baseURL}PersonalizedOrderFormListView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  quickOrder: `${baseURL}QuickOrderView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  orderBands: `${baseURL}LogonForm?page=OrderBands&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  catalog: `${baseURL}StoreCatalogDisplay?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&showcat=Y`,
  myCart: `${baseURL}OrderItemDisplay?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&orderId=.&doPrice=N`,
  myOrders: `${baseURL}OrderInfoSearchView?function=next&pageNbr=0&lastOrderNbr=0&lastOrgnNbr=0&storeId=${storeId}&langId=${langId}&catalogId=${catalogId}`,
  myStatements: `${baseURL}AccountsReceivableAccountSummaryView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  mySetup: `${baseURL}UserRegistrationForm?storeId=${storeId}&langId=${langId}&catalogId=${catalogId}&editRegistration=Y`,
  contactUs: `${baseURL}ContactView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  logout: `${baseURL}Logoff?new=Y&langId=${langId}&catalogId=${catalogId}&storeId=${storeId}`,
  // RestoreOriginalUserSetInSession -> Logoff -> RSMPortalDisplayCmd
  restoreBOBAndLogout: `${baseURL}RestoreOriginalUserSetInSession?showlogin=Y&langId=${langId}&catalogId=${catalogId}&storeId=${storeId}&URL=${encodeURIComponent(
    "Logoff?URL=StoreCatalogDisplay"
  )}`,
  help: `${baseURL}HelpView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`,
  rewards: `${baseURL}StoreAppView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}#/my-rewards/home`,
});

class EStoreHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLanguageListOpen: false,
      cartItemsCount: 0,
    };
  }

  componentDidMount() {
    const { dispatch, storeId, currentPendingOrderLoaded } = this.props;
    if (!currentPendingOrderLoaded) {
      dispatch(GetCurrentPendingOrderAction({ storeId }));
    }

    if (storeId) {
      this.getCartItemsCount(storeId);
    }
  }

  toggleLanguageItemList = () => {
    this.setState((state) => ({
      isLanguageListOpen: !state.isLanguageListOpen,
    }));
  };

  closeLanguageDropdown = () => {
    if (this.state.isLanguageListOpen) {
      this.setState(() => ({
        isLanguageListOpen: false,
      }));
    }
  };

  setCookie = (cname, cvalue) => {
    document.cookie = cname + "=" + cvalue + ";";
  };

  setLanguage = async (lang, storeId, isSalesPortal, langId) => {
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.set("langId", langId.toString());
    url.search = search_params.toString();
    var new_url = url.toString();
    this.setCookie("UNITEK_LANGID", langId.toString());

    if (isSalesPortal) {
      window.location.href = new_url;
      console.log("sales portal condition true");
      return;
    }

    try {
      if (!isSalesPortal) {
        const token = document.getElementById("reactWCToken")?.value;

        const response = await fetch(
          `/wcs/resources/store/${storeId}/person/@self/languageCurrency`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              WCAuthToken: token ? token : "",
            },
            body: JSON.stringify({
              langId: langId.toString(),
            }),
          }
        );
        console.log("response", response.status);
        if (response.status === 200) {
          window.location.href = new_url;
        }
        if (response.status >= 200 && response.status < 204) {
          window.location.href = new_url;
        } else if (response.status === 204) {
          return true;
        } else {
          var error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      }
    } catch (error) {
      console.log("error update language in profile ..", error);
    }
  };

  isMySetupPage = () => {
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    if (search_params.has("editRegistration")) {
      return search_params.get("editRegistration") === "Y";
    }
    return false;
  };

  getCartItemsCount = async (storeId) => {
    const response = await fetch(`/wcs/resources/store/${storeId}/cart/@self`, {
      method: "GET",
      headers: jsonApiHeader(),
    });
    const data = await checkHttpStatus(response);
    if (data) {
      const orderItem = data.orderItem;
      var qtyCount = 0;
      orderItem.forEach((item) => {
        qtyCount += parseInt(item.quantity);
      });
      this.setState({ cartItemsCount: qtyCount });
    }
  };

  static contextType = UserContext;

  render() {
    const { selectedLanguage = "", setLanguage: setCurrentLanguage } =
      this?.context;
    const {
      activeMenu,
      storeId,
      catalogId,
      langId,
      baseURL,
      appURL,
      user,
      isMAC,
      isCSR,
      isBOB,
      currentPendingOrder,
      isSavedCarts,
      t: translationKey = {},
    } = this.props;
    const { isLanguageListOpen, cartItemsCount } = this.state;
    var currentFileRequired = getCurrentFile(selectedLanguage);

    console.log({ isBOB, isMAC, user, isCSR });

    const savedCartsURL = buildSavedCartsURL(appURL);
    const links = createLinks(storeId, catalogId, langId, baseURL);
    const activeClass = (menuName) =>
      activeMenu === menuName ? "headerMenuActive" : "";

    return (
      <div onClick={this.closeLanguageDropdown}>
        <div
          style={{
            borderBottom: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TopNavigation isLanguageListOpen={isLanguageListOpen}>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                // width: "30%",
              }}
            >
              <fieldset
                className={`language-container ${
                  isLanguageListOpen ? "language-container-open" : ""
                }`}
              >
                <div
                  onClick={this.toggleLanguageItemList}
                  className="icon-container"
                >
                  <img
                    className="lang-icon"
                    src={languageIcon}
                    height="15px"
                    width="15px"
                    alt="lang_icon"
                  />
                  <div className="selected-language">{selectedLanguage}</div>
                  <img
                    className="dropdown-icon"
                    src={isLanguageListOpen ? ArrowUp : ArrowDown}
                    height="24px"
                    width="24px"
                    alt="vector_icon"
                  />
                </div>
              </fieldset>
              {isLanguageListOpen &&
              totalLanguagesAvailableWithId.length > 0 ? (
                <div class="lang-popup">
                  <i class="small-arrow"></i>{" "}
                  {totalLanguagesAvailableWithId.map((currentLangObj) => {
                    let displayName = currentLangObj.displayName;
                    let localeName = currentLangObj.localeName;
                    let languageId = currentLangObj.langId;

                    return (
                      <div className="language-items">
                        <div className="radio-group-lang">
                          <input
                            onClick={() => {
                              this.setLanguage(
                                localeName,
                                storeId,
                                isCSR || isBOB || isMAC,
                                languageId
                              );
                              this.toggleLanguageItemList();
                              setCurrentLanguage(localeName);
                            }}
                            type="radio"
                            id={displayName}
                            name="langauges"
                            value={localeName}
                            checked={selectedLanguage == localeName}
                          />
                          <label htmlFor={displayName} onClick={() => {
                              this.setLanguage(
                                localeName,
                                storeId,
                                isCSR || isBOB || isMAC,
                                languageId
                              );
                              this.toggleLanguageItemList();
                              setCurrentLanguage(localeName);
                            }}>{displayName}</label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>

            {isMAC && (
              <MultiAccountsMenu
                currentFileRequired={currentFileRequired}
                translationKey={translationKey}
              />
            )}

            <CustomerSupportMenu
              currentFileRequired={currentFileRequired}
              translationKey={translationKey}
              contactUsLink={links.contactUs}
              helpLink={links.help}
              activeMenu={activeMenu}
              activeClass={activeClass}
            />
            {isCSR && isBOB ? (
              <BackToUSIPDropdown />
            ) : isMAC && isBOB ? (
              <a
                style={{ marginLeft: "5px", fontSize: "14px" }}
                href={links.restoreBOBAndLogout}
              >
                {translationKey(currentFileRequired["LOGOUT"])}
              </a>
            ) : (
              <a
                style={{ marginLeft: "5px", fontSize: "14px" }}
                href={links.logout}
              >
                {translationKey(currentFileRequired["LOGOUT"])}
              </a>
            )}
          </TopNavigation>
        </div>

        <TopHeaderMenu
          onClick={this.closeLanguageDropdown}
          isLanguageListOpen={isLanguageListOpen}
        >
          <a href={links.home}>
            <TopLogo src={threeMlogo} />
          </a>
          <TopNavBar>
            <TopHeaderNav>
              <HeaderSearchBar
                translationKey={translationKey}
                currentFileRequired={currentFileRequired}
                storeId={storeId}
                catalogId={catalogId}
                langId={langId}
              />
              <div className="navbar-links">
                <YourAccountMenu
                  currentFileRequired={currentFileRequired}
                  translationKey={translationKey}
                  accountDetailsLink={links.mySetup}
                  orderHistoryLink={links.myOrders}
                  managePaymentsLink={links.myStatements}
                  myRewardLink={links.rewards}
                  logoutLink={links.logout}
                  activeClass={activeClass}
                  user={user}
                  activeMenu={activeMenu}
                />

                <a
                  href={links.orderFavorites}
                  className={`order-favorites ${activeClass(
                    "order-favorites"
                  )}`}
                >
                  <img alt="Order favorites menu" src={OrderFav} />{" "}
                  {translationKey(currentFileRequired["ORDER_FAVORITES"])}
                </a>
                <a
                  href={links.myCart}
                  className={`my-cart ${activeClass("my-cart")}`}
                >
                  <img alt="My cart menu" src={MyCart} />{" "}
                  {translationKey(currentFileRequired["MY_CART"])}{" "}
                  <span style={{ margin: "0px 5px" }}>|</span>
                  <span>
                    {cartItemsCount}{" "}
                    {translationKey(currentFileRequired["ITEMS"])}
                  </span>
                  {/* {currentPendingOrder?.orderId
              ? `(${currentPendingOrder.orderId})`
              : ""} */}
                </a>
              </div>
            </TopHeaderNav>
          </TopNavBar>
        </TopHeaderMenu>
        <HeaderMenu
          fullwidth
          isLanguageListOpen={this.state.isLanguageListOpen}
        >
          <YourProductsMenu
            currentFileRequired={currentFileRequired}
            translationKey={translationKey}
            orderHistoryLink={links.myOrders}
            orderFavoritesLink={links.orderFavorites}
            savedCartsLink={savedCartsURL}
            orderBandsLink={links.orderBands}
            quickOrderLink={links.quickOrder}
            activeClass={activeClass}
            isSavedCarts={isSavedCarts}
            activeMenu={activeMenu}
          />

          <CatalogMenu
            currentFileRequired={currentFileRequired}
            translationKey={translationKey}
            className={activeClass("catalog")}
          />
          <a
            style={{ fontSize: "16px" }}
            href={links.myOrders}
            className={activeClass("my-orders")}
          >
            {translationKey(currentFileRequired["MY_ORDERS"])}
          </a>
          <a
            style={{ fontSize: "16px" }}
            href={links.myStatements}
            className={activeClass("my-statement")}
          >
            {translationKey(currentFileRequired["MY_STATEMENT"])}
          </a>
        </HeaderMenu>
        <GradientBorder />
        {(isMAC && isBOB && user) || isCSR || this.isMySetupPage() ? (
          <UserDetails
            currentFileRequired={currentFileRequired}
            translationKey={translationKey}
            user={user}
          />
        ) : null}
      </div>
    );
  }
}

EStoreHeader.propTypes = {
  activeMenu: PropTypes.string,
  user: UserDetails.propTypes.user,
  siteId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  storeId: getStoreId(state),
  catalogId: getCatalogId(state),
  langId: getLangId(state),
  baseURL: getBaseURL(state),
  appURL: getAppURL(state),
  user: getUser(state),
  isMAC: isMultiAccountsCustomer(state),
  isCSR: isCustomerServiceRepresentative(state),
  isBOB: isBuyOnBehalf(state),
  currentPendingOrderLoaded: isCurrentPendingOrderLoaded(state),
  currentPendingOrder: getCurrentPendingOrder(state),
  isSavedCarts: isFeatureEnabled(state, "saved-carts"),
  siteId: getSiteId(state),
});

export default withTranslation()(connect(mapStateToProps)(EStoreHeader));

import { ProductDescriptionActionTypes } from '../constants/ApiConstants';

const initialState = {
    loadingDetails: false,
    loadingOptions: false,
    productDetails: null,
    productSpecifications: null,
    totalProductOptions: null,
    productOptions: null,
    facets: null,
    addingToCart: false,
    addToCart: null,
    addingToOrderFavorites: false,
    addToOrderFavorites: null,
    errorMessages: null,
};


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ProductDescriptionActionTypes.get_productDetails.REQUEST:
            return {
                ...state,
                loadingDetails: true,
                productDetails: null,
            };
        case ProductDescriptionActionTypes.get_productDetails.SUCCESS:
            return {
                ...state,
                loadingDetails: false,
                productDetails: payload,
            };
        case ProductDescriptionActionTypes.get_productDetails.FAILURE:
            return {
                ...state,
                loadingDetails: false,
                productDetails: null
            };
        case ProductDescriptionActionTypes.get_prouctSpecifications.REQUEST:
            return {
                ...state,
                loadingOptions: true,
                productSpecifications: null,
                productOptions: null,
                facets: null,
                totalProductOptions: null,
            };
        case ProductDescriptionActionTypes.get_prouctSpecifications.SUCCESS:
            return {
                ...state,
                loadingOptions: false,
                productSpecifications: payload,
                productOptions: payload.catalogEntryView,
                facets: payload.facetView,
                totalProductOptions: payload.recordSetTotal,
            };
        case ProductDescriptionActionTypes.get_prouctSpecifications.FAILURE:
            return {
                ...state,
                loadingOptions: false,
                productSpecifications: null,
                productOptions: null,
                facets: null,
                totalProductOptions: null,
            };
        case ProductDescriptionActionTypes.get_filteredProductOptions.REQUEST:
            return {
                ...state,
                loadingOptions: true,
                productOptions: null,
            };
        case ProductDescriptionActionTypes.get_filteredProductOptions.SUCCESS:
            return {
                ...state,
                loadingOptions: false,
                productOptions: payload.catalogEntryView,
                facets: payload.facetView,
                totalProductOptions: payload.recordSetTotal,
            };
        case ProductDescriptionActionTypes.get_filteredProductOptions.FAILURE:
            return {
                ...state,
                loadingOptions: false,
            };
        case ProductDescriptionActionTypes.get_moreProductOptions.REQUEST:
            return {
                ...state,
                loadingOptions: true,
            };
        case ProductDescriptionActionTypes.get_moreProductOptions.SUCCESS:
            return {
                ...state,
                loadingOptions: false,
                // replacing rather than appending because we are
                // loading small set of products initially (pageSize = 50),
                // then replacing with a large set (pageSize = 1000)
                productOptions: payload.catalogEntryView,
            };
        case ProductDescriptionActionTypes.get_moreProductOptions.FAILURE:
            return {
                ...state,
                loadingOptions: false,
            };
        case ProductDescriptionActionTypes.add_itemsToCart.REQUEST:
            return {
                ...state,
                addingToCart: true,
                addToCart: null,
                errorMessages: null,
            };
        case ProductDescriptionActionTypes.add_itemsToCart.SUCCESS:
            return {
                ...state,
                addingToCart: false,
                addToCart: payload,
            };
        case ProductDescriptionActionTypes.add_itemsToCart.FAILURE:
            return {
                ...state,
                addingToCart: false,
                errorMessages: payload,
            };
        case ProductDescriptionActionTypes.add_toOrderFavorites.REQUEST:
            return {
                ...state,
                addingToOrderFavorites: true,
                addToOrderFavorites: null,
                errorMessages: null,
            };
        case ProductDescriptionActionTypes.add_toOrderFavorites.SUCCESS:
            return {
                ...state,
                addingToOrderFavorites: false,
                addToOrderFavorites: payload,
            };
        case ProductDescriptionActionTypes.add_toOrderFavorites.FAILURE:
            return {
                ...state,
                addingToOrderFavorites: false,
                errorMessages: payload,
            };
        default:
            return state;
    }
};

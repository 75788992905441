import React, { useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from 'react-redux';
import { GetFaqProperties } from './actions/index';
import { getFaqProperties, isFaqPropertiesLoading as propertiesLoading } from './selectors/index';
import { getStoreId } from '../../Shared/AppContext/selectors';

export default function ExpansionContainer() {
    const dispatch = useDispatch();
    const data = useSelector(getFaqProperties);
    const isFaqPropertiesLoading = useSelector(propertiesLoading);
    const storeId = useSelector(getStoreId);

    useEffect(() => {
        if (!data && !isFaqPropertiesLoading) {
            dispatch(GetFaqProperties({ storeId }));
        }
    }, [data, isFaqPropertiesLoading, dispatch, storeId]);

    return (
        <>
            {data && <div style={{ fontSize: '16px', lineHeight: '19px', marginBottom: '40px' }}>
                <ExpansionPanel >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="panel1-header"
                        style={{ padding: '10px 20px 0px' }}
                    >
                        <Typography><b>{data?.properties?.FAQ_TOPIC_1}</b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: data?.properties?.FAQ_ANSWER_1 }} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="panel2-header"
                        style={{ padding: '10px 20px 0px' }}

                    >
                        <Typography><b>{data?.properties?.FAQ_TOPIC_2}
                        </b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: data?.properties?.FAQ_ANSWER_2 }} />

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="panel3-header"
                        style={{ padding: '10px 20px 0px' }}

                    >
                        <Typography><b>{data?.properties?.FAQ_TOPIC_3}
                        </b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: data?.properties?.FAQ_ANSWER_3 }} />

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {data?.properties?.FAQ_TOPIC_4 && data?.properties?.FAQ_ANSWER_4 && <ExpansionPanel >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="panel3-header"
                        style={{ padding: '10px 20px 0px' }}

                    >
                        <Typography><b>{data?.properties?.FAQ_TOPIC_4}
                        </b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: data?.properties?.FAQ_ANSWER_4 }} />

                    </ExpansionPanelDetails>
                </ExpansionPanel>}
            </div>}
        </>
    );
}
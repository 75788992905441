import React from 'react';
import styled from 'styled-components/macro';
import Cross from '../../../assets/images/cross-white.svg';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import Button from './Button'

const StyledDialogOverlay = styled(DialogOverlay)`
  background: transparent;
`

const StyledDialogContent = styled(DialogContent)`
  width: 500px;
  padding: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  margin: 25vh auto;
`

const StyledDialogTitle = styled.div`
  font-family: fontregular;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 16px;
  line-height: 20px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    cursor: pointer;
  }
`

const StyledDialogInputContainer = styled.div`
  padding: 16px 16px 0 16px;
`

const StyledDialogButtonContainer = styled.div`
  position: relative;
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

const StyledDialogButton = styled(Button)`
  font-family: fontregular;
  outline: none;
  width: 215px;
`

const Dialog = (props) => {
  const {
    showDialog,
    close,
    title,
    confirmActionLabel,
    confirmActionHandleClick,
    cancelActionLabel,
    cancelActionHandleClick,
    inputComponent
  } = props

  return (
    <div>
      <StyledDialogOverlay isOpen={showDialog} onDismiss={close}>
        <StyledDialogContent aria-label="dialog">
          <StyledDialogTitle>
            {title}
            <img alt="cancel" src={Cross} onClick={close} />
          </StyledDialogTitle>
          <StyledDialogInputContainer>
            {inputComponent}
          </StyledDialogInputContainer>
          <StyledDialogButtonContainer>
            <StyledDialogButton onClick={confirmActionHandleClick}>
              {confirmActionLabel}
            </StyledDialogButton>
            <StyledDialogButton onClick={cancelActionHandleClick}>
              {cancelActionLabel}
            </StyledDialogButton>
          </StyledDialogButtonContainer>
        </StyledDialogContent>
      </StyledDialogOverlay>
    </div>
  )
}

export default Dialog;
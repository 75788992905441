import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { MenuLink, MenuList, MenuButton } from "@reach/menu-button";
import { useSelector } from "react-redux";
import { getMultiAccounts } from "./../selectors";
import {
  getStoreId,
  getLangId,
  getBaseURL,
  getCatalogId,
  getSiteId,
} from "../../../AppContext/selectors";
import { buildHomeURL } from "../../../linkBuilders";
import searchIcon from "../../../../assets/images/searchicon.png";

const StyledCalloutNotch = styled.i`
  border: solid #ccc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 30px;
  top: -4px;
  background-color: rgba(255, 255, 255, 1);
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const StyledListWithScollbar = styled.div`
  overflow-y: scroll;
`;

const StyledMenuList = styled(MenuList)`
  background-color: rgba(255, 255, 255, 1);
  margin-left: -70px;
  border: 1px solid #ccc;
  border-sizing: border-box;
  width: 220px;
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.15);
  &:focus {
    outline: none;
  }
`;

const MenuListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
`;

const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
  display: block;
  padding: 12px 16px;
  border-top: 1px solid #ccc;
  margin-top: -1px;
  color: #4b4b4b;
  overflow: hidden;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
  &:hover {
    font-weight: bold;
  }
`;
const StyledButton = styled.button`
  border: none;
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
  display: block;
  padding: 12px 16px;
  border-top: 1px solid #ccc;
  margin-top: -1px;
  color: #4b4b4b;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: inherit;
  background: white;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    font-weight: bold;
  }
`;

const Title = styled.div`
  font-family: fontregular;
  font-style: normal;
  font-size: 16px;
  color: #4b4b4b;
  text-decoration: none;
  font-weight: bold;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  border-top: 1px solid #ccc;
  color: #4b4b4b;
  font-size: 14px;
  font-family: fontregular;
  font-style: normal;
  text-align: right;
  padding: 0 2px;
`;

const TitleContainer = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 12px 8px;
`;

const StyledInput = styled.input`
  font-size: 16px;
  font-family: fontregular;
  font-style: normal;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border-radius: 0;
  border: none;
  margin-top: 8px;
`;

const MultiAccountsMenuList = ({
  isExpanded,
  translationKey,
  currentFileRequired,
}) => {
  const storeId = useSelector(getStoreId);
  const siteId = useSelector(getSiteId);
  const langId = useSelector(getLangId);
  const baseURL = useSelector(getBaseURL);
  const catalogId = useSelector(getCatalogId);
  const [inputValue, setInputValue] = useState("");
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const multiAccounts = useSelector(getMultiAccounts);
  const el = useRef(null);
  const redirectUrl = buildHomeURL(siteId, baseURL, langId, storeId, catalogId);
  //const createStartBOBUrl = (runAsUserId) => `${baseURL}RunAsUserSetInSession?runAsUserId=${encodeURIComponent(runAsUserId)}&URL=${redirectUrl}`

  useEffect(() => {
    if (!isExpanded) {
      setOpenSearchBox(false);
      setInputValue("");
    }
  }, [isExpanded]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (el && el.current) {
      el.current.scrollIntoView();
    }
  };

  const upperCaseInputValue = inputValue.toUpperCase();
  const allAccounts = multiAccounts?.resultList ?? [];
  const filteredAccounts =
    inputValue === ""
      ? allAccounts
      : allAccounts.filter(
          (account) =>
            (account.firstName &&
              account.firstName.toUpperCase().includes(upperCaseInputValue)) ||
            (account.lastName &&
              account.lastName.toUpperCase().includes(upperCaseInputValue)) ||
            (account.customerNumber &&
              account.customerNumber
                .toString()
                .includes(upperCaseInputValue)) ||
            (account.address &&
              account.address.toUpperCase().includes(upperCaseInputValue)) ||
            (account.city &&
              account.city.toUpperCase().includes(upperCaseInputValue)) ||
            (account.zip &&
              account.zip.toString().includes(upperCaseInputValue))
        );
  const accounts = filteredAccounts.slice(0, 30);

  const displayLength = accounts.length;
  const filteredLength = filteredAccounts.length;
  const authToken = document.getElementById("reactWCToken")?.value;

  return (
    multiAccounts &&
    multiAccounts.resultList && (
      <StyledMenuList>
        <StyledCalloutNotch />
        <MenuListContainer>
          <TitleContainer>
            <Title>
              <span>
                {translationKey(currentFileRequired["BUY_ON_BEHALF"])}
              </span>
              <img
                alt="Search icon"
                src={searchIcon}
                onClick={() => {
                  setOpenSearchBox(true);
                }}
              />
            </Title>
            {openSearchBox && (
              <StyledInput
                value={inputValue}
                onChange={handleChange}
                placeholder={"Search for an account"}
                autoFocus
              />
            )}
          </TitleContainer>
          <StyledListWithScollbar>
            {accounts.map((account, index) => (
              <form
                key={account.usersId}
                action="RunAsUserSetInSession"
                method="post"
                id={account.usersId}
              >
                <StyledButton
                  key={account.usersId}
                  ref={index === 0 ? el : null}
                  type="submit"
                >
                  <input type="hidden" name="storeId" value={storeId} />
                  <input type="hidden" name="langId" value={langId} />
                  <input type="hidden" name="catalogId" value={catalogId} />
                  <input
                    type="hidden"
                    name="runAsUserId"
                    value={account.usersId}
                  />
                  <input type="hidden" name="URL" value={redirectUrl} />
                  <input type="hidden" name="authToken" value={authToken} />
                  <div>
                    {account.customerNumber} - {account.firstName}{" "}
                    {account.lastName}
                  </div>
                  <div>{account.address}</div>
                  <div>
                    {account.city}
                    {account.state ? `, ${account.state}` : ""} {account.zip}
                  </div>
                </StyledButton>
              </form>
            ))}
          </StyledListWithScollbar>
        </MenuListContainer>
        <Footer>
          showing: {displayLength}/{filteredLength}
        </Footer>
      </StyledMenuList>
    )
  );
};

export default React.memo(MultiAccountsMenuList);

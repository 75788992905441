import styled from 'styled-components'
import Texture from '../../../assets/images/Texture.png'

const BannerArea = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
  width: 100%;
  &:empty {
    display: none;
  }
  &:not(:empty):after {
    display: block;
    content: ' ';
    background-image: url(${Texture});
    height: 16px;
  }
`

export default BannerArea
import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Pagination from "@material-ui/lab/Pagination";
import { GetLoyaltyActivities } from "./actions/index";
import {
  getLoyaltyActivities,
  isLoyaltyActivitiesLoading,
} from "./selectors/index";
import {
  getStoreId,
  getLangId,
  getBaseURL,
  getCatalogId,
  getUser,
} from "../../Shared/AppContext/selectors";

const ProgressBarText = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 80px 0px 30px;
`;
const DisplayAlignerTable = ({ currentFileRequired, translationKey }) => {
  const dispatch = useDispatch();
  const activities = useSelector(getLoyaltyActivities);
  const userDetails = useSelector(getUser);

  const activitiesLoading = useSelector(isLoyaltyActivitiesLoading);
  const storeId = useSelector(getStoreId);
  const tableData = activities && activities.activityRecordList;
  const currentRecordCount = activities && activities?.recordCountPerPage;
  const totalrecordCount = activities && activities?.totalRecordCount;
  const totalPages = activities
    ? Math.ceil(activities?.totalRecordCount / activities?.recordCountPerPage)
    : 1;
  const [rowsPerPage, setRowsPerPage] = React.useState(currentRecordCount);
  const [orderBy, setOrderBy] = React.useState(tableData);
  const [sortedField, setSortedField] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [columnOrder, setColumnOrder] = React.useState(0);
  const langId = useSelector(getLangId);
  const baseURL = useSelector(getBaseURL);
  const catalogId = useSelector(getCatalogId);
  const [selectedCol, setSelectedCol] = React.useState(null);

  const columns = [
    {
      id: "entryDate",
      label: translationKey(currentFileRequired["DATE"]),
      width: 100,
      align: "center",
      borderRight: "1px solid #CCCCCC",
      order: 1,
    },
    {
      id: "invoiceNumber",
      label: translationKey(currentFileRequired["INVOICE_NUMBER"]),
      onClick: "routeRedirect",
      width: 100,
      align: "center",
      color: "#03c",
      borderRight: "1px solid #CCCCCC",
      order: 0,
    },
    {
      id: "orderNumberForDisplay",
      label: translationKey(currentFileRequired["ORDER_NUMBER"]),
      width: 100,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      color: "#03c",
      borderRight: "1px solid #CCCCCC",
      order: 2,
      onClick: "routeRedirect",
    },
    {
      id: "qualifyFactor",
      label: translationKey(currentFileRequired["QUALIFYING_CASES"]),
      width: 100,
      align: "center",
      format: (value) => value.replace("Sale of ", ""),
      order: 3,
    },
  ];

  // const constructSetCurrentPendingOrderUrl = (value) =>
  //     encodeURIComponent(`SetPendingOrder?orderId=${value}&URL=OrderItemDisplay&doPrice=N`)
  const OrderNumberRedirect = (row) =>
    `${baseURL}OrderInfoInvoiceDetailView?invNbr=${row.invoiceNumber}&ordNbr=${row.orderNumber}&ordGenNbr=${row.orderGenNumber}&seqNbr=${row.historySeqNumber}&customerNumber=${userDetails.customerNumber}&headerTrx=true&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&&firstName=${userDetails.firstName}&lastName=${userDetails.lastName}`;

  useEffect(() => {
    if (!activitiesLoading && !activities) {
      dispatch(
        GetLoyaltyActivities({
          storeId,
          pageNumber: 1,
          qualifiedSaleType: 1,
          sortColumn: 0,
          sortSequence: 0,
        })
      );
    }
  }, [activities, activitiesLoading, storeId, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetLoyaltyActivities({
        storeId,
        pageNumber: newPage,
        qualifiedSaleType: 1,
        sortColumn: columnOrder,
        sortSequence: sortedField,
      })
    );
  };

  const handleSort = (order) => {
    setColumnOrder(order);

    if (!!selectedCol && selectedCol === order.toString()) {
      dispatch(
        GetLoyaltyActivities({
          storeId,
          pageNumber: page,
          qualifiedSaleType: 1,
          sortColumn: order,
          sortSequence: sortedField === 0 ? 1 : 0,
        })
      );

      setSortedField(sortedField === 0 ? 1 : 0);
    } else {
      setSelectedCol(order.toString());
      dispatch(
        GetLoyaltyActivities({
          storeId,
          pageNumber: page,
          qualifiedSaleType: 1,
          sortColumn: order,
          sortSequence: 0,
        })
      );

      setSortedField(0);
    }
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <ProgressBarText>
          {translationKey(currentFileRequired["CLARITY_ALIGNERS_ORDERS"])}
        </ProgressBarText>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              width: "100%",
              borderWidth: 0,
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      width: column.width,
                      backgroundColor: "black",
                      color: "white",
                      height: "10px",
                      borderRight: "1px solid white",
                      fontWeight: "700",
                      padding: "8px",
                      position: "relative",
                    }}
                  >
                    <a
                      style={{ color: "white" }}
                      onClick={() => handleSort(column.order)}
                    >
                      {" "}
                      {column.label}
                      {column.order == columnOrder && sortedField == 1 ? (
                        <ArrowDropDownIcon
                          style={{ position: "absolute", top: "5px" }}
                        />
                      ) : column.order == columnOrder ? (
                        <ArrowDropUpIcon
                          style={{ position: "absolute", top: "5px" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          style={{
                            color: "#808080",
                            position: "absolute",
                            top: "5px",
                          }}
                        />
                      )}{" "}
                    </a>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableData && (
              <TableBody>
                {tableData.map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.code}>
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color: column.color,
                              borderRight: column.borderRight,
                              padding: "8px",
                            }}
                          >
                            <a
                              //  onClick={column.onClick == 'routeRedirect' && OrderNumberRedirect(row)}
                              href={OrderNumberRedirect(row)}
                              style={{
                                color: column.color ? column.color : "black",
                                cursor:
                                  column.onClick == "routeRedirect"
                                    ? "ponter"
                                    : "none",
                                pointerEvents:
                                  column.onClick == "routeRedirect"
                                    ? "inherit"
                                    : "none",
                              }}
                            >
                              {value}
                            </a>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {activities && (
          <Pagination
            style={{ float: "right", padding: "30px 0px" }}
            component="div"
            count={Math.ceil(totalrecordCount / 25)}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        )}
      </Paper>
    </>
  );
};

export default DisplayAlignerTable;

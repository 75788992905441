import { SearchResultsActionTypes } from '../constants/ApiConstants';

const initialState = {
    loading: false,
    productViewDetails: null,
    category: null,
};


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SearchResultsActionTypes.get_searchResults.REQUEST:
            return {
                ...state,
                loading: true,
                productViewDetails: null,
            };
        case SearchResultsActionTypes.get_searchResults.SUCCESS:
            return {
                ...state,
                loading: false,
                productViewDetails: payload,
            };
        case SearchResultsActionTypes.get_searchResults.FAILURE:
            return {
                ...state,
                loading: false,
                productViewDetails: null
            };
        case SearchResultsActionTypes.get_searchCategoryName.REQUEST:
            return {
                ...state,
                category: null,
            }
        case SearchResultsActionTypes.get_searchCategoryName.SUCCESS:
            return {
                ...state,
                category: payload,
            }
        case SearchResultsActionTypes.get_searchCategoryName.FAILURE:
            return {
                ...state,
                category: null,
            }
        default:
            return state;
    }
};

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers/index";

// import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "./Shared/Components/GenericComponent/Container";
import { setAppContext } from "./Shared/AppContext/actions";
import Espot from "./Shared/Components/Espot";
import OrderCommentsSideBar from "./Shared/Components/CSROrderComments/OrderCommentsSideBar";
import AccountLockedAlertDialog from "./Shared/Components/AlertDialog/AccountLockedAlertDialog";
import { UserProvider } from "./UserContext";
import Header from "./Shared/Components/Header";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

global.setAppContext = ({
  storeId,
  catalogId,
  langId,
  baseURL,
  appURL,
  dmContextPath,
  dmImagePath,
  dmSubstitutionVariables = {},
  user,
  siteId,
  forUser,
  features = [],
  callerId,
}) => {
  store.dispatch(
    setAppContext({
      storeId,
      catalogId,
      langId,
      baseURL,
      appURL,
      dmContextPath,
      dmImagePath,
      dmSubstitutionVariables,
      user,
      siteId,
      forUser,
      features,
      callerId,
    })
  );
};

global.renderApp = (node) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    node
  );
};

global.renderHeader = ({ activeMenu }, node) => {
  ReactDOM.render(
    <Provider store={store}>
      <Container>
        <UserProvider>
          <Header activeMenu={activeMenu} />
        </UserProvider>
      </Container>
    </Provider>,
    node
  );
};

global.renderEspot = ({ name, vertical, variables = {} }, node) => {
  ReactDOM.render(
    <Provider store={store}>
      <Container>
        <Espot name={name} vertical={vertical} variables={variables} />
      </Container>
    </Provider>,
    node
  );
};
global.renderAccountLockedAlert = () => {
  const root = document.body;
  const alertContainer = document.createElement("div");
  root.appendChild(alertContainer);

  ReactDOM.render(<AccountLockedAlertDialog />, alertContainer);

  root.removeChild(alertContainer);
};
global.renderCSRComments = ({ orderId }, node) => {
  ReactDOM.render(
    <Provider store={store}>
      <OrderCommentsSideBar width={300} height={300} orderId={orderId} />
    </Provider>,
    node
  );
};

if (process.env.NODE_ENV === "development") {
  const storeId = process.env.REACT_APP_STORE_ID;
  const catalogId = process.env.REACT_APP_CATALOG_ID;
  const langId = process.env.REACT_APP_LANG_ID;
  const baseURL = process.env.REACT_APP_BASE_URL;
  const appURL = "/";
  const dmContextPath = process.env.REACT_APP_DM_CONTEXT_PATH;
  const dmImagePath = process.env.REACT_APP_DM_IMAGE_PATH;
  const dmSubstitutionVariables = JSON.parse(
    process.env.REACT_APP_DM_SUBSTITUTION_VARIABLES ?? "{}"
  );
  const user = {
    lastName: "Dontist&trade;",
    logonId: "1-1234599",
    customerNumber: "12345",
    billingFirstName: "Michael",
    billingLastName: "TEST ACCOUNT &dash; L8",
    billingAddressLine1: "2724 SOUTH PECK ROAD",
    billingCity: "MONROVIA",
    billingState: "CA",
    billingZipCode: "91016",
    billingCountry: "USA",
  };
  const salesRep = {
    lastName: "Fanzoi",
    logonId: "sr705553",
  };
  const commonContext = {
    storeId,
    catalogId,
    langId,
    baseURL,
    appURL,
    dmContextPath,
    dmImagePath,
    dmSubstitutionVariables,
  };
  global.setAppContext(commonContext);
  global.renderEStoreApp = (opts = {}) => {
    global.setAppContext({
      ...commonContext,
      user,
      siteId: "e-store",
      features: opts.features,
      callerId: opts.callerId,
    });
    global.renderApp(document.getElementById("root"));
  };
  global.renderEStoreHeader = (opts = {}) => {
    global.setAppContext({
      ...commonContext,
      user,
      siteId: "e-store",
      features: opts.features,
    });
    global.renderHeader({}, document.getElementById("root"));
  };
  global.renderSalesPortalApp = () => {
    global.setAppContext({
      ...commonContext,
      user: salesRep,
      siteId: "sales-portal",
    });
    global.renderApp(document.getElementById("root"));
  };
  global.renderSalesPortalHeader = () => {
    global.setAppContext({
      ...commonContext,
      user: salesRep,
      siteId: "sales-portal",
    });
    global.renderHeader({}, document.getElementById("root"));
  };
  global.renderLoyaltyRewards = (opts = {}) => {
    user.isLoyaltyUser = opts.loyaltyFlags[0];
    user.isOCPUser = opts.loyaltyFlags[1];
    global.setAppContext({
      ...commonContext,
      user,
      siteId: "e-store",
    });
    global.renderApp(document.getElementById("root"));
  };
  switch (process.env.REACT_APP_RENDER_MODE) {
    case "eStoreApp":
      global.renderEStoreApp();
      break;
    case "eStoreHeader":
      global.renderEStoreHeader();
      break;
    case "salesPortalApp":
      global.renderSalesPortalApp();
      break;
    case "salesPortalHeader":
      global.renderSalesPortalHeader();
      break;
    default:
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import MainTitle from '../GenericComponent/MainTitle'

const TitleWrapper = styled.div`
    display:flex;
    margin-top:10px;
`;

const RightTitle = styled.div`
  flex: 1;
  text-align: right;
  float: right;
  font-size: 26px;
  font-family: fontbold;
  line-height: 32px;
  & span {
    font-family: fontregular;
  }
`

const ProductListTitle = ({ title, productCount,translationKey,currentFileRequired }) => {
  return (
    <TitleWrapper>
      <MainTitle>{title}</MainTitle>
      <RightTitle><MainTitle>{productCount ?? ''} <span>{translationKey(currentFileRequired['PRODUCTS'])}</span></MainTitle> </RightTitle>
    </TitleWrapper>
  )
}

ProductListTitle.propTypes = {
  title: PropTypes.string,
  productCount: PropTypes.number,
}

export default ProductListTitle
import React from "react";
import styled from "styled-components/macro";
import threeMlogo from "./../../../assets/images/3m.svg";
import SearchBar from "../SearchBar";

export const TopHeaderMenu = styled.div`
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 10px;
  ${(props) => (props.isLanguageListOpen ? "margin-top:0px" : "")}
`;

export const TopNavBar = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: flex-end;
  flex: 1;
  & a {
    /* color: #000; */
    color: rgba(75, 75, 75, 1);
    font-family: fontregular;
    padding: 11px 0px;
    text-align: center;
    margin-left: 8px;
    font-size:16px;
  }
`;

export const TopNavigation = styled.div`
  display: flex;

  justify-content: end;
  align-items: center;
  ${(props) => (props.isLanguageListOpen ? "padding:0px;" : "")}

  > a {
    /* color: #000; */
    color: rgba(75, 75, 75, 1);
    font-family: fontregular;
    padding: 11px 0px;
    text-align: center;
    white-space: nowrap;
    text-decoration: auto;
  }
`;

export const TopLogo = styled.img`
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  height: 40px;
`;

export const HeaderSearchBar = styled(SearchBar)`
  min-width: 477px;
  width: 61%;
  margin-right: 20px;
`;

export const TopHeaderNav = styled.ul`
  flex-direction: row;
  margin-left: auto;
  display: flex;
  padding-left: 0;

  list-style: none;

  box-sizing: border-box;
  align-items: center;
  width: 100%;
  > a {
    white-space: nowrap;
  }
`;

export const HeaderMenu = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-bottom: 4px !important;
  padding-top: 0px !important;
  justify-content: flex-start;
  & img {
    vertical-align: middle;
  }
  & a,
  & button {
    font-family: fontregular;
    color: #4b4b4b;
    font-size: 14px;
    line-height: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    ${(props) =>
      props.fullwidth
        ? "margin: 0 20px;"
        : "margin-left: 13px; margin-right: 21px;"}
  }
`;

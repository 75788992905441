import {
    actionCreator,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../Shared/utility';

export {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader
};

export const ProductDescriptionActionTypes = {
    get_productDetails: createRequestActionTypes('GET_PRODUCT_DETAILS'),
    get_prouctSpecifications: createRequestActionTypes('GET_PRODUCT_SPECIFICATIONS'),
    get_filteredProductOptions: createRequestActionTypes('GET_FILTERED_PRODUCT_OPTIONS'),
    get_moreProductOptions: createRequestActionTypes('GET_MORE_PRODUCT_OPTIONS'),
    add_itemsToCart: createRequestActionTypes('ADD_ITEMS_TO_CART'),
    add_toOrderFavorites: createRequestActionTypes('ADD_TO_ORDER_FAVORITES'),
};
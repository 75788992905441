import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useLocation, useParams, withRouter } from 'react-router-dom';
import queryString from 'query-string'
import { useSelector } from 'react-redux';
import { getAppURL } from '../../AppContext/selectors';
import { toArray } from '../../utility';
import { buildCategoryURL } from '../../linkBuilders';

const MenuList = styled.div`
    background: #f9f9f9;
    margin-bottom: 32px;
    flex-direction: column!important;
    display:flex;
    & a {
    border-bottom: 1px solid #000;
    min-height: 33.6px;
    line-height: 20px;
    color: #000;
    font-size: 14px;
    padding: 6px 8px;
    font-family: fontregular;
    }
`;

const VerticalFacets = ({ facets }) => {
  const { searchTerm } = useParams()
  const { pathname, search } = useLocation()
  const appURL = useSelector(getAppURL)

  return useMemo(() => {

    const urlPrefix = `${appURL}#${pathname}${search.length > 0 ? search + '&' : '?'}`
    const toURL = facet => `${urlPrefix}${queryString.stringify({ facet })}`
    const toCategoryURL = searchTerm ?
      categoryId => `${urlPrefix}${queryString.stringify({ categoryId })}` :
      categoryId => buildCategoryURL(appURL, categoryId)

    const existingFacets = toArray(queryString.parse(search).facet)
    const filteredFacets = facets?.map(
      facet => ({
        ...facet,
        entry: facet.entry.filter(entry => !existingFacets.includes(entry.value))
      })
    )?.filter(facet => facet.entry.length > 0)

    return (
      <div className="drawer">
        {
          filteredFacets?.map((facet, index) => {
            if (facet.value.startsWith('price_')) {
            } else if (facet.value === 'parentCatgroup_id_search') {
              return (
                <div key={index}>
                  <p className="menuTitle">Category</p>
                  <MenuList vertical className="menuList">
                    {
                      facet.entry.map((facetEntry, childIndex) => {
                        return (
                          <a href={toCategoryURL(facetEntry.value)} key={childIndex}>{facetEntry.label} <b>({facetEntry.count})</b></a>
                        )
                      })
                    }
                  </MenuList>
                </div>
              )
            } else {
              return (
                <div key={index}>
                  <p className="menuTitle">{facet.name}</p>
                  <MenuList vertical className="menuList">
                    {
                      facet.entry.map((facetEntry, childIndex) => {
                        return (
                          <a href={toURL(facetEntry.value)} key={childIndex}>{facetEntry.label} <b>({facetEntry.count})</b></a>
                        )
                      })
                    }
                  </MenuList>
                </div>
              )
            }
          })
        }
      </div>
    )
  }, [ pathname, search, facets, appURL, searchTerm ])
}

VerticalFacets.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    entry: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      count: PropTypes.string.isRequired,
    }))
  }))
}

export default withRouter(VerticalFacets)
import { combineReducers } from 'redux';
import appContext from '../Shared/AppContext/reducer'
import productDetailsReducer from '../Views/ProductDescription/reducers/index';
import header from '../Shared/Components/Header/reducers/index'
import productViewReducer from '../Views/ProductListing/reducers/index';
import searchResults from '../Views/SearchResults/reducers'
import orderFavoritesDropdown from '../Shared/Components/OrderFavoritesDropDown/reducers'
import { EspotReducer as espots } from '../Shared/Components/Espot'
import savedCarts from '../Views/SavedCarts/reducers'
import orderComments from '../Shared/Components/CSROrderComments/reducers'
import loyaltyRewards from '../Views/LoyaltyRewards/reducers/index';

export default combineReducers({
   // reducers goes here
   appContext,
   productDetailsReducer,
   productViewReducer,
   header,
   searchResults,
   orderFavoritesDropdown,
   espots,
   savedCarts,
   orderComments,
   loyaltyRewards,
})
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { getAppURL } from '../../AppContext/selectors'
import searchIcon from './../../../assets/images/searchicon.png'

const InputGroup = styled.div`
    margin-right: 35px;
    border: 1px solid #ccc;
    width: 100%;
    padding-left: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    box-sizing: border-box
`

const InputGroupAddon = styled.div`
    margin-right: -1px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    img {
        height: 16px;
        width: 16px;
        margin-top: 13px;
    }
`

const Input = styled.input`
    border: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    display: block;
    padding: .75rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-offset: -2px;
    overflow: visible;
    margin: 0;
    font-family: inherit;
    box-sizing: border-box;
`

const SearchBar = ({ className,translationKey,currentFileRequired }) => {
    const [input, setInput] = useState()
    const appURL = useSelector(getAppURL)
    
    const handleSubmit = e => {
        e.preventDefault()
        window.location.href = `${appURL}#/search/${encodeURIComponent(input)}`
    }
   
    return (
        <form className={className} onSubmit={handleSubmit}>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <img alt="Search icon" src={searchIcon} />
                </InputGroupAddon>
                <Input
                    type="search"
                    name="keywords"
                    placeholder={translationKey(currentFileRequired['SEARCH_CATALOG'])}
                    onChange={e => setInput(e.currentTarget.value)}
                />
            </InputGroup>
        </form>
    )
}

export default SearchBar
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getForUser, getStoreId } from '../../AppContext/selectors'
import Dropdown from '../GenericComponent/Dropdown'
import { GetOrderFavoritesAction } from './actions'
import { getOrderFavorites } from './selectors'

const OrderFavoritesDropDown = ({ value, onChange, disabled, defaultValue = null, defaultLabel = 'Add to Order Favorites', ...props }) => {
  const [ isLoaded, setLoaded ] = useState(false)
  const storeId = useSelector(getStoreId)
  const lists = useSelector(getOrderFavorites)
  const forUser = useSelector(getForUser)
  const dispatch = useDispatch()
  const handleFocusOrMouseOver = !isLoaded ? (() => {
    dispatch(GetOrderFavoritesAction({ storeId, forUser }))
    setLoaded(true)
  }) : undefined

  useEffect(()=>{
    handleFocusOrMouseOver();
  },[])

  return (
    <div {...props} >
      <Dropdown 
        value={value}
        options={[
          {
            value: defaultValue,
            label: defaultLabel,
          },
          ...(lists?.map(list => ({
            value: list.listId,
            label: list.description
          })) ?? [])
        ]}
        onChange={onChange}
        disabled={disabled || (Array.isArray(lists) && lists.length===0) || lists===undefined || lists === 'undefined'}
      />

    </div>
  )
}

export default OrderFavoritesDropDown
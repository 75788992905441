import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import EditIcon from '../../../assets/images/edit.svg'
import Button from '../GenericComponent/Button'
import {
  getOrderCommentsAction,
  addOrderCommentAction
} from './actions'
import { getComments } from './selectors'
import { getStoreId } from '../../AppContext/selectors'
import { dateTimeFormatter } from '../../utility'

const Styles = styled.div`
  overflow: hidden;
`

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  transition: transform .8s cubic-bezier(0, .52, 0, 1);
  ${props => props.show ? `transform: translate3d(0, 0, 0);`: `transform: translate3d(-${props.distance}px, 0, 0);`}
`

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-left: 0;
  background-color: white;
  ${props => `width: ${props.width}px;`}
  ${props => `height: ${props.height}px;`}
`

const StyledToggleButton = styled.button`
  outline: none;
  background-color: #d2d2d2;
  border: 1px solid black;
  border-top: 0;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  padding: 12px 16px;
  height: fit-content;
  margin-top: 200px;
  font-size: 12px;
  position: absolute;
  left: 300px;
`

const SideBarTitle = styled.div`
  font-family: fontregular;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 16px;
  line-height: 20px;
  border-bottom: 1px solid black;
`

const SideBarRow = styled.div`
  font-family: fontregular;
  font-size: 12px;
  padding: 12px 12px 12px 16px;
  line-height: 20px;
  border-bottom: 1px solid #d2d2d2;
`

const SideBarEditButton = styled.button`
  font-family: fontregular;
  font-size: 12px;
  padding: 12px 16px;
  line-height: 20px;
  background: white;
  border: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  img {
    width: 20px;
    margin-right: 8px;
  }
`

const StyledInputBox = styled.textarea`
  width: 100%;
  resize: vertical;
  outline: none;
  box-sizing: border-box;
  max-height: 182px;
  min-height: 48px;
`
const StyledInputButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

const StyledInputButton = styled(Button)`
  font-family: fontregular;
  font-size: 12px;
  outline: none;
  width: 40%;
  padding: 4px 8px;
  border-radius: 4px;
`

const TextContainer = styled.div`
  overflow-wrap: break-word;
`

const SideBarCommentsContainer = styled.div`
  overflow-y: scroll;
  div:last-child {
    border: none;
  }
`

const OrderCommentsSideBar = ({ width, height, orderId }) => {
  const dispatch = useDispatch()
  const storeId = useSelector(getStoreId)
  const comments = useSelector(getComments)
  const [show, setShow] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleSaveNewComment = () => {
    dispatch(addOrderCommentAction({ storeId, orderId, commentField: inputValue }))
    handleCloseNewCommentInputArea();
  }

  const handleOpenNewCommentInputArea = () => {
    setOpen(true);
  }

  const handleCloseNewCommentInputArea = () => {
    setOpen(false);
    setInputValue('');
  }

  useEffect(() => {
    dispatch(getOrderCommentsAction({ storeId, orderId }))
  }, [])
  
  return (
    <Styles>
      <StyledContainer
        distance={width}
        show={show}
      >
        <StyledSideBar
          height={height}
          width={width}
        >
          <SideBarTitle>
            Order Comments
          </SideBarTitle>
          {!open ? 
            <SideBarEditButton onClick={handleOpenNewCommentInputArea}>
              <img alt="Edit" src={EditIcon} />
              <span>click here to add new comment</span>
            </SideBarEditButton> :
            <SideBarRow>
              <StyledInputBox 
                placeholder={"Enter new comment here"}
                value={inputValue}
                onChange={handleChange}
                autoFocus
                disabled={!show}
                maxLength={3000}
              />
              <StyledInputButtonContainer>
                <StyledInputButton
                  onClick={handleSaveNewComment}
                >
                  Save
                </StyledInputButton>
                <StyledInputButton
                  onClick={handleCloseNewCommentInputArea}
                >
                  Cancel
                </StyledInputButton>
              </StyledInputButtonContainer>
            </SideBarRow>
          }
          <SideBarCommentsContainer>
            { comments && comments.length > 0 && comments.map((commentData) => (
              <SideBarRow key={commentData.commentDetails.orcommentId}>
                <TextContainer>[
                  {dateTimeFormatter(commentData.commentDetails.lastupdate)} - by {commentData.serviceRepDetails.address?.firstName} {commentData.serviceRepDetails.address?.midName} {commentData.serviceRepDetails.address?.lastName} {commentData.serviceRepDetails.logonId}]
                </TextContainer>
                <TextContainer>{commentData.commentDetails.comment}</TextContainer>
              </SideBarRow>
            ))}
          </SideBarCommentsContainer>
        </StyledSideBar>
        <StyledToggleButton
          onClick={() => toggleMenu()}
        >
          Order Comments
        </StyledToggleButton>
      </StyledContainer>
    </Styles>
  )
}

export default OrderCommentsSideBar
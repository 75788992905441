import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCatalogId, getDmContextPath, getDmImagePath, getDmSubstitutionVariables, getLangId, getStoreId } from "../../AppContext/selectors"
import { fetchEspot, getEspot } from "./EspotState"

const useEspot = ({ name, categoryId, productId, searchTerm, reqCommand, variables }) => {
  const storeId = useSelector(getStoreId)
  const catalogId = useSelector(getCatalogId)
  const langId = useSelector(getLangId)
  const dmContextPath = useSelector(getDmContextPath)
  const dmImagePath = useSelector(getDmImagePath)
  const dmSubstitutionVariables = useSelector(getDmSubstitutionVariables)
  /**
   * @type {import("./EspotState").EspotQuery}
   */
  const espotQuery = useMemo(() => ({
    name,
    storeId,
    catalogId,
    langId,
    categoryId,
    productId,
    searchTerm,
    reqCommand,
    dmContextPath,
    dmImagePath,
    variables: {
      ...dmSubstitutionVariables,
      ...variables,
    },
  }), [
    name,
    storeId,
    catalogId,
    langId,
    categoryId,
    productId,
    searchTerm,
    reqCommand,
    dmContextPath,
    dmImagePath,
    dmSubstitutionVariables,
    variables,
  ])
  const espot = useSelector(getEspot(espotQuery))
  const dispatch = useDispatch()
  useEffect(() => { dispatch(fetchEspot(espotQuery)) }, [ espotQuery, dispatch ])
  return espot
}

export default useEspot
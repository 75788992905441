import queryString from 'query-string';
import {
    actionCreator,
    checkHttpStatus,
    SavedCartsActionTypes,
    jsonApiHeader,
} from '../constants/ApiConstants';
import {
  isPendingOrdersLoading,
  isCurrentPendingOrderLoading,
  isLockedCustomerCartsLoading,
} from '../selectors';

export const GetPendingOrdersAction = ({ storeId }) => {
    return async (dispatch, getState) => {
      const state = getState()
      if (!isPendingOrdersLoading(state)) {
        dispatch(actionCreator(SavedCartsActionTypes.get_pendingOrders.REQUEST));
        try {
          const response = await fetch(`/wcs/resources/store/${storeId}/order/byStatus/P`, {
              method: 'GET',
              headers: jsonApiHeader(),
          });
          const payload = await checkHttpStatus(response);
          dispatch(actionCreator(SavedCartsActionTypes.get_pendingOrders.SUCCESS, payload));
        } catch(e) {
          dispatch(actionCreator(SavedCartsActionTypes.get_pendingOrders.FAILURE));
          console.log('error get_pendingOrders ..', e);
        }
      }
    };
};

export const GetCurrentPendingOrderAction = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isCurrentPendingOrderLoading(state)) {
      dispatch(actionCreator(SavedCartsActionTypes.get_currentPendingOrder.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/cart/@self?profileName=IBM_Summary`, {
            method: 'GET',
            headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(SavedCartsActionTypes.get_currentPendingOrder.SUCCESS, payload));
      } catch(e) {
        dispatch(actionCreator(SavedCartsActionTypes.get_currentPendingOrder.FAILURE));
        console.log('error get_currentPendingOrder ..', e);
      }
    }
  };
};

export const SetCurrentPendingOrderAction = ({ storeId, orderId }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.set_currentPendingOrder.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const response = await fetch(`/wcs/resources/store/${storeId}/cart/${orderId}/set_pending_order`, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
          body: JSON.stringify({}),
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.set_currentPendingOrder.SUCCESS, payload));
      dispatch(GetCurrentPendingOrderAction({ storeId }))
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.set_currentPendingOrder.FAILURE));
      console.log('error set_currentPendingOrder ..', e);
    }
  };
};

export const LockOrderAction = ({ storeId, orderId, takeOverLock=false }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.lock_order.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const response = await fetch(`/wcs/resources/store/${storeId}/cart/${orderId}/lockOnBehalf`, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
          body: JSON.stringify({
            takeOverLock: takeOverLock ? "Y" : "N"
          })
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.lock_order.SUCCESS, payload));
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.lock_order.FAILURE));
      console.log('error lock_order ..', e);
    }
  };
};

export const UnLockOrderAction = ({ storeId, orderId }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.unlock_order.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const response = await fetch(`/wcs/resources/store/${storeId}/cart/${orderId}/unlockOnBehalf`, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
          body: JSON.stringify({}),
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.unlock_order.SUCCESS, payload));
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.unlock_order.FAILURE));
      console.log('error unlock_order ..', e);
    }
  };
};

export const RenameOrderAction = ({ storeId, orderId, orderName, buyerId }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.rename_order.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const query = queryString.stringify({
        forUserId: buyerId
      })
      const BOBUrl = `/wcs/resources/store/${storeId}/cart/copy_order`
      const nonBOBUrl = `/wcs/resources/store/${storeId}/cart/copy_order?${query}`
      const response = await fetch(`${buyerId ? nonBOBUrl : BOBUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
          body: JSON.stringify({
            toOrderId: orderId,
            description: orderName,
          })
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.rename_order.SUCCESS, payload));
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.rename_order.FAILURE));
      console.log('error rename_order ..', e);
    }
  };
};

export const DeleteOrderAction = ({ storeId, orderId, buyerId }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.delete_order.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const query = queryString.stringify({
        forUserId: buyerId
      })
      const response = await fetch(`/wcs/resources/store/${storeId}/cart/${orderId}/cancel_order?${query}`, {
          method: 'DELETE',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.delete_order.SUCCESS, payload));
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.delete_order.FAILURE));
      console.log('error delete_order ..', e);
    }
  };
};

export const CreateOrderAction = ({ storeId, orderName }) => {
  return async (dispatch) => {
    dispatch(actionCreator(SavedCartsActionTypes.create_order.REQUEST));
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const query = queryString.stringify({
        description: orderName
      })
      const response = await fetch(`/wcs/resources/store/${storeId}/cart/create_order?${query}`, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            'WCAuthToken':token?token:''
          },
          body: JSON.stringify({}),
      });
      const payload = await checkHttpStatus(response);
      dispatch(actionCreator(SavedCartsActionTypes.create_order.SUCCESS, payload));
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    } catch(e) {
      dispatch(actionCreator(SavedCartsActionTypes.create_order.FAILURE));
      console.log('error create_order ..', e);
    }
  };
};

export const GetLockedCustomerCartsAction = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!isLockedCustomerCartsLoading(state)) {
      dispatch(actionCreator(SavedCartsActionTypes.get_lockedCustomerCarts.REQUEST));
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/3mcart/lockedBy/@self`, {
            method: 'GET',
            headers: jsonApiHeader(),
        });
        const payload = await checkHttpStatus(response);
        dispatch(actionCreator(SavedCartsActionTypes.get_lockedCustomerCarts.SUCCESS, payload));
      } catch(e) {
        dispatch(actionCreator(SavedCartsActionTypes.get_lockedCustomerCarts.FAILURE));
        console.log('error get_lockedCustomerCarts ..', e);
      }
    }
  };
};
export const translation = {
  SEARCH_CATALOG: "Suche im Katalog",
  MY_SETUP: "Meine Einstellungen",
  CONTACT_US: "Kontaktieren Sie uns",
  HELP: "Hilfe",
  LOGOUT: "Ausloggen",
  ORDER_FAVORITES: "Favoritenbestellung",
  QUICK_ORDER: "Direktbestellung",
  ORDER_BANDS: "Bänderbestellung",
  CATALOG: "Katalog",
  SAVED_CARTS: "Gespeicherte Warenkörbe",
  MY_CART: "Mein Warenkorb",
  MY_ORDERS: "Meine Bestellungen",
  MY_STATEMENT: "Mein Kontostand",
  WELCOME: "Willkommen",
  LOGIN_ID: "Benutzername",
  ACCOUNT_NUMBER: "Kundennummer",
  ADDRESS: "Adresse",
  PHONE: "Telefon",
  FAX: "Fax",
  PRICE_DISCOUNT: "Preisnachlass",
  SHOW_CUSTOMER_DETAILS: "Kundendetails anzeigen",
  HIDE_CUSTOMER_DETAILS: "Kundendetails ausblenden",
  MY_REWARDS: "Meine Belohnungen", //==> needs to be verified
  PRODUCTS: "Produkte",
  YOUR_PRODUCTS: "Ihre Produkte", //==> needs to be verified
  MANAGE_ACCOUNTS: "Konten verwalten", //==> needs to be verified
  //CATALOG PAGE
  PRODUCTS: "Produkte",
  HOME: "Startseite",
  //Saved Carts
  CART_NUMBER: "Warenkorb-Nummer",
  CART_NAME: "Warenkorb-Name",
  LAST_UPDATED: "Zuletzt aktualisiert",
  TOTAL: "Insgesamt",
  ACTIONS: "Aktionen",
  SAVED_CARTS: "Gespeicherte Warenkörbe",
  CREATE_CART: "Warenkorb erstellen",
  VIEW_CART_DETAILS: "Einzelheiten zum Warenkorb anzeigen",
  SET_AS_CURRENT_CART: "Als aktuellen Warenkorb festlegen",
  RENAME_CART: "Warenkorb umbenennen",
  DELETE_CART: "Warenkorb löschen",
  ACTIVE_CART_MESSAGE: "Dies ist Ihr aktiver Einkaufswagen", // => needs to be verified
  LOCK_CART_MESSAGE: "Dieser Warenkorb ist durch den Benutzernamen gesperrt",
  RENAME: "Umbenennen",
  DELETE: "Löschen",
  CANCEL: "Abbrechen",
  PRIVATE_CART_MESSAGE:
    "Dieser Warenkorb ist nicht öffentlich. Bitte entsperren Sie ihn, damit andere diesen Warenkorb ansehen können.",
  UNLOCK_CART: "Warenkorb entsperren",
  TAKE_OVER_LOCK: "Sperre übernehmen", // => needs to be verified
  LOCK_CART: "Warenkorb sperren",
  CART_NAME: "Warenkorbname", // => needs to be verified
  LOCK_CART_SELF_MESSAGE:
    "Sie haben diesen Warenkorb gesperrt. Niemand sonst kann diesen Warenkorb bearbeiten.",
  CREATE: "Erstellen", //=> needs to be verified
  CUSTOMER_SUPPORT: "Kundenbetreuung", // => needs to be verified
  YOUR_ACCOUNT: "Ihr Konto", //=> needs to be verified
  ITEMS: "Artikel", // => needs to be verified
  //Product Details Page
  OVERVIEW: "Übersicht",
  SPECIFICATIONS: "Spezifikationen",
  DETAILS: "Details",
  VIEW_LEGAL_DISCLAIMER: "Rechtliche Hinweise ansehen",
  PRODUCTS_AVAILABLE: "Verfügbare Produkte",
  THREEM_SITE_REDIRECT_MSG:
    "Klicken Sie hier, um dieses Produkt auf 3M.com zu sehen", // => needs to be verified
  LEGAL_DISCLAIMER: "Rechtliche Hinweise",
  ENDORSEMENT_MSG:
    "*3M-Version dieses Rezepts. Es wird keine Billigung durch den Arzt impliziert.", // => needs to be verified
  SEARCH_RESULTS_FOR: "Ergebnis durchsuchen nach",
  CATALOG_PRODUCT: "Katalog",
  SEARCH_RESULTS: "Ergebnis durchsuchen ...",
  //My Rewards Page (Not needed for germany right now)
  SAVINGS_THIS_YEAR: "Einsparungen in diesem Jahr",
  CURRENT_3M: "Aktuelle 3M",
  TM: "TM",
  CLARITY: "Klarheit",
  DISCOUNT_STATUS: "Aligner verdienter Rabattstatus",
  CLARITY_ALIGNERS_CASES: "Clarity Aligners Hüllen",
  OTHER_PRODUCT_PURCHASES: "Sonstige Produktkäufe",
  SPEND: "Ausgeben",
  IN_PRODUCT_PURCHASES: "beim Produktkauf",
  ADD: "Hinzufügen",
  OR: "ODER",
  CLARITY_ALIGNERS_ORDERS: "Clarity Aligners Bestellungen",
  DATE: "Datum",
  INVOICE_NUMBER: "Rechnungsnummer",
  ORDER_NUMBER: "Bestellnummer",
  QUALIFYING_CASES: "Qualifizierende Fälle",
  QUALIFYING_PURCHASES: "Qualifizierende Käufe",
  OTHER_ORTHODONTIC_PURCHASES: "Andere kieferorthopädische Einkäufe",
  FAQS: "Häufig gestellte Fragen (FAQs)",
  //MAC BOB
  BUYING_FOR: "Kaufen für",
  BUY_ON_BEHALF: "Kauf im Auftrag von",
  RETURN_TO_HEADQUARTERS: "Zurück an die Zentrale ",
};

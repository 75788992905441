import queryString from 'query-string'
import {
    actionCreator,
    checkHttpStatus,
    SearchResultsActionTypes,
    jsonApiHeader,
} from '../constants/ApiConstants';

export const GetSearchResultsAction = ({ storeId, catalogId, searchTerm, facets, categoryId }) => {
    return (dispatch) => {
        const query = queryString.stringify({
            catalogId,
            searchTerm,
            facet: facets,
            categoryId,
            profileName: '3M_findProductsBySearchTerm_Summary',
            pageNumber: 1,
            pageSize: 1000,
            orderBy: 20,
        })
        dispatch(actionCreator(SearchResultsActionTypes.get_searchResults.REQUEST));
        fetch(`/search/resources/store/${storeId}/productview/bySearchTerm/*?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(SearchResultsActionTypes.get_searchResults.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(SearchResultsActionTypes.get_searchResults.FAILURE));
                console.log('error get_prouctView ..', error);
            });
    };
};

export const GetCategoryNameAction = ({ storeId, catalogId, langId, categoryId }) => async dispatch => {

    dispatch(actionCreator(SearchResultsActionTypes.get_searchCategoryName.REQUEST))

    try {
        const query = queryString.stringify({ langId, catalogId })
        const response = await fetch(`/search/resources/store/${storeId}/categoryview/byId/${categoryId}?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })

        const jsonResponse = await checkHttpStatus(response)
        dispatch(actionCreator(SearchResultsActionTypes.get_searchCategoryName.SUCCESS, jsonResponse))

    } catch (error) {
        dispatch(actionCreator(SearchResultsActionTypes.get_searchCategoryName.FAILURE, error))
        console.log('error get_searchCategoryName ..', error);
    }
}
const getLoyaltyRewards = state => state.loyaltyRewards;


export const getLoyaltyPoints = state => getLoyaltyRewards(state).loyaltyPoints;
export const isLoyaltyPointsLoading = state => getLoyaltyRewards(state).loyaltyPointsloading;
export const isLoyaltyPointsLoaded = state => getLoyaltyRewards(state).loyaltyPointsloaded;

export const getLoyaltyActivities = state => getLoyaltyRewards(state).loyaltyActivities;
export const isLoyaltyActivitiesLoading = state => getLoyaltyRewards(state).loyaltyActivitiesloading;
export const isLoyaltyActivitiesLoaded = state => getLoyaltyRewards(state).loyaltyActivitiesloaded;

export const getLoyaltyTiers = state => getLoyaltyRewards(state).tiers;
export const isLoyaltyTiersLoading = state => getLoyaltyRewards(state).loyaltyTiersloading;
export const isLoyaltyTiersLoaded = state => getLoyaltyRewards(state).loyaltyTiersloaded;

export const getOtherLoyaltyActivities = state => getLoyaltyRewards(state).otherLoyaltyActivities;
export const isOtherLoyaltyActivitiesLoading = state => getLoyaltyRewards(state).otherLoyaltyActivitiesloading;
export const isOtherLoyaltyActivitiesLoaded = state => getLoyaltyRewards(state).otherLoyaltyActivitiesloaded;

export const getFaqProperties = state => getLoyaltyRewards(state).faqProperties;
export const isFaqPropertiesLoading = state => getLoyaltyRewards(state).faqPropertiesloading;
export const isFaqPropertiesLoaded = state => getLoyaltyRewards(state).faqPropertiesloaded;

export const getRewardProperties = state => getLoyaltyRewards(state).rewardProperties;
export const isRewardPropertiesLoading = state => getLoyaltyRewards(state).rewardPropertiesloading;
export const isRewardPropertiesLoaded = state => getLoyaltyRewards(state).rewardPropertiesloaded;

import { AppContextActionTypes } from "../constants"

const initialState = {
  storeId: '',
  catalogId: '',
  langId: '',
  baseURL: '',
  appURL: '',
  dmContextPath: '',
  dmImagePath: '',
  dmSubstitionVariables: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AppContextActionTypes.setAppContext:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
import React, { Component,useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HashRouter as Router, Switch, Route } from "react-router-dom"
import { createGlobalStyle } from 'styled-components/macro';

import EStoreRoutes from './routes/e-store'
import SalesPortalRoutes from './routes/sales-portal'

import Container from './Shared/Components/GenericComponent/Container';
import { getSiteId } from './Shared/AppContext/selectors';
import SideBarContentWrapper from './Shared/Components/GenericComponent/SideBarContentWrapper';
import ContentWrapper from './Shared/Components/GenericComponent/ContentWrapper';
import {UserProvider,UserContext} from './UserContext'


const GlobalStyle = createGlobalStyle`
  a:hover {
    cursor: pointer;
  }
  table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  font-family: fontregular;
  margin-bottom:0px !important;
  th,
  td {
    border-collapse: collapse;
    border-top:0 !important;
    font-family: fontregular;
  }
  th,
  td,
  tr {
    padding: 5px;
    font-family: fontregular;
    
  }
  th {
    background-color:#000;
    color:#fff;
    font-family: fontregular;
  }
  tr:nth-child(even) {
  background-color: #d2d2d2;
  }
  thead th {
    vertical-align: bottom;
    border-bottom: 0px;
    position: sticky;
    top: -1px;
  }
  }
`;

const routesMap = {
  'e-store': EStoreRoutes,
  'sales-portal': SalesPortalRoutes
}

class App extends Component {
  render() {
    const { siteId } = this.props
    const routes = routesMap[siteId];
    
    
    return (
      <UserProvider>
      <Router >
        <GlobalStyle />
        <Container>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                { route.header && <route.header activeMenu={route.activeMenu}/> }
              </Route>
            ))}
          </Switch>
        </Container>
        <Container>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                {
                  // remount on path and query change, but not hash (within hash, i.e. #/path?search#hash)
                  routeProps => route.component && <route.component
                    key={routeProps.location.pathname + routeProps.location.search} />
                }
              </Route>
            ))}
          </Switch>
        </Container>
      </Router>
      </UserProvider>
    )
  }
}

App.propTypes = {
  siteId: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  siteId: getSiteId(state)
})

export default connect(mapStateToProps)(App);

import queryString from 'query-string'
/**
 * @param {string} appURL 
 * @param {string} categoryId 
 */
export const buildCategoryURL = (appURL, categoryId) => 
  `${appURL}#/category/${categoryId}`

/**
 * @param {string} appURL 
 * @param {string} productId 
 * @param {object} options
 */
export const buildProductURL = (appURL, productId, { categoryId } = {}) => 
  `${appURL}#/product/${productId}?${queryString.stringify({categoryId})}`

export const buildHomeURL = (siteId, baseURL, langId, storeId, catalogId) =>
  siteId === 'sales-portal' ?
    `${baseURL}RSMPortalDisplayCmd?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}` :
    `${baseURL}StoreCatalogDisplay?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`

export const buildSavedCartsURL = (appURL) => 
  `${appURL}#/saved-carts`
  
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import styled from "styled-components/macro";
import {
  getBaseURL,
  getCatalogId,
  getLangId,
  getSiteId,
  getStoreId,
} from "../../AppContext/selectors";
import { buildHomeURL } from "../../linkBuilders";
import Arrowright from "./../../../assets/images/arrowright.png";
import { UserContext } from '../../../UserContext';
import { getCurrentFile } from '../../../Shared/utility';

const Container = styled.div`
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  max-width: 1280px;
  text-size-adjust: 100%;
  width: 1280px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const BreadCrumbContainer = styled.div`
  background-color: transparent;
  padding-left: 25px;
  padding-right: 0px;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
`;

const BreadCrumbItem = styled.a`
  color: #000;
  font-size: 12px;
  line-height: 16px;
  font-family: fontregular;
  display: flex;
`;

const CrumbArrow = styled.img`
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 4px;
`;

const BreadCrumb = ({
  breadCrumbs,
  currentPageLabel,
  showHome,
}) => {
  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);
  const langId = useSelector(getLangId);
  const baseURL = useSelector(getBaseURL);
  const siteId = useSelector(getSiteId);
  const { t:translationKey } = useTranslation();
  const {selectedLanguage} = useContext(UserContext);
  const currentFileRequired = getCurrentFile(selectedLanguage)
  return (
    <Container>
      <BreadCrumbContainer className="breadCrumb">
        {showHome && (
          <Fragment>
            <BreadCrumbItem
              tag="a"
              href={buildHomeURL(siteId, baseURL, langId, storeId, catalogId)}
              className="activeBreadCrumb"
            >
              {" "}
              {currentFileRequired
                ? translationKey(currentFileRequired["HOME"])
                : "Home"}
            </BreadCrumbItem>
            <CrumbArrow src={Arrowright} />
          </Fragment>
        )}
        {breadCrumbs.map((breadCrumb) => (
          <Fragment key={breadCrumb}>
            <BreadCrumbItem
              tag="a"
              href={breadCrumb.href}
              className="activeBreadCrumb"
            >
              {breadCrumb.label}
            </BreadCrumbItem>
            <CrumbArrow src={Arrowright} />
          </Fragment>
        ))}
        <BreadCrumbItem tag="span">{currentPageLabel}</BreadCrumbItem>
      </BreadCrumbContainer>
    </Container>
  );
};

BreadCrumb.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
  currentPageLabel: PropTypes.string.isRequired,
  showHome: PropTypes.bool,
};

BreadCrumb.defaultProps = {
  breadCrumbs: [],
  currentPageLabel: "",
  showHome: true,
};

export default BreadCrumb;

import React from 'react';
import styled from 'styled-components/macro';

const Label = styled.label`
  font-size: 16px;
  line-height:20px;
  color: #000;
  font-family: fontbold;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export default Label;
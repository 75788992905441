import {
    actionCreator,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../Shared/utility';

export {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader
};

export const SavedCartsActionTypes = {
    get_pendingOrders: createRequestActionTypes('GET_PENDING_ORDERS'),
    get_currentPendingOrder: createRequestActionTypes('GET_CURRENT_PENDING_ORDER'),
    set_currentPendingOrder: createRequestActionTypes('SET_CURRENT_PENDING_ORDER'),
    lock_order: createRequestActionTypes('LOCK_ORDER'),
    unlock_order: createRequestActionTypes('UNLOCK_ORDER'),
    rename_order: createRequestActionTypes('RENAME_ORDER'),
    delete_order: createRequestActionTypes('DELETE_ORDER'),
    create_order: createRequestActionTypes('CREATE_ORDER'),
    get_lockedCustomerCarts: createRequestActionTypes('GET_LOCKED_CUSTOMER_CARTS'),
};
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Cross from '../../../assets/images/cross-black.svg';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

const StyledDialogContent = styled(DialogContent)`
  border: 1px solid;
  font-family: fontregular;
  color: rgba(75,75,75,1);
  position: relative;
`

const StyledCloseButton = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const AccountLockedAlertDialog = () => {
  const [showDialog, setShowDialog] = useState(true)
  const close = () => {
    setShowDialog(false)
  }

  return (
    <DialogOverlay isOpen={showDialog} onDismiss={close}>
      <StyledDialogContent aria-label="account locked alert">
        <StyledCloseButton alt="cancel" src={Cross} onClick={close} />
        <StyledTitle>
          User Account Locked
        </StyledTitle>
        <p>
          The account you selected is locked. Please contact the site administrator at 1-800-123-4567 for further assistance.
        </p>
      </StyledDialogContent>
    </DialogOverlay>
  )
}

export default AccountLockedAlertDialog
import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import EStoreHeader from './variations/EStoreHeader'
import SalesPortalHeader from './variations/SalesPortalHeader'
import { getSiteId } from '../../AppContext/selectors';


class Header extends Component {

    render() {
       
        const { siteId, ...props } = this.props;
        console.log("prp is header",props,siteId)

        return siteId === 'sales-portal' ? <SalesPortalHeader {...props} /> : <EStoreHeader {...props} />
    }
}

Header.propTypes = {
    siteId: PropTypes.oneOf(['e-store', 'sales-portal']),
    ...SalesPortalHeader.propTypes,
    ...EStoreHeader.propTypes,
};

const mapStateToProps = state => ({
    siteId: getSiteId(state)
})

export default (connect(mapStateToProps)(Header));
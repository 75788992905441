import queryString from 'query-string'
import {
    actionCreator,
    checkHttpStatus,
    ProductListingActionTypes,
    jsonApiHeader,
} from '../constants/ApiConstants';

export const GetProductViewAction = ({ storeId, catalogId, categoryId, facets }) => {
    return (dispatch) => {
        dispatch(actionCreator(ProductListingActionTypes.get_prouctView.REQUEST, { storeId, catalogId, categoryId, facets }));
        const query = queryString.stringify({
            catalogId,
            profileName: 'IBM_findProductsByCategory_Summary',
            facet: facets,
        })
        fetch(`/search/resources/store/${storeId}/productview/byCategory/${categoryId}?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(ProductListingActionTypes.get_prouctView.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(ProductListingActionTypes.get_prouctView.FAILURE));
                console.log('error get_prouctView ..', error);
            });
    };
};

export const GetProductCategoryAction = ({ storeId, catalogId, categoryId }) => {
    return (dispatch) => {
        dispatch(actionCreator(ProductListingActionTypes.get_productCategory.REQUEST, { storeId, catalogId, categoryId }));
        const query = queryString.stringify({
            catalogId,
        })
        fetch(`/search/resources/store/${storeId}/categoryview/byId/${categoryId}?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(ProductListingActionTypes.get_productCategory.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(ProductListingActionTypes.get_productCategory.FAILURE));
                console.log('error get_productCategory ..', error);
            });
    };
};

export const GetCategoryBreadCrumbAction = ({ storeId, categoryId }) => {
    return (dispatch) => {
        dispatch(actionCreator(ProductListingActionTypes.get_categoryBreadCrumbTrail.REQUEST));
        fetch(`/search/resources/store/${storeId}/productview/byCategory/${categoryId}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(ProductListingActionTypes.get_categoryBreadCrumbTrail.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(ProductListingActionTypes.get_categoryBreadCrumbTrail.FAILURE));
                console.log('error get_productCategory ..', error);
            });
    };
};
export const translation = {
  SEARCH_CATALOG: "Rechercher dans le catalogue",
  MY_SETUP: "Mes paramètres",
  CONTACT_US: "Nous contacter",
  HELP: "Aide",
  LOGOUT: "Déconnexion",
  ORDER_FAVORITES: "Commande des favoris",
  QUICK_ORDER: "Commande rapide",
  ORDER_BANDS: "Commande de bagues",
  CATALOG: "Catalogue",
  SAVED_CARTS: "Paniers sauvegardés",
  MY_CART: "Mon panier",
  MY_ORDERS: "Mes commandes",
  MY_STATEMENT: "Mes relevés",
  WELCOME: "Bienvenue",
  LOGIN_ID: "Identifiant de connexion",
  ACCOUNT_NUMBER: "Numéro de compte",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  FAX: "Fax",
  PRICE_DISCOUNT: "Remise de prix",
  SHOW_CUSTOMER_DETAILS: "Afficher les détails du client",
  HIDE_CUSTOMER_DETAILS: "Masquer les détails du client",
  MY_REWARDS: "Mes récompenses",
  PRODUCTS: "Produits",
  YOUR_PRODUCTS: "Mes produits", // => needs to be verified
  MANAGE_ACCOUNTS: "Gérer les comptes", // => needs to be verified
  //CATALOG PAGE
  PRODUCTS: "Produits",
  HOME: "Accueil",
  //Saved Carts
  CART_NUMBER: "Référence du panier",
  CART_NAME: "Nom du panier",
  LAST_UPDATED: "Dernière mise à jour",
  TOTAL: "Total",
  ACTIONS: "Actions",
  SAVED_CARTS: "Paniers sauvegardés",
  CREATE_CART: "Créer un panier",
  VIEW_CART_DETAILS: "Afficher les détails du panier",
  SET_AS_CURRENT_CART: "Définir comme panier actuel",
  RENAME_CART: "Renommer le panier",
  DELETE_CART: "Supprimer le panier",
  ACTIVE_CART_MESSAGE: "Ceci est votre panier actif.",
  LOCK_CART_MESSAGE: "Ce panier est verrouillé par l’identifiant de connexion",
  RENAME: "Renommer",
  DELETE: "Supprimer",
  CANCEL: "Annuler",
  PRIVATE_CART_MESSAGE:
    "Ce panier est privé. Déverrouillez-le pour permettre à d’autres personnes de voir ce panier.", //BOB
  UNLOCK_CART: "Déverrouiller le panier", //BOB
  TAKE_OVER_LOCK: "Verrou de re", //=> needs to be verified
  LOCK_CART: "Verrouiller le panier",
  CART_NAME: "Nom du panier",
  LOCK_CART_SELF_MESSAGE:
    "Vous avez verrouillé ce panier. Personne d’autre ne peut le modifier.",
  CREATE: "Créer", //=> needs to be verified,
  CUSTOMER_SUPPORT: "Soutien à la clientèle", //=> needs to be verified
  YOUR_ACCOUNT: "Mon compte", //=> needs to be verified
  ITEMS: "Articles", //=> needs to be verified
  //Product Details Page
  OVERVIEW: "Présentation",
  SPECIFICATIONS: "Caractéristiques",
  DETAILS: "Détails",
  VIEW_LEGAL_DISCLAIMER: "Afficher la clause de non-responsabilité",
  PRODUCTS_AVAILABLE: "Produits disponibles",
  THREEM_SITE_REDIRECT_MSG: "Cliquez ici pour voir ce produit sur 3M.com", //=> needs to be verified
  LEGAL_DISCLAIMER: "Clause de non-responsabilité",
  ENDORSEMENT_MSG:
    "*Version 3M de cette prescription. Aucune approbation par le médecin n’est implicite.", //=> needs to be verified
  SEARCH_RESULTS_FOR: "Résultat de la recherche pour",
  CATALOG_PRODUCT: "Catalogue",
  SEARCH_RESULTS: "Résultat de la recherche...",
  //My Rewards Page
  SAVINGS_THIS_YEAR: "d’économie cette année",
  CURRENT_3M: "État actuel des remises cumulées sur les aligneurs",
  TM: "TM",
  CLARITY: "3M Clarity",
  DISCOUNT_STATUS: "",
  CLARITY_ALIGNERS_CASES: "Boîtiers d’aligneurs Clarity",
  OTHER_PRODUCT_PURCHASES: "Autres achats de produits",
  SPEND: "Dépensez",
  IN_PRODUCT_PURCHASES: "dans l’achat de produits",
  ADD: "Ajouter", //=> Needs to be verified
  OR: "OU",
  CLARITY_ALIGNERS_ORDERS: "Commandes d’aligneurs Clarity",
  DATE: "Date",
  INVOICE_NUMBER: "Numéro de facture",
  ORDER_NUMBER: "Numéro de commande",
  QUALIFYING_CASES: "Boîtes admissibles",
  QUALIFYING_PURCHASES: "Achats admissibles", //=> needs to be verified
  OTHER_ORTHODONTIC_PURCHASES: "Autres achats orthodontiques",
  FAQS: "FAQ",
  //MAC BOB
  BUYING_FOR: "Achat pour",
  BUY_ON_BEHALF: "Acheter pour le compte de",
  RETURN_TO_HEADQUARTERS: "Retour au siège",
};

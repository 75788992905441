import { createSelector } from 'reselect'
import { toArray } from '../../../Shared/utility'
const getProductDescriptionReducer = state => state.productDetailsReducer


export const isLoading = state => {
  const { loadingDetails, loadingOptions } = getProductDescriptionReducer(state)
  return loadingDetails || loadingOptions
}
export const getErrorMessages = state => getProductDescriptionReducer(state).errorMessages
export const getProduct = state => getProductDescriptionReducer(state).productDetails?.catalogEntryView?.[0]
export const getBreadCrumbTrail = state => getProductDescriptionReducer(state).productDetails?.breadCrumbTrailEntryView
export const getFacets = state => getProductDescriptionReducer(state).facets
export const getProductOptions = state => getProductDescriptionReducer(state).productOptions
export const getAttributeMap = createSelector(
  getProduct,
  product => product?.attributes?.reduce((accum, attr) => ({ ...accum, [attr.identifier]: attr }), {})
)
export const isAddingToOrderFavorites = state => getProductDescriptionReducer(state).addingToOrderFavorites
export const getTotalProductOptions = state => getProductDescriptionReducer(state).totalProductOptions
export const hasMore = createSelector(
  [
    getTotalProductOptions,
    getProductOptions,
    isLoading,
  ],
  (totalProductOptions, productOptions) => {
    const total = parseInt(totalProductOptions ?? '0')
    const totalLoaded = productOptions?.length ?? 0

    return (totalLoaded < total) 
  }
)
export const getHeaders = createSelector(
  [getProduct],
  product => {
    const headerMap = toArray(product?.x_comparableAttributes).map(attrString => attrString.split('/_/'))
      .reduce((accum, [identifier, name]) => ({
        ...accum,
        [identifier]: { identifier, name }
      }), {})
    const headers = Object.values(headerMap ?? {})
    return headers
  }
)
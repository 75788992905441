import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button'
import ArrowUp from '../../../assets/images/arrow-up.svg'
import ArrowDown from '../../../assets/images/arrow-down.svg'
import {
  getStoreId,
  getLangId,
  getBaseURL,
  getCatalogId
} from '../../AppContext/selectors'
import { buildHomeURL } from '../../linkBuilders'

const StyledMenuButton = styled(MenuButton)`
  font-family: fontregular;
  color: #4b4b4b;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border: none;
  background: transparent;
  outline: none;
  position: relative;
  cursor: pointer;
`

const StyledMenuList = styled(MenuList)`
  background-color: rgba(255,255,255,1);
  margin-left:-62px;
  border: 1px solid;
  border-sizing: border-box;
  &:focus {
    outline: none;
  }
`

const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: black;
  text-decoration: none;
  display: block;
  padding: 12px 16px;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
`

const StyledCalloutNotch = styled.i`
  border: solid #5a5a5a;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 15px;
  top: -4px;
  background-color: rgba(255,255,255,1);
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`

const StyledLink= styled.a`
  width: 110px !important;
`

const BackToUSIPDropdown = () => {
  const storeId = useSelector(getStoreId)
  const langId = useSelector(getLangId)
  const baseURL = useSelector(getBaseURL)
  const catalogId = useSelector(getCatalogId)

  const redirectUrl = encodeURIComponent(buildHomeURL('sales-portal', baseURL, langId, storeId, catalogId))
  // RestoreOriginalUserSetInSession -> RSMPortalDisplayCmd
  const restoreBOBUrl = `${baseURL}RestoreOriginalUserSetInSession?URL=${redirectUrl}`
  // RestoreOriginalUserSetInSession -> Logoff -> RSMPortalDisplayCmd
  const logoutUrl = `${baseURL}RestoreOriginalUserSetInSession?area=regionalsalesportal&new=Y&langId=${langId}&catalogId=${catalogId}&storeId=${storeId}&URL=${encodeURIComponent('Logoff?URL=RSMPortalDisplayCmd')}`

  return (
    <Menu>
      {({ isExpanded }) => (
        <React.Fragment>
          <StyledLink href={restoreBOBUrl}>Return To USIP</StyledLink>
          <StyledMenuButton>
            {isExpanded ? 
              <img alt="Arrow up icon" src={ArrowUp} /> :
              <img alt="Arrow down icon" src={ArrowDown} />
            }
          </StyledMenuButton>
          <StyledMenuList>
            <StyledCalloutNotch />
            <StyledMenuLink href={logoutUrl}>
              LOGOUT
            </StyledMenuLink>
          </StyledMenuList>
        </React.Fragment>
      )}
    </Menu>
  )
}

export default BackToUSIPDropdown
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro';
import OrderBand from '../../assets/images/orderband.svg';
import Button from '../../Shared/Components/GenericComponent/Button';
import Dialog from '../../Shared/Components/GenericComponent/Dialog';
import {
  CreateOrderAction
} from './actions';
import { getStoreId } from '../../Shared/AppContext/selectors';

const StyledButton = styled(Button)`
  font-family: fontregular;
  outline: none;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 280px;
  float: right;
`

const StyledDialogInput = styled.div`
  position: relative;
  display: flex;
  input {
    flex: 2;
    outline: none;
    padding: 12px 16px 12px 48px;
    line-height: 20px;
    font-family: fontregular;
    font-size: 16px;
    border: 1px solid black;
  }
  img {
    position:absolute;
    top:12px;
    left:12px;
    width:24px;
    height:24px;
  }
`

const CreateOrderDialog = ({translationKey,currentFileRequired}) => {
 
  const dispatch = useDispatch()
  const storeId = useSelector(getStoreId)
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => {
    setShowDialog(false);
    setInputValue('');
  };

  const [inputValue, setInputValue] = useState('');
  const handleChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleClick = () => {
    dispatch(CreateOrderAction({
      storeId,
      orderName: inputValue,
    }))
    close();
  }

  return (
    <div>
      <StyledButton onClick={open}>{translationKey(currentFileRequired['CREATE_CART'])}</StyledButton>
      <Dialog 
        showDialog={showDialog}
        close={close}
        title={`${translationKey(currentFileRequired['CREATE_CART'])}`}
        confirmActionLabel={`${translationKey(currentFileRequired['CREATE'])}`}
        confirmActionHandleClick={handleClick}
        cancelActionLabel={`${translationKey(currentFileRequired['CANCEL'])}`}
        cancelActionHandleClick={close}
        inputComponent={
          <StyledDialogInput>
            <img alt="cart" src={OrderBand} />
            <input placeholder={`${translationKey(currentFileRequired['CART_NAME'])}`} value={inputValue} onChange={handleChange}/>
          </StyledDialogInput>
        }
      />
    </div>
  )
}

export default CreateOrderDialog
const getHeader = state => state.header
export const isTopCategoriesLoading = state => getHeader(state)?.isLoading
export const isTopCategoriesLoaded = state => getHeader(state)?.isLoaded
export const getTopCategories = state => getHeader(state)?.topCategories

export const isMultiAccountsLoading = state => getHeader(state)?.multiAccountsLoading
export const isMultiAccountsLoaded = state => getHeader(state)?.multiAccountsLoaded
export const getMultiAccounts = state => getHeader(state)?.multiAccounts

export const isCustomerProfileLoading = state => getHeader(state)?.customerProfileLoading
export const isCustomerProfileLoaded = state => getHeader(state)?.customerProfileLoaded
export const getCustomerProfile = state => getHeader(state)?.customerProfile

export const isRunAsUserLoading = state => getHeader(state)?.runAsUserLoading
export const isRunAsUserLoaded = state => getHeader(state)?.runAsUserLoaded
export const getRunAsUser = state => getHeader(state)?.runAsUser
const getAppContext = state => state.appContext


export const getStoreId = state => getAppContext(state).storeId
export const getCatalogId = state => getAppContext(state).catalogId
export const getLangId = state => getAppContext(state).langId
export const getUser = state => getAppContext(state).user
export const getBaseURL = state => getAppContext(state).baseURL
export const getAppURL = state => getAppContext(state).appURL
export const getDmContextPath = state => getAppContext(state).dmContextPath
export const getDmImagePath = state => getAppContext(state).dmImagePath
export const getDmSubstitutionVariables = state => getAppContext(state).dmSubstitutionVariables
export const getSiteId = state => getAppContext(state).siteId
export const getForUser = state => getAppContext(state).forUser
export const isMultiAccountsCustomer = state => getAppContext(state)?.features?.includes('mac') ?? false
export const isCustomerServiceRepresentative = state => getAppContext(state)?.features?.includes('csr') ?? false
export const isBuyOnBehalf = state => getAppContext(state)?.features?.includes('bob') ?? false
export const getCallerId = state => getAppContext(state).callerId
export const isFeatureEnabled = (state, featureName) => getAppContext(state)?.features?.includes(featureName) ?? false
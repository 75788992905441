import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import BreadCrumb from '../../Shared/Components/BreadCrumb'
import { buildCategoryURL } from '../../Shared/linkBuilders'
import { getAppURL } from '../../Shared/AppContext/selectors'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../src/UserContext';
import { getCurrentFile } from '../../Shared/utility';

const SavedCartsBreadCrumb = () => {
  const appURL = useSelector(getAppURL)
  const breadCrumbTrail = []
  const { t:translationKey } = useTranslation();
  const {selectedLanguage} = useContext(UserContext);
  const currentFileRequired = getCurrentFile(selectedLanguage);
  const breadCrumbs = breadCrumbTrail?.map(({label, value}) => ({label, href: buildCategoryURL(appURL, value)}))
  return (
    <BreadCrumb breadCrumbs={breadCrumbs} currentPageLabel={`${translationKey(currentFileRequired['SAVED_CARTS'])}`}/>
  )  
}

export default SavedCartsBreadCrumb
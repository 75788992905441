import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux'
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button';
import Orderlock from '../../assets/images/orderlock.svg';
import StarOutline from '../../assets/images/star-outline.svg';
import EyeOutline from '../../assets/images/eye-outline.svg';
import ArrowUp from '../../assets/images/arrow-up.svg'
import ArrowDown from '../../assets/images/arrow-down.svg'
import SavedCartsBreadCrumb from './SavedCartsBreadCrumb';
import CreateOrderDialog from './CreateOrderDialog';
import RenameOrderDialog from './RenameOrderDialog';
import DeleteOrderDialog from './DeleteOrderDialog';
import {
  GetPendingOrdersAction,
  GetCurrentPendingOrderAction,
  SetCurrentPendingOrderAction,
  LockOrderAction,
  UnLockOrderAction,
  RenameOrderAction,
  DeleteOrderAction
} from './actions';
import {
  getPendingOrders,
  getCurrentPendingOrder,
  isCurrentPendingOrderLoaded,
} from './selectors';
import {
  getStoreId,
  isBuyOnBehalf,
  getLangId,
  getBaseURL,
  getCatalogId,
  getCallerId,
} from '../../Shared/AppContext/selectors';
import { priceFormatter, dateTimeFormatter } from '../../Shared/utility';
import Tooltip from '../../Shared/Components/GenericComponent/Tooltip';
import HTML from "../../Shared/Components/GenericComponent/HTML";
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../UserContext';
import { getCurrentFile } from '../../Shared/utility';

const MinHeightTable = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid;
  table {
    table-layout: fixed;
    border: none;
  }
  th, td {
    padding: 8px 16px;
    border-right: 1px solid #5A5A5A;
    &:last-child {
      border-right: none;
    }
  }
  img {
    width: 24px;
    height: 24px;
  }
  input {
    margin: 0px;
  }
  span {
    word-break: break-word;
  }
`

const FlexVerticalAlign = styled.div`
  display: flex;
  align-items: center;
`

const FlexHorizontalAlign = styled.div`
  display: flex;
  justify-content: center;
`

const FixedTH = styled.th`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.center && 'text-align: center;'}
`

const StyledMenuButton = styled(MenuButton)`
  font-family: fontregular;
  color: #4b4b4b;
  font-size: 16px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`

const StyledMenuList = styled(MenuList)`
  background-color: rgba(255,255,255,1);
  margin-top: 5px;
  margin-left: -220px;
  border: 1px solid #ccc;
  box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.15);
  border-sizing: border-box;
  &:focus {
    outline: none;
  }
  div:last-child {
    a {
      border: 0;
    }
  }
`

const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 44px;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  width: 220px;
  border-bottom: 1px solid #ccc;
  color: #4b4b4b;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
  ${props => props.fullwidth === 'true' && 'padding: 0; width: 100%;'}
`

const StyledCalloutNotch = styled.i`
  border: 1px solid #ccc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  left: 12px;
  top: 1px;
  background-color: rgba(255,255,255,1);
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`

const StyledTooltip = styled(Tooltip)`
  height: 20px;
  margin-left: 8px;
`

const EStoreSavedCarts = () => {
  const dispatch = useDispatch()
  const storeId = useSelector(getStoreId)
  const langId = useSelector(getLangId)
  const baseURL = useSelector(getBaseURL)
  const catalogId = useSelector(getCatalogId)
  const callerId = useSelector(getCallerId)

  const constructOrderUrl = (orderId) => 
  `${baseURL}SetPendingOrder?URL=OrderItemDisplay&langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&orderId=${orderId}&doPrice=N&from=savedCarts`

  const isBOB = useSelector(isBuyOnBehalf)
  const pendingOrders = useSelector(getPendingOrders)
  const currentPendingOrder = useSelector(getCurrentPendingOrder)
  const currentPendingOrderLoaded = useSelector(isCurrentPendingOrderLoaded)

    const { t:translationKey } = useTranslation();
    const {selectedLanguage} = useContext(UserContext);
    const currentFileRequired = getCurrentFile(selectedLanguage)

  const [currentOrderId, setCurrentOrderId] = useState('')
  const handleSelect = (orderId) => {
    setCurrentOrderId(orderId)
    dispatch(SetCurrentPendingOrderAction({ storeId, orderId }))
  }

  const handleLockOrder = (orderId, takeOverLock=false) => {
    dispatch(LockOrderAction({ storeId, orderId, takeOverLock }))
    .then(()=>{
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    })
  }

  const handleUnLockOrder = (orderId) => {
    dispatch(UnLockOrderAction({ storeId, orderId }))
    .then(()=>{
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    })
  }

  const handleTakeOverAndUnLockOrder =  (orderId) => {
    dispatch(LockOrderAction({ storeId, orderId, takeOverLock:true }))
    .then(()=>dispatch(UnLockOrderAction({ storeId, orderId })))
    .then(()=>{
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    })
  }

  const getHandleRenameAction = (order) => (orderName) => {
    dispatch(RenameOrderAction({
      storeId,
      orderId: order.orderId,
      orderName,
    }))
    .then(()=>{
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    })
  }

  const getHandleDeleteAction = (order) => () => {
    dispatch(DeleteOrderAction({
      storeId,
      orderId: order.orderId,
    }))
    .then(()=>{
      dispatch(GetCurrentPendingOrderAction({ storeId }))
      dispatch(GetPendingOrdersAction({ storeId }))
    })
  }

  useEffect(() => {
    dispatch(GetPendingOrdersAction({ storeId }))
    if (!currentPendingOrderLoaded) {
      dispatch(GetCurrentPendingOrderAction({ storeId }))
    }
  }, [])
  useEffect(() => {
    if (currentPendingOrder) {
      setCurrentOrderId(currentPendingOrder.orderId)
    }
  }, [currentPendingOrder])

  const isCurrentOrder = (order) => currentPendingOrder && currentPendingOrder.orderId === order.orderId
  const isOrderLocked = (order) => order.locked === "true"
  const isOrderEditedByCaller = (order) => order?.orderEditor?.uniqueID === callerId
  const isOrderHidden = (order) => parseInt(order.x_field1) === 1
  const isOrderOnlyVisibleToLoggedInUser = (order) => isOrderHidden(order) && isOrderLocked(order) && isOrderEditedByCaller(order)

  const visibleOrders = pendingOrders && pendingOrders.length > 0 && pendingOrders.filter((order) => !isOrderHidden(order) || !isOrderLocked(order) || isOrderEditedByCaller(order))

  return (
    <>
      <SavedCartsBreadCrumb translationKey={translationKey} currentFileRequired={currentFileRequired} />
      <CreateOrderDialog translationKey={translationKey} currentFileRequired={currentFileRequired} />
      <MinHeightTable>
        <table>
          <thead>
            <tr>
              <FixedTH width={50} />
              <FixedTH>{translationKey(currentFileRequired['CART_NUMBER'])}</FixedTH>
              <FixedTH>{translationKey(currentFileRequired['CART_NAME'])}</FixedTH>
              <FixedTH center>{translationKey(currentFileRequired['LAST_UPDATED'])}</FixedTH>
              <FixedTH center>{translationKey(currentFileRequired['TOTAL'])}</FixedTH>
              <FixedTH width={100} center>{translationKey(currentFileRequired['ACTIONS'])}</FixedTH>
            </tr>
          </thead>
          <tbody>
            {visibleOrders && visibleOrders.length > 0 && visibleOrders.map((order, index)=> (
              <tr key={index} >
                <td>
                  <FlexHorizontalAlign>
                    <input
                      type="radio"
                      name="optradio"
                      checked={currentOrderId === order.orderId}
                      onClick={() => handleSelect(order.orderId)}
                      onChange={() => {}}
                    />
                  </FlexHorizontalAlign>
                </td>
                <td>
                  <FlexVerticalAlign>
                    <a href={constructOrderUrl(order.orderId)}>{order.orderId}</a>
                    {isOrderLocked(order) && 
                    <StyledTooltip
                      id={`lock_${order.orderId}`}
                      text={isOrderEditedByCaller(order) ? 
                        `${translationKey(currentFileRequired['LOCK_CART_SELF_MESSAGE'])}`
                        : `${translationKey(currentFileRequired['LOCK_CART_MESSAGE'])} "${order?.orderEditor?.externalIdentifier?.identifier}".`}
                    >
                      <img alt="lock" src={Orderlock} />
                    </StyledTooltip>}
                    {isCurrentOrder(order) && 
                    <StyledTooltip
                      id={'currentOrder'}
                      text={`${translationKey(currentFileRequired['ACTIVE_CART_MESSAGE'])}`}
                    >
                      <img alt="star" src={StarOutline} />
                    </StyledTooltip>}
                    {isOrderOnlyVisibleToLoggedInUser(order) && 
                    <StyledTooltip
                      id={`visible_${order.orderId}`}
                      text={`${translationKey(currentFileRequired['PRIVATE_CART_MESSAGE'])}`}
                    >
                      <img alt="eye" src={EyeOutline} />
                    </StyledTooltip>}
                  </FlexVerticalAlign>
                </td>
                <td>
                  <span>
                    {order.orderDescription}
                  </span>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    {dateTimeFormatter(order.lastUpdateDate)}
                  </FlexHorizontalAlign>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    <HTML>{order.x_formattedTotalProductPrice}</HTML>
                  </FlexHorizontalAlign>
                </td>
                <td>
                  <FlexHorizontalAlign>
                    <Menu>
                      {({ isExpanded }) => (
                        <>
                          <StyledMenuButton>
                            { isExpanded ? <img alt="Dropdown" src={ArrowUp} /> : <img alt="Dropdown" src={ArrowDown} /> }
                          </StyledMenuButton>
                          <StyledMenuList>
                            <StyledCalloutNotch />
                            <StyledMenuLink href={constructOrderUrl(order.orderId)}>
                              {translationKey(currentFileRequired['VIEW_CART_DETAILS'])}
                            </StyledMenuLink>
                            {!isCurrentOrder(order) && 
                            <StyledMenuLink onClick={()=>{handleSelect(order.orderId)}}>
                              {translationKey(currentFileRequired['SET_AS_CURRENT_CART'])}
                            </StyledMenuLink>}
                            {isBOB && isOrderLocked(order) &&
                            <StyledMenuLink
                              onClick={()=>{
                                if (!isOrderEditedByCaller(order)) {
                                  handleTakeOverAndUnLockOrder(order.orderId)
                                } else {
                                  handleUnLockOrder(order.orderId)
                                }
                              }}
                            >
                              {translationKey(currentFileRequired['UNLOCK_CART'])}
                            </StyledMenuLink>}
                            {isBOB && isOrderLocked(order) && !isOrderEditedByCaller(order) &&
                            <StyledMenuLink onClick={()=>{handleLockOrder(order.orderId, true)}}>
                              {translationKey(currentFileRequired['TAKE_OVER_LOCK'])}
                            </StyledMenuLink>}
                            {isBOB && !isOrderLocked(order) &&
                            <StyledMenuLink onClick={()=>{handleLockOrder(order.orderId)}}>
                              {translationKey(currentFileRequired['LOCK_CART'])}
                            </StyledMenuLink>}
                            {((!isBOB && !isOrderLocked(order)) || (isBOB && isOrderLocked(order) && isOrderEditedByCaller(order))) && 
                            <StyledMenuLink fullwidth={'true'}>
                              <RenameOrderDialog
                                confirmAction={getHandleRenameAction(order)}
                                orderName={order.orderDescription}
                                translationKey={translationKey}
                                currentFileRequired={currentFileRequired}
                              />
                            </StyledMenuLink>}
                            {((!isBOB && !isOrderLocked(order)) || (isBOB && isOrderLocked(order) && isOrderEditedByCaller(order))) && 
                            <StyledMenuLink fullwidth={'true'}>
                              <DeleteOrderDialog 
                                confirmAction={getHandleDeleteAction(order)}
                                translationKey={translationKey}
                                currentFileRequired={currentFileRequired}
                              />
                            </StyledMenuLink>}
                          </StyledMenuList>
                        </>
                      )}
                    </Menu>
                  </FlexHorizontalAlign>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MinHeightTable>
    </>
  )
}

export default EStoreSavedCarts

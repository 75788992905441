import React from 'react'
import { useSelector } from 'react-redux'

import { getBreadCrumbTrail, getProduct } from './selectors'
import BreadCrumb from '../../Shared/Components/BreadCrumb'
import { buildCategoryURL } from '../../Shared/linkBuilders'
import { getAppURL } from '../../Shared/AppContext/selectors'

const ProductDescriptionBreadCrumb = () => {
  const appURL = useSelector(getAppURL)
  const breadCrumbTrail = useSelector(getBreadCrumbTrail)
  const product = useSelector(getProduct)
  const breadCrumbs = breadCrumbTrail?.map(({label, value}) => ({label, href: buildCategoryURL(appURL, value)}))
  return (
    <BreadCrumb breadCrumbs={breadCrumbs} currentPageLabel={product?.name}/>
  )  
}

export default ProductDescriptionBreadCrumb
import styled from 'styled-components/macro';

const Button = styled.button`
  font-size: 16px;
  line-height:20px;
  font-family: fontbold;
  padding: 11px 16px;
  transition: 0.5s all ease-out;

  &:enabled {
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #000000;
    cursor: pointer;
  }
 
  &:enabled:hover {
    color: #FFFFFF;
    background: #000000;
  }

  &:disabled {
    color: #B0B0B0;
    background: #E3E3E3;
    border: 1px solid #B0B0B0;
  }
`;

export default Button;
import queryString from 'query-string'
import {
  actionCreator,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
} from "../../utility"

/**
 * @typedef {Object} EspotQuery
 * @property {string} name
 * @property {string} storeId
 * @property {string} catalogId
 * @property {string} langId
 * @property {string} categoryId
 * @property {string} productId
 * @property {string} reqCommand
 * @property {string} dmContextPath
 * @property {string} dmImagePath
 * @property {Object.<string, string>} variables
 */

const ACTION_TYPES = {
  FETCH_ESPOT: createRequestActionTypes('FETCH_ESPOT')
}
const INITIAL_STATE = {}

/**
 * 
 * @param {EspotQuery} espotQuery 
 */
const espotKey = ({
  name = '',
  categoryId = '',
  productId = '',
  searchTerm = '',
  reqCommand = '',
  variables = {},
}) => (
  JSON.stringify([
    name,
    categoryId,
    productId,
    searchTerm,
    reqCommand,
    Object.entries(variables).sort((a,b) => a[0].localeCompare(b[0])),
  ])
)

export const EspotReducer = (
  state = INITIAL_STATE,
  { type, payload, meta }
) => {
  switch (type) {
    case ACTION_TYPES.FETCH_ESPOT.SUCCESS: 
      const { request } = meta
      const key = espotKey(request)
      return {
        ...state,
        [key]: payload
      }
    default:
      return state
  }
}

/**
 * 
 * @param {EspotQuery} request 
 */
export const fetchEspot = (request) => {
  return async dispatch => {
    const {
      storeId,
      name,
      reqCommand,
      dmContextPath,
      dmImagePath,
      variables = {},
      ...queryParams
    } = request
    const meta = { request }

    dispatch(actionCreator(ACTION_TYPES.FETCH_ESPOT.REQUEST, request))

    const query = queryString.stringify({
      ...queryParams,
      DM_ReqCmd: reqCommand,
      DM_contextPath: dmContextPath,
      DM_imagePath: dmImagePath,
      ...(Object.entries(variables).reduce((accum,variable,idx) => {
        const [name, value] = variable
        const paramIdx = idx+1
        return {
          ...accum,
          [`DM_SubstitutionName${paramIdx}`]: name,
          [`DM_SubstitutionValue${paramIdx}`]: value,
        }
      }, {}))
    })
    const url = `/wcs/resources/store/${storeId}/espot/${name}?${query}`
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: jsonApiHeader(),
      })
      const payload = await checkHttpStatus(response)
      dispatch(actionCreator(ACTION_TYPES.FETCH_ESPOT.SUCCESS, payload, meta))
    } catch (error) {
      dispatch(actionCreator(ACTION_TYPES.FETCH_ESPOT.FAILURE, error, meta))
    }
  }
}

const getEspots = state => state.espots

/**
 * 
 * @param {EspotQuery} espotQuery 
 */
export const getEspot = (espotQuery) => (
  state => (
    getEspots(state)?.[espotKey(espotQuery)]?.MarketingSpotData?.[0]
  )
)
import React from 'react'
import styled from 'styled-components/macro'

const ImageContainer = styled.div`
    position: relative;
    ${props => props.width ? `width: ${props.width};` : ''}
    ${props => props.height ? `height: ${props.height};` : ''}
`
const Image = styled.img`
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`

const FixedImage = ({width, height, alt, src, className}) => (
  <ImageContainer className={className} width={width} height={height}>
    <Image alt={alt} src={src}/>
  </ImageContainer>
)

export default FixedImage

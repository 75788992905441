import React, { useEffect } from "react";
import styled from "styled-components/macro";
import TiersProgress from "./TiersProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import RewardMessageContainer from "./RewardMessage";
// import { LoyalityRewardsData } from './fetch';
import { GetLoyaltyPoints } from "./actions/index";
import {
  getLoyaltyPoints,
  isLoyaltyPointsLoading,
  getLoyaltyTiers,
} from "./selectors/index";
import { getStoreId, getUser } from "../../Shared/AppContext/selectors";
import { useDispatch, useSelector } from "react-redux";
import { GetRewardProperties } from "./actions/index";
import {
  getRewardProperties,
  isRewardPropertiesLoading as propertiesLoading,
} from "./selectors/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: "#1E1E96!important",
  },
  bar: {
    backgroundColor: "#1E1E96!important",
  },
});

const RewardsWrapper = styled.div`
  margin: 20px 0px;
`;

const Header = styled.div`
  font-size: 26px;
  line-height: 32px;
  font-family: fontbold, Helvetica, Arial, Verdana, sans-serif;
  font-style: bold;
  color: #000000;
`;

const RewardMoney = styled.div`
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  display: inline-block;
  width: 220px;
  text-align: center;
`;
const RewardText = styled.span`
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  display: inline-block;
  padding: 0px 5px;
`;

const DiscountBlock = styled.div`
  margin: 40px 0 80px;
`;

const GridLayout = styled.div`

  display: grid;
  grid-template-areas:
    'nav main'
  grid-template-rows:  1fr 10fr; 
  grid-template-columns: 2fr 6fr;
`;
const RewardWrapper = styled.div`
  width: 224px;
  height: 224px;
  background: linear-gradient(
    145.73deg,
    #00c8e6 16.67%,
    #1e1e96 52.24%,
    #8228b4 87.43%
  );
  border-radius: 180px;
  position: relative;
  display: flex;
  margin-right: 145px;
  border: 1px solid #1e90ff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TiersBar = styled.div`
  display: inline-block;
`;
const StyledLinearProgress = styled(LinearProgress)`
  width: 200px;
  border-radius: 10px;
  height: 8px !important;
  margin: 10px 0px;
  display: inline-block;
  top: 10px;
  barcolorprimary: {
    backgroundcolor: #1e1e96 !important;
  }
`;

const ProgressBarText = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
`;

const TrackBlock = styled.div`
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  max-width: 300px;
`;
const TrackTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  margin: 10px 0px -15px;
`;
const TrackText = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin: 0 10px;
`;

const TrackWrapper = styled.div`
  display: inline-block;
  margin: 20px 20px 0 0px;
`;
const SuperScript = styled.sup`
  font-size: 12px;
`;

const MyRewards = ({ currentFileRequired, translationKey }) => {
  // const rewardsData = LoyalityRewardsData;
  const futureYear = new Date().getFullYear() + 1;
  const classes = useStyles();
  const dispatch = useDispatch();
  const rewardsData = useSelector(getLoyaltyPoints);
  const isPointsLoading = useSelector(isLoyaltyPointsLoading);
  const messageData = useSelector(getRewardProperties);
  const isRewardPropertiesLoading = useSelector(propertiesLoading);
  const userDetails = useSelector(getUser);

  const storeId = useSelector(getStoreId);
  const coreSale =
    rewardsData &&
    Math.round(parseInt(rewardsData?.currentCoreSale) / 1000) * 1000;
  const roundedCoreSale = Math.round(coreSale / 1000);
  const targetSale =
    rewardsData &&
    parseInt(rewardsData?.currentCoreSale) +
      parseInt(rewardsData?.additionalCoreSaleToTargetTier);
  const targetShortSale = Math.round(targetSale / 1000) * 1000;
  const roundedTargetSale = Math.round(targetShortSale / 1000);
  const targetAlignerCount =
    rewardsData &&
    parseInt(rewardsData?.currentAlignerCount) +
      parseInt(rewardsData?.additionalAlignerCasesToTargetTier);
  const salePercentage = (roundedCoreSale / roundedTargetSale) * 100;
  const alignerPercentage =
    (parseInt(rewardsData?.currentAlignerCount) / targetAlignerCount) * 100;
  const savingsAmount = Math.round(rewardsData?.savingsThisYear);

  useEffect(() => {
    if (!isPointsLoading && !rewardsData) {
      dispatch(GetLoyaltyPoints({ storeId }));
    }
  }, [isPointsLoading, rewardsData, dispatch, storeId]);

  useEffect(() => {
    if (!messageData && !isRewardPropertiesLoading) {
      dispatch(GetRewardProperties({ storeId }));
    }
  }, [messageData, isRewardPropertiesLoading, dispatch, storeId]);

  const currentFlag = rewardsData?.currentProgressFlag;
  const levelUpFlag = rewardsData?.levelUpFlag;
  const leftHeader =
    rewardsData &&
    messageData &&
    messageData?.properties[`LEFT_HEADER_${currentFlag}_${levelUpFlag}`];
  const leftBody =
    rewardsData &&
    messageData &&
    messageData?.properties[`LEFT_BODY_${currentFlag}_${levelUpFlag}`];
  const rightHeader =
    rewardsData &&
    messageData &&
    messageData?.properties[`RIGHT_HEADER_${currentFlag}_${levelUpFlag}`];
  const rightBody =
    rewardsData &&
    messageData &&
    messageData?.properties[`RIGHT_BODY_${currentFlag}_${levelUpFlag}`];
  return (
    <>
      {rewardsData && messageData && (
        <RewardsWrapper>
          <Header>{translationKey(currentFileRequired["MY_REWARDS"])}</Header>
          <DiscountBlock>
            <GridLayout>
              <RewardWrapper>
                <RewardMoney>
                  ${savingsAmount.toLocaleString("en-us")}
                </RewardMoney>
                <RewardText>
                  {translationKey(currentFileRequired["SAVINGS_THIS_YEAR"])}
                  (USD)
                </RewardText>
              </RewardWrapper>
              <TiersBar>
                <ProgressBarText>
                  {translationKey(currentFileRequired["CURRENT_3M"])}
                  <SuperScript>
                    {translationKey(currentFileRequired["TM"])}
                  </SuperScript>{" "}
                  {translationKey(currentFileRequired["CLARITY"])}
                  <SuperScript>
                    {translationKey(currentFileRequired["TM"])}
                  </SuperScript>
                  {translationKey(currentFileRequired["DISCOUNT_STATUS"])}
                </ProgressBarText>
                <TiersProgress />
              </TiersBar>
            </GridLayout>
            <TrackWrapper>
              <TrackBlock>
                <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {leftHeader}
                </span>
                <br />
                <span>{leftBody}</span>
                {rewardsData.currentProgressFlag == 2 && (
                  <span> {futureYear}</span>
                )}
              </TrackBlock>
              <TrackTitle>
                {translationKey(currentFileRequired["CLARITY_ALIGNERS_CASES"])}
              </TrackTitle>
              <StyledLinearProgress
                classes={{
                  bar: classes.bar,
                }}
                variant="determinate"
                value={alignerPercentage}
              />
              <TrackText>
                {parseInt(rewardsData?.currentAlignerCount)} of{" "}
                {targetAlignerCount}
              </TrackText>

              <TrackTitle>
                {translationKey(currentFileRequired["OTHER_PRODUCT_PURCHASES"])}
              </TrackTitle>
              <StyledLinearProgress
                classes={{
                  bar: classes.bar,
                }}
                variant="determinate"
                value={
                  roundedCoreSale == 0 && roundedTargetSale == 0
                    ? 0
                    : salePercentage
                }
              />

              <TrackText>
                ${roundedCoreSale}K of ${roundedTargetSale}K
                <sub style={{ position: "relative", top: "-4px" }}>(USD)</sub>
              </TrackText>
            </TrackWrapper>
            <RewardMessageContainer
              rightHeader={rightHeader}
              rightBody={rightBody}
              currentFileRequired={currentFileRequired}
              translationKey={translationKey}
              {...rewardsData}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              {userDetails &&
                userDetails.billingCountry &&
                userDetails.billingCountry == "CAN" && (
                  <span>
                    Note: all dollar values shown on page are listed in U.S.
                    currency.
                  </span>
                )}
            </div>
          </DiscountBlock>
        </RewardsWrapper>
      )}
    </>
  );
};

export default MyRewards;

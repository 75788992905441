import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import useEspot from './useEspot'
import { useSelector } from 'react-redux'
import { getDmContextPath } from '../../AppContext/selectors'

const EspotContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
`
const EspotItem = styled.div`
  flex: 1;
`

const HTTP_PATTERN = /^https?:/
const EspotImage = ({asset}) => {
  const {
    attachmentAssetPath,
    attachmentAssetRootDirectory,
  } = asset
  const src = (attachmentAssetPath.match(HTTP_PATTERN)) 
    ? attachmentAssetPath
    : `/wcsstore/${attachmentAssetRootDirectory}/${attachmentAssetPath}` 
  return <img alt="" src={src}/>
}

const ConditionalLink = ({contentUrl, children}) => {
  const { location } = window
  const dmContextPath = useSelector(getDmContextPath)
  if ( contentUrl ) {
    if ( contentUrl.match(HTTP_PATTERN) ) {
      if ( !contentUrl.startsWith(location.origin)) {
        return <a target="_blank" rel="noopener noreferrer" href={`${contentUrl}`}>{children}</a>
      } else {
        return <a href={`${contentUrl}`}>{children}</a>
      }
    } else if ( contentUrl.startsWith('/') ) {
      return <a href={contentUrl}>{children}</a>
    } else {
      return <a href={`${dmContextPath}/${contentUrl}`}>{children}</a>
    }
  } else {
    return <span>{children}</span>
  }
}

const Espot = ({
  name,
  categoryId,
  productId,
  searchTerm,
  reqCommand,
  vertical,
  collapse,
  variables,
}) => {
  const espot = useEspot({
    name,
    categoryId,
    productId,
    searchTerm,
    reqCommand,
    variables,
  })
  const marketingContentActivities = espot?.baseMarketingSpotActivityData
    ?.filter(activity => activity.baseMarketingSpotDataType === 'MarketingContent') ?? []

  if (collapse && marketingContentActivities.length === 0) {
    return null
  }
  return (
    <EspotContainer vertical={vertical}>
      { marketingContentActivities
        .map(activity => {
          if (activity.contentFormatName === 'Text' && activity.marketingContentDescription.length > 0) {
            const { marketingText } = activity.marketingContentDescription[0]
            const { contentUrl } = activity
            return (
              <EspotItem>
                <ConditionalLink contentUrl={contentUrl}>
                  <div dangerouslySetInnerHTML={{__html: marketingText}}/>
                </ConditionalLink>
              </EspotItem>
            )
          } else if (activity.contentFormatName === 'File' && activity.attachmentAsset) {
            return activity.attachmentAsset.map(asset => {
              const { contentUrl } = activity
              return (
                <span>
                {
                  asset.attachmentAssetPath && (
                    <EspotItem>
                      <ConditionalLink contentUrl={contentUrl}>
                        <EspotImage asset={asset}/>
                      </ConditionalLink>
                    </EspotItem>
                  )
                }
                {
                  (asset.attachmentAssetMimeType === 'text/plain') && (
                    <EspotItem>
                      <ConditionalLink contentUrl={contentUrl}>
                        <div dangerouslySetInnerHTML={{__html: asset.html}}/>
                      </ConditionalLink>
                    </EspotItem>
                  )
                }
                </span>
              )
            })
          } else {
            return null
          }
        })
      }
    </EspotContainer>
  )
}

Espot.propTypes = {
  name: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  productId: PropTypes.string,
  searchTerm: PropTypes.string,
  /**
   * @type {string} - 'CategoryDisplay'||'ProductDisplay'||'SearchDisplay'
   */
  reqCommand: PropTypes.string,
  vertical: PropTypes.bool,
  collapse: PropTypes.bool,
  variables: PropTypes.objectOf(PropTypes.string),
}

Espot.defaultProps = {
  vertical: false,
  collapse: false,
}

export default Espot
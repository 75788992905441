import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const MenuList = styled.div`
    background: #f9f9f9;
    margin-bottom: 32px;
    flex-direction: column!important;
    display:flex;
    position: sticky;
    top: 12px;
`;

const MenuItem = styled.button`
    border: none;
    border-bottom: 1px solid #000;
    height: 33.6px;
    line-height: 20px;
    color: #000;
    font-size: 14px;
    padding: 6px 8px;
    font-family: fontregular;
    text-align: left;
    background-color: transparent;
    cursor: pointer;
`

class NavBar extends Component {
    render() {
        const { scrollToSection,currentFileRequired,translationKey } = this.props
        return (
            <div className="drawer">
                <MenuList className="menuList">
                    <MenuItem onClick={() => scrollToSection('overview')}><b>{translationKey(currentFileRequired["OVERVIEW"])}</b></MenuItem>
                    <MenuItem onClick={() => scrollToSection('specifications')}>{translationKey(currentFileRequired["SPECIFICATIONS"])}</MenuItem>
                    <MenuItem onClick={() => scrollToSection('details')}>{translationKey(currentFileRequired["DETAILS"])}</MenuItem>
                </MenuList>
            </div>
        );
    }
}

NavBar.propTypes = {
    scrollToSection: PropTypes.func.isRequired
};

export default NavBar;
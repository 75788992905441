import ProductList from '../Views/ProductListing/index';
import ProductDescription from '../Views/ProductDescription/index'
import SalesPortalHeader from '../Shared/Components/Header/variations/SalesPortalHeader';
import SearchResults from '../Views/SearchResults';
import SalesPortalSavedCarts from '../Views/SavedCarts/SalesPortalSavedCarts'

const routes = [
  {
    path: '/catalog',
    component: SearchResults,
    header: SalesPortalHeader,
    activeMenu: 'catalog',
  },
  {
    path: '/category/:categoryId',
    component: ProductList,
    header: SalesPortalHeader,
    activeMenu: 'catalog',
  },
  {
    path: '/product/:productId',
    component: ProductDescription,
    header: SalesPortalHeader,
    activeMenu: 'catalog',
  },
  {
    path: '/search/:searchTerm',
    component: SearchResults,
    header: SalesPortalHeader,
    activeMenu: 'catalog',
  },
  {
    path: '/saved-carts',
    component: SalesPortalSavedCarts,
    header: SalesPortalHeader,
    activeMenu: 'saved-carts',
  },
  {
    header: SalesPortalHeader,
  },
]

export default routes
import {
    actionCreator,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../Shared/utility';

export {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader
};

export const SearchResultsActionTypes = {
    get_searchResults: createRequestActionTypes('GET_SEARCH_RESULTS'),
    get_searchCategoryName: createRequestActionTypes('GET_SEARCH_CATEGORY_NAME'),
};
import queryString from 'query-string'
import { actionCreator, checkHttpStatus } from "../../../utility"
import { OrderFavoritesDropdownActionTypes } from "../constants/ApiCostants"

export const GetOrderFavoritesAction = ({ storeId, forUser }) => {
  return async dispatch => {
    dispatch(actionCreator(OrderFavoritesDropdownActionTypes.get_orderFavorites.REQUEST))
    try {
      const forUserId = forUser?.usersId
      const query = {
        forUserId
      }
      const url = `/wcs/resources/store/${storeId}/interestItemList/@self`
      const response = await fetch(queryString.stringifyUrl({ url, query }))
      const jsonResponse = await checkHttpStatus(response)
      dispatch(actionCreator(OrderFavoritesDropdownActionTypes.get_orderFavorites.SUCCESS, jsonResponse))
    } catch (error) {
      dispatch(actionCreator(OrderFavoritesDropdownActionTypes.get_orderFavorites.FAILURE))
      console.log('error get_orderFavorites ..', error)
    }
  }  
}
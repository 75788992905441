import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import { GetTopCategoriesAction } from "./actions";
import { getTopCategories } from "./selectors";
import { buildCategoryURL } from "../../linkBuilders";
import {
  getAppURL,
  getCatalogId,
  getStoreId,
} from "../../AppContext/selectors";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";

const StyledMenuList = styled(MenuList)`
  background-color: rgba(90, 90, 90, 1);
  margin-top: 5px;
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.15);
  cursor:pointer;
  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 52px;
    top: -5px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(90, 90, 90, 1) transparent;
  }
  &:focus {
    outline: none;
  }
`;
const StyledMenuLink = styled(MenuLink)`
  font-family: fontregular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 44px;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff !important;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    to left,
    rgba(235, 235, 235, 0) 0%,
    #ebebeb 100%
  );
  border-image-slice: 1;
  &[data-reach-menu-item][data-selected] {
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
`;

const CatalogMenu = ({ className, translationKey, currentFileRequired }) => {
  const [clicked, setClicked] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const categories = useSelector(getTopCategories);
  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);
  const appURL = useSelector(getAppURL);

  useEffect(() => {
    handleMouseOver();
  }, []);

  const toUrl = (category) => buildCategoryURL(appURL, category.uniqueID);
  
  const handleMouseOver = !isLoaded
    ? () => {
        dispatch(GetTopCategoriesAction({ storeId, catalogId }));
        setLoaded(true);
      }
    : undefined;
  const handleButtonDown = () => {
    if (!clicked) setClicked(true);
  };
  const handleMenuUp = (e) => {
    if (clicked) setClicked(false);
  };
  const handleMenuOut = (e) => {
    if (clicked) setClicked(false);
  };
  const handleMenuClick = (e) => {
    if (clicked) {
      e.preventDefault();
      setClicked(false);
    }
  };

  return (
  <Menu>
    {({ isExpanded }) => (
      <React.Fragment>
        <MenuButton
            // onFocus={handleMouseOver}
            // onMouseOver={handleMouseOver}
            // onMouseDown={handleButtonDown}
            className={className}
            style={{ fontSize: "16px" }}
          >
            {translationKey(currentFileRequired["CATALOG"])}
            <img alt="" src={isExpanded ? ArrowUp : ArrowDown}/>
          </MenuButton>
          {categories &&  (
            <StyledMenuList>
              {categories?.catalogGroupView?.map((category) => (
                <StyledMenuLink
                  key={category.uniqueID}
                  href={toUrl(category)}
                  onClick={handleMenuClick}
                  onMouseUp={handleMenuUp}
                  onMouseOut={handleMenuOut}
                >
                  {category.name}
                </StyledMenuLink>
              ))}
            </StyledMenuList>
          )}
      </React.Fragment>
    )}
  </Menu>
  );
};

CatalogMenu.propTypes = {
  className: PropTypes.string,
};

export default CatalogMenu;

/* eslint-disable jsx-a11y/anchor-is-valid */
import queryString from "query-string";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components/macro";
import {
  getBaseURL,
  getCatalogId,
  getForUser,
  getLangId,
  getSiteId,
  getStoreId,
} from "../../Shared/AppContext/selectors";
import ContentWrapper from "../../Shared/Components/GenericComponent/ContentWrapper";
import FixedImage from "../../Shared/Components/GenericComponent/FixedImage";
import SideBarContentWrapper from "../../Shared/Components/GenericComponent/SideBarContentWrapper";
import ProductDescriptionSideBar from "./ProductDescriptionSideBar";
import OrderFavoritesDropDown from "../../Shared/Components/OrderFavoritesDropDown";
import { getRecListId } from "../../Shared/Components/OrderFavoritesDropDown/selectors";
import Button from "./../../Shared/Components/GenericComponent/Button";
import Label from "./../../Shared/Components/GenericComponent/Label";
import MainTitle from "./../../Shared/Components/GenericComponent/MainTitle";
import {
  AddToCartAction,
  AddToOrderFavoritesAction,
  GetFilteredProductOptionsAction,
  GetMoreProductOptionsAction,
  GetProductDetailsAction,
  GetProductSpecificationsAction,
} from "./actions/index";
import "./index.css";
import {
  getAttributeMap,
  getErrorMessages,
  getFacets,
  getProduct,
  getProductOptions,
  getTotalProductOptions,
  isAddingToOrderFavorites,
  isLoading,
  hasMore,
  getHeaders,
} from "./selectors";
import ProductDescriptionBreadCrumb from "./ProductDescriptionBreadCrumb";
import ProductOptionDropdown from "./ProductOptionDropDown";
import HTML from "../../Shared/Components/GenericComponent/HTML";
import { withTranslation } from "react-i18next";
import { UserContext } from "../../UserContext";
import { getCurrentFile } from "../../Shared/utility";

const PLACEHOLDER = "//multimedia.3m.com/mws/media/642088J";
const INITIAL_PAGE_SIZE = 50;
const MORE_PAGE_SIZE = 1000;
const RESET = "__reset__";

const Flex = styled.div`
  display: flex;

  > div:first-child {
    flex-grow: 1;
  }

  > .flexRight {
    width: 202px;
  }
`;

const FlexOne = styled.div`
  display: flex;
`;

const TableActionDropdown = styled.div`
  width: 240px;
  margin-right: 12px;
`;

const Action = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 22px 12px;
  margin-bottom: 92px;
`;

const Input = styled.input`
  background: transparent;
  outline: none;
  color: #000;
  font-size: 16px;
  height: 24px;
  width: 54px;
  padding: 4px;
  border: 1px solid #000;
  border-radius: 4px;
`;

const Error = styled.output`
  flex-basis: 100%;
  text-align: right;
  color: red;
  a {
    color: red;
    font-weight: normal;
    text-decoration: underline !important;
  }
`;

const LoadMoreTD = styled.td`
  height: 283px;
  vertical-align: top;
  ${(props) => (props.centered ? "text-align: center;" : "")}
`;

const detailBullets = Array(12)
  .fill(0)
  .map((n, i) => `Bullet${i + 1}`);
const summaryBullets = detailBullets.slice(0, 4);

class ProductDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      specifications: [],
      qty: {},
      product: null,
      headers: [],
      facets: [],
      productOptions: [],
      errorMessages: [],
      attributeMap: {},
      filters: {},
      listId: RESET,
      totalProductOptions: null,
      loading: false,
      hasMore: false,
    };
    this.overviewRef = React.createRef();
    this.specificationsRef = React.createRef();
    this.detailsRef = React.createRef();
    this.legalRef = React.createRef();
    this.scrollTableRef = React.createRef();
  }

  componentDidMount = () => {
    const {
      storeId,
      catalogId,
      match: {
        params: { productId },
      },
      location: { search },
    } = this.props;
    const { categoryId } = queryString.parse(search);
    this.props.GetProductDetailsAction({
      storeId,
      catalogId,
      productId,
      categoryId,
    });
    this.props.GetProductSpecificationsAction({
      storeId,
      catalogId,
      productId,
      pageSize: INITIAL_PAGE_SIZE,
    });
  };

  componentDidUpdate = (prevProps) => {
    let stateChanges = {};
    if (this.props.product && this.props.product !== prevProps.product) {
      stateChanges = {
        ...stateChanges,
        product: this.props.product,
      };
    }
    if (this.props.headers && this.props.headers !== prevProps.headers) {
      stateChanges = {
        ...stateChanges,
        headers: this.props.headers,
      };
    }
    if (this.props.facets && this.props.facets !== prevProps.facets) {
      stateChanges = {
        ...stateChanges,
        facets: this.props.facets,
      };
    }
    if (
      this.props.productOptions &&
      this.props.productOptions !== prevProps.productOptions
    ) {
      stateChanges = {
        ...stateChanges,
        productOptions: this.props.productOptions,
      };
    }
    if (this.props.totalProductOptions !== prevProps.totalProductOptions) {
      stateChanges = {
        ...stateChanges,
        totalProductOptions: this.props.totalProductOptions,
      };
    }
    if (this.props.errorMessages !== prevProps.errorMessages) {
      stateChanges = {
        ...stateChanges,
        errorMessages: this.props.errorMessages,
      };
    }
    if (
      this.props.attributeMap &&
      this.props.attributeMap !== prevProps.attributeMap
    ) {
      stateChanges = {
        ...stateChanges,
        attributeMap: this.props.attributeMap,
      };
    }
    if (
      !this.props.addingToOrderFavorites &&
      prevProps.addingToOrderFavorites
    ) {
      stateChanges = {
        ...stateChanges,
        listId: RESET, // reset add to order favorites
      };
    }
    if (this.props.loading !== prevProps.loading) {
      stateChanges = {
        ...stateChanges,
        loading: this.props.loading,
      };
    }
    if (this.props.hasMore !== prevProps.hasMore) {
      stateChanges = {
        ...stateChanges,
        hasMore: this.props.hasMore,
      };
    }
    if (Object.keys(stateChanges).length > 0) {
      this.setState(stateChanges);
    }
  };

  scrollToOverviewRef = () =>
    window.scrollTo(0, this.overviewRef.current.offsetTop);
  scrollToSpecificationsRef = () =>
    window.scrollTo(0, this.specificationsRef.current.offsetTop);
  scrollToDetailsRef = () =>
    window.scrollTo(0, this.detailsRef.current.offsetTop);
  scrollToLegalRef = () => window.scrollTo(0, this.legalRef.current.offsetTop);
  resetScrollTable = () => {
    this.scrollTableRef.current.scrollTop = 0;
  };

  scrollToSection = (section) => {
    switch (section) {
      case "overview":
        return this.scrollToOverviewRef();
      case "specifications":
        return this.scrollToSpecificationsRef();
      case "details":
        return this.scrollToDetailsRef();
      default:
    }
  };

  renderBullets = (bullets) =>
    bullets
      .map((bullet) => this.props.attributeMap?.[bullet]?.values?.[0]?.value)
      .filter((x) => x)
      .map((bullet, index) => <li key={index}>{bullet}</li>);

  handleFilterChange = (facet, value) => {
    const {
      storeId,
      catalogId,
      match: {
        params: { productId },
      },
    } = this.props;
    const { filters: oldFilters } = this.state;
    const filters = {
      ...oldFilters,
      [facet.value]: value === RESET ? undefined : value,
    };
    this.setState({ filters });
    this.props.GetFilteredProductOptionsAction({
      storeId,
      catalogId,
      productId,
      filters: Object.values(filters).filter((o) => o),
      pageSize: INITIAL_PAGE_SIZE,
    });
    this.resetScrollTable();
  };

  loadMore = () => {
    const {
      storeId,
      catalogId,
      match: {
        params: { productId },
      },
    } = this.props;
    const { filters } = this.state;
    this.props.GetMoreProductOptionsAction({
      storeId,
      catalogId,
      productId,
      filters: Object.values(filters).filter((o) => o),
      pageSize: MORE_PAGE_SIZE,
    });
  };

  handleQtyChange = (itemId, e) => {
    const value = e.target.value;
    this.setState((state) => ({
      qty: {
        ...state.qty,
        [itemId]: value,
      },
    }));
  };

  addToCart = () => {
    const { storeId, langId, catalogId, baseURL } = this.props;
    const { qty } = this.state;
    this.props.AddToCartAction({
      storeId,
      langId,
      catalogId,
      baseURL,
      orderItems: Object.entries(qty).map(([productId, quantity]) => ({
        productId,
        quantity,
      })),
    });
    this.setState({ qty: [] });
  };

  addToOrderFavorites = (listId) => {
    this.setState({ listId });
    const { storeId, langId, catalogId, baseURL, recListId, forUser } =
      this.props;
    const { qty } = this.state;
    this.props.AddToOrderFavoritesAction({
      storeId,
      langId,
      catalogId,
      baseURL,
      listId,
      recListId: recListId,
      forUser,
      items: Object.entries(qty).map(([productId, quantity]) => ({
        productId,
        quantity,
      })),
    });
  };

  static contextType = UserContext;

  render() {
    const { selectedLanguage = "" } = this?.context;
    const {
      product,
      facets,
      productOptions,
      errorMessages,
      attributeMap,
      qty,
      listId,
      totalProductOptions,
      loading,
      headers,
      hasMore,
    } = this.state;
    const { siteId, forUser, t: translationKey = {} } = this.props;
    // console.log('daata--------', data);
    // console.log('specifications--------', specifications);

    const hasDisclaimer = facets
      ?.find((facet) => facet?.extendedData?.attridentifier === "Prescription")
      ?.entry?.some((entry) => entry?.label?.includes("*"));
    const specifications =
      facets
        ?.filter((obj) => obj?.extendedData?.ffield3?.includes("S"))
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
    const c2aDisabled = !Object.values(qty)?.some((q) => parseInt(q) > 0);
    const showC2A =
      siteId === "e-store" ||
      (siteId === "sales-portal" && typeof forUser === "object");

    var currentFileRequired = getCurrentFile(selectedLanguage);

    return (
      <>
        <ProductDescriptionBreadCrumb />
        <SideBarContentWrapper>
          <ProductDescriptionSideBar
            currentFileRequired={currentFileRequired}
            translationKey={translationKey}
            scrollToSection={(section) => this.scrollToSection(section)}
          />
          <ContentWrapper>
            <Flex>
              <div>
                <MainTitle style={{ marginTop: "10px" }} ref={this.overviewRef}>
                  {product?.name}
                </MainTitle>
                <FlexOne>
                  <div className="displayInlineFlex">
                    <FixedImage
                      width="220px"
                      alt={`${product?.name}`}
                      src={product?.thumbnail ?? PLACEHOLDER}
                      className="descPlaceholder displayInline"
                    />
                    <div className="displayInline">
                      <ul className="details">
                        {this.renderBullets(summaryBullets)}
                      </ul>
                      <div className="viewDetail">
                        <a onClick={() => this.scrollToDetailsRef()}>
                          {translationKey(
                            currentFileRequired["VIEW_ALL_DETAILS"]
                          )}
                        </a>
                        {hasDisclaimer && (
                          <a onClick={() => this.scrollToLegalRef()}>
                            {translationKey(
                              currentFileRequired["VIEW_LEGAL_DISCLAIMER"]
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </FlexOne>
              </div>
              <div className="flexRight">
                {facets
                  ?.filter((facet) =>
                    facet.extendedData?.ffield3?.includes("P")
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((facet, index) => {
                    return (
                      <div key={index}>
                        <Label>{facet.name}</Label>
                        <ProductOptionDropdown
                          options={[
                            { label: "Select...", value: RESET },
                            ...facet.entry,
                          ]}
                          onChange={(item) =>
                            this.handleFilterChange(facet, item)
                          }
                          cancellable
                        />
                      </div>
                    );
                  })}
              </div>
            </Flex>
            <div className="yourSelection">
              {totalProductOptions}{" "}
              {translationKey(currentFileRequired["PRODUCTS_AVAILABLE"])}
            </div>
            <div className="optionTable">
              <div className="minHeightTable" ref={this.scrollTableRef}>
                <table>
                  <thead>
                    <tr>
                      {headers?.map((header, index) => (
                        <th key={index}>{header.name}</th>
                      ))}
                      <th>Part Number</th>
                      {showC2A && <th>Qty</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {productOptions?.map((data, index) => {
                      return (
                        <tr key={data.partNumber}>
                          {headers.map((header, childIndex) => {
                            const obj = data.attributes?.find(
                              (attr) => attr.identifier === header.identifier
                            );

                            return (
                              <td key={childIndex}>
                                {obj?.values
                                  ?.map((valueObject) => valueObject.value)
                                  ?.join(", ")}
                              </td>
                            );
                          })}
                          <td>{data.mfPartNumber_ntk}</td>
                          {showC2A && (
                            <td>
                              <Input
                                type="text"
                                maxLength="5"
                                value={qty[data.uniqueID] || ""}
                                onChange={(e) =>
                                  this.handleQtyChange(data.uniqueID, e)
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    {hasMore && (
                      <tr>
                        <LoadMoreTD
                          centered
                          colSpan={(headers?.length ?? 0) + 2}
                        >
                          <Button
                            onClick={() => this.loadMore()}
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Load more"}
                          </Button>
                        </LoadMoreTD>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {showC2A && (
                <Action>
                  {errorMessages?.map((errorMessage) => (
                    <Error>
                      <HTML>{errorMessage}</HTML>
                    </Error>
                  ))}
                  <TableActionDropdown>
                    <OrderFavoritesDropDown
                      value={listId}
                      onChange={(value) => this.addToOrderFavorites(value)}
                      defaultValue={RESET}
                      disabled={c2aDisabled}
                    />
                  </TableActionDropdown>
                  {siteId === "e-store" && (
                    <Button
                      onClick={() => this.addToCart()}
                      disabled={c2aDisabled}
                    >
                      Add to Current Order
                    </Button>
                  )}
                </Action>
              )}
            </div>
            {specifications.length > 0 && (
              <React.Fragment>
                <div className="yourSelection" ref={this.specificationsRef}>
                  {translationKey(currentFileRequired["SPECIFICATIONS"])}
                </div>
                <table className="specificationsTable">
                  <tbody>
                    {specifications.map((specification, index) => {
                      return (
                        <tr key={index}>
                          <td>{specification.name}</td>
                          <td>
                            {specification.entry.map((data, idx) => (
                              <span key={idx}>
                                {data.label}
                                {idx < specification.entry.length - 1 && ", "}
                                &nbsp;
                              </span>
                            ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            )}

            <div className="yourSelection" ref={this.detailsRef}>
              {translationKey(currentFileRequired["DETAILS"])}
            </div>
            <ul className="details">{this.renderBullets(detailBullets)}</ul>

            <div
              className="info"
              dangerouslySetInnerHTML={{ __html: product?.longDescription }}
            >
              {/* Designed with both the patient and orthodontist in mind, our innovative <b>3M™ Clarity™ Ultra Self-Ligating Brackets</b> provide treatment control and predictability from start to finish. <b>3M™ Clarity™ Ultra Self Ligating Brackets</b> are translucent, stain resistant, and deliver a natural look. In addition, they’re available precoated with <b>3M™ APC™ Flash Free Adhesive</b> — the most efficient bonding system in orthodontics. Using the Clarity Ultra bracket system means choosing expertise, predictability and service you can trust and rely on for results. */}
            </div>

            {attributeMap["3M_SITE_URL"]?.values?.[0]?.value && (
              <div className="info">
                <a
                  href={attributeMap["3M_SITE_URL"]?.values?.[0]?.value}
                  target="_blank"
                >
                  {translationKey(
                    currentFileRequired["THREEM_SITE_REDIRECT_MSG"]
                  )}
                </a>
              </div>
            )}
            {hasDisclaimer && (
              <Fragment>
                <hr />
                <div className="yourSelection" ref={this.legalRef}>
                  {translationKey(currentFileRequired["LEGAL_DISCLAIMER"])}
                </div>
                <div className="info">
                  {translationKey(currentFileRequired["ENDORSEMENT_MSG"])}
                </div>
              </Fragment>
            )}
          </ContentWrapper>
        </SideBarContentWrapper>
      </>
    );
  }
}

ProductDescription.propTypes = {};

function mapStateToProps(state) {
  return {
    product: getProduct(state),
    facets: getFacets(state),
    productOptions: getProductOptions(state),
    errorMessages: getErrorMessages(state),
    attributeMap: getAttributeMap(state),
    storeId: getStoreId(state),
    langId: getLangId(state),
    catalogId: getCatalogId(state),
    baseURL: getBaseURL(state),
    addingToOrderFavorites: isAddingToOrderFavorites(state),
    recListId: getRecListId(state),
    totalProductOptions: getTotalProductOptions(state),
    loading: isLoading(state),
    hasMore: hasMore(state),
    headers: getHeaders(state),
    siteId: getSiteId(state),
    forUser: getForUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetProductDetailsAction,
        GetProductSpecificationsAction,
        GetFilteredProductOptionsAction,
        GetMoreProductOptionsAction,
        AddToCartAction,
        AddToOrderFavoritesAction,
      },
      dispatch
    ),
  };
}

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ProductDescription)
  )
);

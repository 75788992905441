import {
    actionCreator,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../Shared/utility';

export {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader
};

export const ProductListingActionTypes = {
    get_prouctView: createRequestActionTypes('GET_PRODUCT_VIEW'),
    get_productCategory: createRequestActionTypes('GET_PRODUCT_CATEGORY'),
    get_categoryBreadCrumbTrail: createRequestActionTypes('GET_CATEGORY_BREADCRUMB')
};
import queryString from 'query-string'
import {
    actionCreator,
    checkHttpStatus,
    ProductDescriptionActionTypes,
    jsonApiHeader,
} from '../constants/ApiConstants';

export const GetProductDetailsAction = ({ storeId, catalogId, productId, categoryId }) => {
    return (dispatch) => {
        const query = queryString.stringify({
            catalogId,
            profileName: '3M_findProductByIds_Summary',
            categoryId,
        })
        dispatch(actionCreator(ProductDescriptionActionTypes.get_productDetails.REQUEST));
        fetch(`/search/resources/store/${storeId}/productview/byId/${productId}?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(ProductDescriptionActionTypes.get_productDetails.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(ProductDescriptionActionTypes.get_productDetails.FAILURE));
                console.log('error get_productDetails ..', error);
            });
    };
};

export const GetProductSpecificationsAction = ({ storeId, catalogId, productId, pageSize }) => {
    const actionType = ProductDescriptionActionTypes.get_prouctSpecifications
    return doGetProductOptionsAction({ storeId, catalogId, productId, pageSize, actionType })
}

export const GetFilteredProductOptionsAction = ({ storeId, catalogId, productId, filters, pageSize }) => {
    const actionType = ProductDescriptionActionTypes.get_filteredProductOptions
    return doGetProductOptionsAction({ storeId, catalogId, productId, filters, pageSize, actionType })
}

export const GetMoreProductOptionsAction = ({ storeId, catalogId, productId, filters, pageSize, pageNumber }) => {
    const actionType = ProductDescriptionActionTypes.get_moreProductOptions
    return doGetProductOptionsAction({ storeId, catalogId, productId, filters, pageSize, pageNumber, actionType })
}

const doGetProductOptionsAction = ({ storeId, catalogId, productId, filters = [], pageSize, pageNumber = 1, actionType }) => {
    return (dispatch) => {
        const query = queryString.stringify({
            catalogId,
            searchType: 101,
            facet: [
                `parentCatentry_id:${productId}`,
                ...filters,
            ],
            pageSize,
            pageNumber,
            profileName: '3M_findProductsBySearchTerm',
            orderBy: 10,
        })
        dispatch(actionCreator(actionType.REQUEST));
        fetch(`/search/resources/store/${storeId}/productview/bySearchTerm/*?${query}`, {
            method: 'GET',
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                dispatch(actionCreator(actionType.SUCCESS, response));
            })
            .catch(function (error) {
                dispatch(actionCreator(actionType.FAILURE));
                console.log('error get_filteredProductOptions ..', error);
            });
    };
};

export const AddToCartAction = ({ storeId, langId, catalogId, baseURL, orderItems }) => {
    return async dispatch => {
        let failures = [];
        let updatedOrderItems = orderItems.filter((item)=>item.quantity!=='');
        const request = { storeId, langId, catalogId, baseURL, updatedOrderItems }
        dispatch(actionCreator(ProductDescriptionActionTypes.add_itemsToCart.REQUEST, request))
        try {
            const token = document.getElementById('reactWCToken')?.value;
            // Calling API once per order item because WCS is not very good at handling error messages for multiple order items
            for (const idx in updatedOrderItems) {
                const response = await fetch(`/wcs/resources/store/${storeId}/cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type':'application/json',
                        'WCAuthToken':token?token:''
                    },
                    body: JSON.stringify({
                        x_calculationUsage: "-1",
                        orderId: ".",
                        orderItem: updatedOrderItems[idx]                        
                    })
                })
                const jsonResponse = await checkHttpStatus(response)
                if (jsonResponse?.errors) {
                    jsonResponse.errors.forEach(error => {
                        if (!failures.includes(error.errorMessage)) {
                            failures.push(error.errorMessage)
                        }
                    })
                }
            }
            if (failures.length > 0) {
                dispatch(actionCreator(ProductDescriptionActionTypes.add_itemsToCart.FAILURE, failures))
            } else {
                dispatch(actionCreator(ProductDescriptionActionTypes.add_itemsToCart.SUCCESS, {}))
                window.location.href=`${baseURL}OrderItemDisplay?storeId=${storeId}&langId=${langId}&catalogId=${catalogId}&orderId=.&doPrice=N`
            }
        } catch (err) {
            dispatch(actionCreator(ProductDescriptionActionTypes.add_itemsToCart.FAILURE, [err.message]))
            console.log('error add_itemsToCart ..', err)
        }
    }
}

export const AddToOrderFavoritesAction = ({ storeId, langId, catalogId, baseURL, listId, recListId, forUser, items}) => {
    return async dispatch => {
        let failures = []
        const request = { storeId, langId, catalogId, baseURL, listId, recListId, forUser, items }
        dispatch(actionCreator(ProductDescriptionActionTypes.add_toOrderFavorites.REQUEST, request))
        try {
            const token = document.getElementById('reactWCToken')?.value;
            const forUserId = forUser?.usersId
            const query = {
                forUserId
            }
            const url = `/wcs/resources/store/${storeId}/interestItemList/${listId}`
            const response = await fetch(queryString.stringifyUrl({ url, query }), {
                method: 'POST',
                headers: {
                    'Content-Type':'application/json',
                    'WCAuthToken':token?token:''
                },
                body: JSON.stringify({
                    items
                })
            })
            const jsonResponse = await checkHttpStatus(response)
            if (jsonResponse?.errors) {
                const errorMessages = jsonResponse.errors.map(error => (error.errorMessage))
                failures = [
                    ...failures,
                    ...errorMessages
                ]
            }
            if (failures.length > 0) {
                dispatch(actionCreator(ProductDescriptionActionTypes.add_toOrderFavorites.FAILURE, failures))
            } else {
                dispatch(actionCreator(ProductDescriptionActionTypes.add_toOrderFavorites.SUCCESS))
                if (forUser) {
                    // Handling sales-portal add to order favorites for customer
                    const query = {
                        view: 'InterestOrderEditView',
                        listId,
                        recListId: forUser.recListId,
                        usersId: forUser.usersId,
                        customerName: forUser.customerName,
                        cono: forUser.cono,
                        custNbr: forUser.custNbr,
                        langId,
                        catalogId,
                        storeId,
                    }
                    const url = `${baseURL}RSMPortalDisplayCmd`
                    const location = queryString.stringifyUrl({ url, query })
                    window.location.href = location
                } else {
                    const location = `${baseURL}PersonalizedOrderFormEditView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}&listId=${listId}&recListId=${recListId}`
                    window.location.href = location
                }
            }
        } catch (error) {
            dispatch(actionCreator(ProductDescriptionActionTypes.add_toOrderFavorites.FAILURE, failures))
        }
    }
}
import { OrderCommentsActionTypes } from "../constants/ApiConstants"

const initialState = {
  loading: false,
  loaded: false,
  commentsData: null,
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OrderCommentsActionTypes.get_orderComments.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case OrderCommentsActionTypes.get_orderComments.SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        commentsData: payload,
      }
    case OrderCommentsActionTypes.get_orderComments.FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        commentsData: payload,
      }
    default:
      return state
  }
}
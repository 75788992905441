import React from 'react';
import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, id, text, ...props }) => {
  return (
    <>
      <div
        data-for={id}
        data-tip={text}
        data-iscapture="true"
        {...props}
      >
        {children}
      </div>
      <ReactTooltip
        id={id}
        place="right"
        type="dark"
        effect="solid"
        multiline
      />
    </>
  )
}

export default Tooltip
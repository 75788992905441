import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAppURL, getStoreId } from '../../Shared/AppContext/selectors'
import BreadCrumb from '../../Shared/Components/BreadCrumb'
import { buildCategoryURL } from '../../Shared/linkBuilders'
import { GetCategoryBreadCrumbAction } from './actions'
import { getCategory, getCategoryBreadCrumbTrail } from './selectors'

const ProductListingBreadCrumb = ({currentFileRequired,translationKey}) => {
  const { categoryId } = useParams()
  const storeId = useSelector(getStoreId)
  const appURL = useSelector(getAppURL)
  const category = useSelector(getCategory)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetCategoryBreadCrumbAction({ storeId, categoryId }))
  }, [ dispatch, storeId, categoryId ])
  const breadCrumbTrail = useSelector(getCategoryBreadCrumbTrail)
  const breadCrumbs = breadCrumbTrail
    ?.filter(({value}) => value !== categoryId)
    ?.map(({label, value}) => ({
      label,
      href: buildCategoryURL(appURL, value),
    }))
  return (
    <BreadCrumb currentFileRequired={currentFileRequired} translationKey={translationKey} breadCrumbs={breadCrumbs} currentPageLabel={category?.name}/>
  )
}

export default ProductListingBreadCrumb
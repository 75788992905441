import queryString from 'query-string'
import { actionCreator, checkHttpStatus, jsonApiHeader } from "../../../utility"
import { HeaderActionTypes } from "../constants/ApiConstants"
import { 
  isTopCategoriesLoaded, 
  isTopCategoriesLoading,
  isMultiAccountsLoading,
  isMultiAccountsLoaded,
  isCustomerProfileLoading,
  isRunAsUserLoading,
} from "../selectors"

export const GetTopCategoriesAction = ({ storeId, catalogId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if(!isTopCategoriesLoaded(state) && !isTopCategoriesLoading(state)) {
      dispatch(actionCreator(HeaderActionTypes.get_topCategories.REQUEST))
      try {
        const query = queryString.stringify({
          catalogId
        })
        const response = await fetch(`/search/resources/store/${storeId}/categoryview/@top?${query}`, {
          method: 'GET',
          headers: jsonApiHeader(),
        }) 
        const payload = await checkHttpStatus(response)
        dispatch(actionCreator(HeaderActionTypes.get_topCategories.SUCCESS, payload))
      } catch (error) {
        dispatch(actionCreator(HeaderActionTypes.get_topCategories.FAILURE))
        console.log('error get_topCategories ..', error)
      }
    }
  }
}

export const GetMultiAccountsAction = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if(!isMultiAccountsLoaded(state) && !isMultiAccountsLoading(state)) {
      dispatch(actionCreator(HeaderActionTypes.get_multiAccounts.REQUEST))
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/3mprofile/@self/accounts`, {
          method: 'GET',
          headers: jsonApiHeader(),
        }) 
        const payload = await checkHttpStatus(response)
        dispatch(actionCreator(HeaderActionTypes.get_multiAccounts.SUCCESS, payload))
      } catch (error) {
        dispatch(actionCreator(HeaderActionTypes.get_multiAccounts.FAILURE))
        console.log('error get_multiAccounts ..', error)
      }
    }
  }
}

export const GetCustomerProfileAction = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if(!isCustomerProfileLoading(state)) {
      dispatch(actionCreator(HeaderActionTypes.get_customerProfile.REQUEST))
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/3mprofile/@self`, {
          method: 'GET',
          headers: jsonApiHeader(),
        }) 
        const payload = await checkHttpStatus(response)
        dispatch(actionCreator(HeaderActionTypes.get_customerProfile.SUCCESS, payload))
      } catch (error) {
        dispatch(actionCreator(HeaderActionTypes.get_customerProfile.FAILURE))
        console.log('error get_customerProfile ..', error)
      }
    }
  }
}

export const GetRunAsUserAction = ({ storeId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if(!isRunAsUserLoading(state)) {
      dispatch(actionCreator(HeaderActionTypes.get_runAsUser.REQUEST))
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/person/@self`, {
          method: 'GET',
          headers: jsonApiHeader(),
        }) 
        const payload = await checkHttpStatus(response)
        dispatch(actionCreator(HeaderActionTypes.get_runAsUser.SUCCESS, payload))
      } catch (error) {
        dispatch(actionCreator(HeaderActionTypes.get_runAsUser.FAILURE))
        console.log('error get_runAsUser ..', error)
      }
    }
  }
}

import styled from 'styled-components/macro'

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;

  /* copied from body, maybe need to move to its own component */
  color: #212529;
  text-align: left;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  /* copied from bootstrap globals */
  & *, & ::after, & ::before {
    box-sizing: border-box
  }
  & a {
    text-decoration-line: none;
    background-color: transparent;
    font-weight: 400;
  }
`

export default Container
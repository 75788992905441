import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { GetLoyaltyTiers } from './actions/index';
import { getLoyaltyPoints, getLoyaltyTiers, isLoyaltyTiersLoading } from './selectors/index';
import { getStoreId } from '../../Shared/AppContext/selectors';
// import { tiersData, LoyalityRewardsData } from './fetch';

const StyledProgressBar = styled.div`
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color:gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  width: 90%;
  top: 65px;
  left: 15px;
`;

const StyledProgression = styled.div`
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #1E1E96;
  width: ${props => `${props.percentage}%`};
  z-index: -1;
`;

const StyledStepContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
  left: ${props => `${props.left}%`};
  transition-duration: 300ms;
`;

const StyledStep = styled.div`
  &.indexedStep {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 12px;
    background-color: gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.indexedStep.accomplished {
    background-color: #1E1E96;
    
  }
`;
const StyledBigStep = styled(StyledStep)`
width: 60px;
height: 60px;

`;
const StyledPointStep = styled.div`
  &.indexedStep {
    color: white;
    width: 65px;
    height: 65px;
    font-size: 22px;
    background-color: #1E1E96;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid skyblue;
  }

  &.indexedStep.accomplished {
    background-color: #1E1E96;
    
  }
`;

const StyledErrorMessage = styled.h2`
  text-align: center;
`;

const TiersProgress = (props) => {
  const dispatch = useDispatch();
  const tiers = useSelector(getLoyaltyTiers);

  const rewardsData = useSelector(getLoyaltyPoints);
  // const tiers = tiersData?.tierInfoList;
  // const rewardsData = LoyalityRewardsData;
  // const tiers = tiersData && [...tiersData].reverse();
  // const tiers = TiersData.tierInfoList;
  // const tiers =fetch.tiers;
  const isTiersLoading = useSelector(isLoyaltyTiersLoading);
  const storeId = useSelector(getStoreId);
  useEffect(() => {
    if (!isTiersLoading && !tiers) {
      dispatch(GetLoyaltyTiers({ storeId }));
    }
  }, [tiers, isTiersLoading, dispatch, storeId]);


  let completedTiers = 0;
  if (tiers) {
    for (let i = 0; i < tiers.length; i += 1) {
      if (tiers[i].currentTierId == rewardsData?.currentTierId) break;
      completedTiers += 1;
    }
  }
  const tierPositionPercentage = tiers && tiers ? 100 / (tiers.length - 1) : 0;
  let progressPercentage = (completedTiers * tierPositionPercentage) + (tierPositionPercentage * Math.max(20, 25) / 100);
  progressPercentage = progressPercentage > 100 ? 100 : progressPercentage;

  return (<>
    {!tiers &&
      < StyledErrorMessage key={`tiers-error-message`}>Error fetching tier information! Please try again later.</StyledErrorMessage>
    }
    {
      tiers && <>
        <StyledProgressBar key={`tiers-progress`}>
          {tiers && tiers.map((tier, index) => {
            const left = tierPositionPercentage * index;
            const accomplished = progressPercentage >= left;
            return (<StyledStepContainer left={left} key={tier.currentTierId}>
              {tier.currentTierId == rewardsData?.currentTierId ?
                <StyledPointStep
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {tier.currentTierTitle}
                </StyledPointStep>
                :
                <StyledStep
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {tier.currentTierTitle}
                </StyledStep>
              }
            </StyledStepContainer>)
          })}
          <StyledProgression percentage={progressPercentage} />
        </StyledProgressBar>
      </>
    }
  </>);
}

export default TiersProgress
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import BreadCrumb from "../../Shared/Components/BreadCrumb";
import styled from "styled-components/macro";
import {
  getBaseURL,
  getCatalogId,
  getLangId,
  getStoreId,
} from "../../Shared/AppContext/selectors";
import MyRewards from "./MyRewards";
import DisplayAlignerTable from "./DisplayAlignerTable";
import DisplayOtherTable from "./DisplayOtherTable";

import ExpansionContainer from "./LoyalityFaq";
import LoaylityTermsAndConditionsModal from "./LoyalityTermsAndConditions";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../UserContext";
import { getCurrentFile } from "../../Shared/utility";

const ProgressBarText = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 80px 0px 30px;
`;

const LoyaltyRewards = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(getStoreId);
  const catalogId = useSelector(getCatalogId);
  const langId = useSelector(getLangId);
  const baseURL = useSelector(getBaseURL);
  const myStatementURL = `${baseURL}AccountsReceivableAccountSummaryView?langId=${langId}&storeId=${storeId}&catalogId=${catalogId}`;
  const { selectedLanguage = "" } = useContext(UserContext);
  var currentFileRequired = getCurrentFile(selectedLanguage);
  const { t: translationKey } = useTranslation();

  const breadCrumbs = [
    {
      label: translationKey(currentFileRequired["MY_STATEMENT"]),
      link: myStatementURL,
      href: myStatementURL,
    },
  ];
  const { path } = useRouteMatch();

  return (
    <>
      <BreadCrumb
        breadCrumbs={breadCrumbs}
        currentPageLabel={translationKey(currentFileRequired["MY_REWARDS"])}
        currentFileRequired={currentFileRequired}
        translationKey={translationKey}
      />
      <div style={{ fontFamily: " Helvetica, Arial, Verdana, sans-serif" }}>
        <MyRewards
          currentFileRequired={currentFileRequired}
          translationKey={translationKey}
        />
        <DisplayAlignerTable
          currentFileRequired={currentFileRequired}
          translationKey={translationKey}
        />
        <DisplayOtherTable
          currentFileRequired={currentFileRequired}
          translationKey={translationKey}
        />
        <ProgressBarText>
          {translationKey(currentFileRequired["FAQS"])}
        </ProgressBarText>
        <ExpansionContainer />
        {/* <LoaylityTermsAndConditionsModal /> */}
      </div>
    </>
  );
};
export default LoyaltyRewards;

import { actionCreator, checkHttpStatus, jsonApiHeader } from "../../../utility"
import { OrderCommentsActionTypes } from '../constants/ApiConstants'
import { isOrderCommentsLoading } from '../selectors'

export const getOrderCommentsAction = ({ storeId, orderId }) => {
  return async (dispatch, getState) => {
    const state = getState()
    if(!isOrderCommentsLoading(state)) {
      dispatch(actionCreator(OrderCommentsActionTypes.get_orderComments.REQUEST))
      try {
        const response = await fetch(`/wcs/resources/store/${storeId}/order/${orderId}/comment`, {
          method: 'GET',
          headers: jsonApiHeader(),
        }) 
        const payload = await checkHttpStatus(response)
        dispatch(actionCreator(OrderCommentsActionTypes.get_orderComments.SUCCESS, payload))
      } catch (error) {
        dispatch(actionCreator(OrderCommentsActionTypes.get_orderComments.FAILURE))
        console.log('error get_orderComments ..', error)
      }
    }
  }
}

export const addOrderCommentAction = ({ storeId, orderId, commentField }) => {
  return async (dispatch) => {
    dispatch(actionCreator(OrderCommentsActionTypes.add_orderComment.REQUEST))
    try {
      const token = document.getElementById('reactWCToken')?.value;
      const response = await fetch(`/wcs/resources/store/${storeId}/order/${orderId}/comment?mode=onbehalf`, {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
          'WCAuthToken':token?token:''
      },
        body: JSON.stringify({
          commentField                     
        })
      }) 
      const payload = await checkHttpStatus(response)
      dispatch(actionCreator(OrderCommentsActionTypes.add_orderComment.SUCCESS, payload))
      dispatch(getOrderCommentsAction({ storeId, orderId }))
    } catch (error) {
      dispatch(actionCreator(OrderCommentsActionTypes.add_orderComment.FAILURE))
      console.log('error add_orderComment ..', error)
    }
  }
}
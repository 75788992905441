import EStoreHeader from "../Shared/Components/Header/variations/EStoreHeader";
import ProductDescription from "../Views/ProductDescription/index";
import ProductList from "../Views/ProductListing/index";
import SearchResults from "../Views/SearchResults";
import EStoreSavedCarts from "../Views/SavedCarts/EStoreSavedCarts";
import LoyaltyRewards from "../Views/LoyaltyRewards/index";

const routes = [
  {
    path: "/catalog",
    component: SearchResults,
    header: EStoreHeader,
    activeMenu: "catalog",
  },
  {
    path: "/category/:categoryId",
    component: ProductList,
    header: EStoreHeader,
    activeMenu: "catalog",
  },
  {
    path: "/product/:productId",
    component: ProductDescription,
    header: EStoreHeader,
    activeMenu: "catalog",
  },
  {
    path: "/search/:searchTerm",
    component: SearchResults,
    header: EStoreHeader,
    activeMenu: "catalog",
  },
  {
    path: "/saved-carts",
    component: EStoreSavedCarts,
    header: EStoreHeader,
    activeMenu: "saved-carts",
  },
  {
    path: "/my-rewards",
    component: LoyaltyRewards,
    header: EStoreHeader,
    activeMenu: "my-rewards",
  },
  {
    header: EStoreHeader,
  },
];

export default routes;
